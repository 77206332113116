<!-- Card Header -->
<ng-container *ngIf="header">
  <ng-content select="zeva-card-header, [zeva-card-header]"></ng-content>
</ng-container>
<!-- Card Body -->
<div class="card-body">
  <ng-content></ng-content>
</div>
<!-- Card Footer -->
<ng-container *ngIf="footer">
  <ng-content select="zeva-card-footer, [zeva-card-footer]"></ng-content>
</ng-container>

<div class="active-indicator"></div>

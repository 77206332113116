import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { AnalyticsAppState } from '../analytics.reducer';
import { AnalyticsDataService } from './analytics-data.service';
import * as AnalyticsActions from './analytics.actions';

@Injectable()
export class AnalyticsGeneralEffects {
  getVehicleTravelData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getVehicleTravelData),
      switchMap(action =>
        this.dataService
          .getVehicleTravelData(
            action.time_0,
            action.time_1,
            action.compare_time_0,
            action.compare_time_1,
            action.vehicles
          )
          .pipe(
            map(travelData => AnalyticsActions.setVehicleTravelData({ travelData })),
            catchError(() => of(AnalyticsActions.setVehicleTravelDataError()))
          )
      )
    )
  );

  getVehicleActivityData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getVehicleActivityData),
      switchMap(action =>
        this.dataService
          .getVehicleActivityData(
            action.time_0,
            action.time_1,
            action.compare_time_0,
            action.compare_time_1,
            action.vehicles
          )
          .pipe(
            map(activityData => AnalyticsActions.setVehicleActivityData({ activityData })),
            catchError(() => of(AnalyticsActions.setVehicleActivityData({ activityData: null })))
          )
      )
    )
  );

  getVehicleAlertData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getVehicleAlertData),
      switchMap(action =>
        this.dataService
          .getVehicleAlertData(
            action.time_0,
            action.time_1,
            action.compare_time_0,
            action.compare_time_1,
            action.vehicles
          )
          .pipe(
            map(alertData => AnalyticsActions.setVehicleAlertData({ alertData })),
            catchError(() => of(AnalyticsActions.setVehicleAlertData({ alertData: null })))
          )
      )
    )
  );

  getVehicleChargingData$ = this.handleBatchRequest(
    AnalyticsActions.getVehicleChargingData,
    this.dataService.getVehicleChargingData.bind(this.dataService),
    (results: any[]) => {
      const combinedData = this.combineSeriesData(results, 'Charging');

      return AnalyticsActions.setVehicleChargingData({ chargingData: combinedData });
    }
  );

  getVehicleChargingTypeData$ = this.handleBatchRequest(
    AnalyticsActions.getVehicleChargingTypeData,
    this.dataService.getVehicleChargingTypeData.bind(this.dataService),
    (results: any[]) => {
      const combinedTypeData = this.combineSeriesData(results, 'Charging By Type');

      return AnalyticsActions.setVehicleChargingTypeData({ chargingData: combinedTypeData });
    }
  );

  getVehicleTripData$ = this.handleBatchRequest(
    AnalyticsActions.getVehicleTripData,
    this.dataService.getVehicleTripData.bind(this.dataService),
    (results: any[]) => {
      const combinedTripData = this.combineSeriesData(results, 'Trips');
      return AnalyticsActions.setVehicleTripData({ tripData: combinedTripData });
    }
  );

  getUsageChargingHeatmapData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getUsageChargingHeatmapData),
      concatMap(action =>
        this.dataService
          .getUsageChargingHeatmapData(action.time_0, action.time_1, action.vehicles)
          .pipe(
            map(usageChargingHeatmapData => {
              return AnalyticsActions.setUsageChargingHeatmapData({
                usageChargingHeatmapData,
              });
            }),
            catchError(error =>
              of(
                AnalyticsActions.setUsageChargingHeatmapData({
                  usageChargingHeatmapData: { message: String(error) },
                })
              )
            )
          )
      )
    )
  );

  getUsageAnalyticsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getUsageAnalyticsData),
      switchMap(action =>
        this.dataService.getUsageAnalyticsData(action.time_0, action.time_1, action.vehicles).pipe(
          map(usageAnalyticsData => AnalyticsActions.setUsageAnalyticsData({ usageAnalyticsData })),
          catchError(() =>
            of(
              AnalyticsActions.setUsageAnalyticsData({
                usageAnalyticsData: null,
              })
            )
          )
        )
      )
    )
  );

  getChargingAnalyticsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getChargingAnalyticsData),
      switchMap(action =>
        this.dataService
          .getChargingAnalyticsData(action.time_0, action.time_1, action.vehicles)
          .pipe(
            map(chargingAnalyticsData =>
              AnalyticsActions.setChargingAnalyticsData({ chargingAnalyticsData })
            ),
            catchError(() =>
              of(
                AnalyticsActions.setChargingAnalyticsData({
                  chargingAnalyticsData: null,
                })
              )
            )
          )
      )
    )
  );

  getGeofenceAlertsAnalyticsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getGeofenceAlertsAnalyticsData),
      switchMap(action =>
        this.dataService
          .getGeofenceAlertsAnalyticsData(action.time_0, action.time_1, action.vehicles)
          .pipe(
            map(geofenceAlertsAnalyticsData =>
              AnalyticsActions.setGeofenceAlertsAnalyticsData({ geofenceAlertsAnalyticsData })
            ),
            catchError(() =>
              of(
                AnalyticsActions.setGeofenceAlertsAnalyticsData({
                  geofenceAlertsAnalyticsData: null,
                })
              )
            )
          )
      )
    )
  );

  getVehicleRanking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getVehicleRanking),
      switchMap(action =>
        this.dataService
          .getVehicleRanking(
            action.dimension,
            action.time_0,
            action.time_1,
            action.compare_time_0,
            action.compare_time_1,
            action.vehicles
          )
          .pipe(
            map(vehicleRanking => AnalyticsActions.setVehicleRanking({ vehicleRanking })),
            catchError(() => of(AnalyticsActions.setVehicleRanking({ vehicleRanking: null })))
          )
      )
    )
  );

  getVehicleAnalyticsDataDateRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getVehicleAnalyticsDataDateRange),
      switchMap(() =>
        this.dataService.getVehicleAnalyticsDataDateRange().pipe(
          map(dataDateRange =>
            AnalyticsActions.setVehicleAnalyticsDataDateRange({ dataDateRange })
          ),
          catchError(() =>
            of(AnalyticsActions.setVehicleAnalyticsDataDateRange({ dataDateRange: null }))
          )
        )
      )
    )
  );

  getSuperchargers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getSuperchargers),
      switchMap(() =>
        this.dataService.getSuperchargers().pipe(
          map(superchargers => AnalyticsActions.setSuperchargers({ superchargers })),
          catchError(error => of(AnalyticsActions.setSuperchargers({ superchargers: null, error })))
        )
      )
    )
  );

  getBatteryCapacity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getBatteryCapacity),
      switchMap(action =>
        this.dataService.getBatteryCapacity(action.time_0, action.time_1, action.vehicles).pipe(
          map(capacityData => AnalyticsActions.setBatteryCapacity({ capacityData })),
          catchError(() => of(AnalyticsActions.setBatteryCapacityError()))
        )
      )
    )
  );

  getBatteryEfficiency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getBatteryEfficiency),
      switchMap(action =>
        this.dataService.getBatteryEfficiency(action.time_0, action.time_1, action.vehicles).pipe(
          map(efficiencyData => AnalyticsActions.setBatteryEfficiency({ efficiencyData })),
          catchError(() => of(AnalyticsActions.setBatteryEfficiencyError()))
        )
      )
    )
  );

  getBatteryLife$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getBatteryLife),
      switchMap(action =>
        this.dataService.getBatteryLife(action.time_0, action.time_1, action.vehicles).pipe(
          map(batteryLifeData => AnalyticsActions.setBatteryLife({ batteryLifeData })),
          catchError(() => of(AnalyticsActions.setBatteryLifeError()))
        )
      )
    )
  );

  getChargeCycles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getChargeCycles),
      switchMap(action =>
        this.dataService.getChargeCycles(action.time_0, action.time_1, action.vehicles).pipe(
          map(chargeCycleData => AnalyticsActions.setChargeCycles({ chargeCycleData })),
          catchError(() => of(AnalyticsActions.setChargeCyclesError()))
        )
      )
    )
  );

  getDepletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getDepletion),
      switchMap(action =>
        this.dataService.getAverageDepletion(action.time_0, action.time_1, action.vehicles).pipe(
          map(depletionData => AnalyticsActions.setDepletion({ depletionData })),
          catchError(() => of(AnalyticsActions.setDepletionError()))
        )
      )
    )
  );

  getBatteryRating$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getBatteryRating),
      switchMap(action =>
        this.dataService
          .getBatteryRating(
            action.time_0,
            action.time_1,
            action.compare_time_0,
            action.compare_time_1,
            action.vehicles
          )
          .pipe(
            map(batteryRatingData => AnalyticsActions.setBatteryRating({ batteryRatingData })),
            catchError(() => of(AnalyticsActions.setBatteryRatingError()))
          )
      )
    )
  );

  getRoadSpeedData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getRoadSpeedData),
      switchMap(action =>
        this.dataService
          .getRoadSpeedData(
            action.time_0,
            action.time_1,
            action.lat_0,
            action.lat_1,
            action.lng_0,
            action.lng_1,
            action.zoom,
            action.vehicles
          )
          .pipe(
            map(roadSpeedData => AnalyticsActions.setRoadSpeed({ roadSpeedData })),
            catchError(() => of(AnalyticsActions.setRoadSpeedError()))
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private dataService: AnalyticsDataService,
    private store: Store<AnalyticsAppState>
  ) {}

  handleBatchRequest(actionType: any, dataServiceMethod: any, mapFn: (data: any) => any) {
    return createEffect(() =>
      this.actions$.pipe(
        ofType(actionType),
        switchMap(action => {
          const defaultTime = moment().add(1, 'week').toDate();
          const segmentSize = 28 * 24 * 60 * 60 * 1000; // 4 weeks in milliseconds
          const timeSegments = this.splitTimeRangeIntoSegments(
            action.time_0,
            action.time_1,
            segmentSize
          );
          const compareSegments = this.splitTimeRangeIntoSegments(
            action.compare_time_0!,
            action.compare_time_1!,
            segmentSize
          );

          const batchObservables = [];

          for (let i = 0; i < Math.max(timeSegments.length, compareSegments.length); i++) {
            const payloadSegmented = {
              time_0: defaultTime,
              time_1: defaultTime,
              compare_time_0: defaultTime,
              compare_time_1: defaultTime,
              vehicles: action.vehicles,
            };

            if (i < timeSegments.length) {
              payloadSegmented.time_0 = timeSegments[i][0];
              payloadSegmented.time_1 = timeSegments[i][1];
            }

            if (i < compareSegments.length) {
              payloadSegmented.compare_time_0 = compareSegments[i][0];
              payloadSegmented.compare_time_1 = compareSegments[i][1];
            }

            const batchObservable = dataServiceMethod(
              payloadSegmented.time_0,
              payloadSegmented.time_1,
              payloadSegmented.compare_time_0,
              payloadSegmented.compare_time_1,
              payloadSegmented.vehicles
            );

            batchObservables.push(batchObservable);
          }

          return forkJoin(batchObservables).pipe(
            map((results: any[]) => {
              // Combine results if needed
              return mapFn(results);
            }),
            catchError(() => of(mapFn(null)))
          );
        })
      )
    );
  }

  combineSeriesData(results: any[], name: string): any {
    return results.reduce(
      (acc, curr) => {
        for (const seriesName in curr.series) {
          if (Object.prototype.hasOwnProperty.call(curr.series, seriesName)) {
            if (!acc.series[seriesName]) {
              acc.series[seriesName] = {
                data: [],
                format: curr.series[seriesName].format,
              };
            }
            acc.series[seriesName].data.push(...curr.series[seriesName].data);
          }
        }
        return acc;
      },
      { name, series: {} }
    );
  }

  splitTimeRangeIntoSegments(timeStart: Date, timeEnd: Date, segmentSize: number): Date[][] {
    const segments: Date[][] = [];
    let segmentStart = new Date(timeStart);
    while (segmentStart < timeEnd) {
      const segmentEnd = new Date(Math.min(+segmentStart + segmentSize, +timeEnd));
      segments.push([segmentStart, segmentEnd]);
      segmentStart = new Date(+segmentEnd);
    }
    return segments;
  }
}

import { createSelector } from '@ngrx/store';
import { selectFeatureGeneralAdmin } from '../admin.selectors';
import { AdminGeneralState } from './admin.reducer';

export const selectLoadingStatus = createSelector(
  selectFeatureGeneralAdmin,
  (state: AdminGeneralState) => state.loadingStatus
);

export const selectSupportedProviders = createSelector(
  selectFeatureGeneralAdmin,
  (state: AdminGeneralState) => state.providers
);

export const selectAllUsers = createSelector(
  selectFeatureGeneralAdmin,
  (state: AdminGeneralState) => state.users
);

export const selectPermissionsList = createSelector(
  selectFeatureGeneralAdmin,
  (state: AdminGeneralState) => state.permissionsList
);

export const selectPermissionGroups = createSelector(
  selectFeatureGeneralAdmin,
  (state: AdminGeneralState) => state.permissionGroups
);

export const selectMemberCounts = createSelector(
  selectFeatureGeneralAdmin,
  (state: AdminGeneralState) => state.memberCounts
);

export const selectInvites = createSelector(
  selectFeatureGeneralAdmin,
  (state: AdminGeneralState) => state.invites
);

export const selectBillingDetails = createSelector(
  selectFeatureGeneralAdmin,
  (state: AdminGeneralState) => state.billingDetails
);

export const selectBillingCard = createSelector(
  selectFeatureGeneralAdmin,
  (state: AdminGeneralState) => state.billingCard
);

export const selectSubscriptionInvoices = createSelector(
  selectFeatureGeneralAdmin,
  (state: AdminGeneralState) => state.subscriptionInvoices
);

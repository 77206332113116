import { Action, createReducer, on } from '@ngrx/store';
import { resetState } from '../../../store/app.actions';
import { ListDTO } from '../../loading-state/api.models';
import { Loadable } from '../../loading-state/loadable';
import { SubscriptionDTO, SubscriptionPlanDTO } from '../subscription.models';
import * as SubscriptionActions from './subscription.actions';

export type SubscriptionState = {
  subscriptionPlans: Loadable<ListDTO<SubscriptionPlanDTO>>;
  subscription: Loadable<SubscriptionDTO>;
};

const initialState: SubscriptionState = {
  subscriptionPlans: new Loadable(),
  subscription: new Loadable(),
};

const reducer = createReducer<SubscriptionState>(
  initialState,
  on(resetState, () => ({ ...initialState })),
  // Subscription Plans
  on(SubscriptionActions.getSubscriptionPlans, state => ({
    ...state,
    subscriptionPlans: state.subscriptionPlans.loadingCopy(true),
  })),
  on(SubscriptionActions.setSubscriptionPlans, (state, { subscriptionPlans, error }) => ({
    ...state,
    subscriptionPlans:
      error === undefined
        ? new Loadable(subscriptionPlans)
        : state.subscriptionPlans.errorCopy(error),
  })),
  // Subscription
  on(SubscriptionActions.getSubscription, state => ({
    ...state,
    subscription: state.subscription.loadingCopy(true),
  })),
  on(SubscriptionActions.setSubscription, (state, { subscription, error }) => ({
    ...state,
    subscription:
      error === undefined ? new Loadable(subscription) : state.subscription.errorCopy(error),
  }))
);

export function subscriptionReducer(
  state: SubscriptionState | undefined,
  action: Action
): SubscriptionState {
  return reducer(state, action);
}
export const subscriptionFeatureKey = 'subscription';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { InsightsDataService } from './insights-data.service';
import * as InsightsActions from './insights.actions';

@Injectable()
export class InsightsGeneralEffects {
  getUsageInsights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsightsActions.getUsageInsights),
      switchMap(action =>
        this.dataService.getUsageInsights(action.payload).pipe(
          map(usageInsights => InsightsActions.setUsageInsights({ usageInsights })),
          catchError(() => of(InsightsActions.setUsageInsights({ usageInsights: null })))
        )
      )
    )
  );

  getMileageRanking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsightsActions.getMileageRanking),
      switchMap(action =>
        this.dataService.getMileageRanking(action.payload).pipe(
          map(mileageRanking => InsightsActions.setMileageRanking({ mileageRanking })),
          catchError(() => of(InsightsActions.setMileageRanking({ mileageRanking: null })))
        )
      )
    )
  );

  getActivityInsights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsightsActions.getActivityInsights),
      switchMap(action =>
        this.dataService.getActivityInsights(action.payload).pipe(
          map(activityInsights => InsightsActions.setActivityInsights({ activityInsights })),
          catchError(() => of(InsightsActions.setActivityInsights({ activityInsights: null })))
        )
      )
    )
  );

  getChargingLevelsInsights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsightsActions.getChargingLevelsInsights),
      switchMap(action =>
        this.dataService.getChargingLevelsInsights(action.payload).pipe(
          map(chargingLevels => InsightsActions.setChargingLevelsInsights({ chargingLevels })),
          catchError(() => of(InsightsActions.setChargingLevelsInsights({ chargingLevels: null })))
        )
      )
    )
  );

  getEnergyConsumptionRanking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsightsActions.getEnergyConsumptionRanking),
      switchMap(action =>
        this.dataService.getEnergyConsumptionRanking(action.payload).pipe(
          map(energyConsumption =>
            InsightsActions.setEnergyConsumptionRanking({ energyConsumption })
          ),
          catchError(() =>
            of(InsightsActions.setEnergyConsumptionRanking({ energyConsumption: null }))
          )
        )
      )
    )
  );

  getAlertEventsInsights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InsightsActions.getAlertEventsInsights),
      switchMap(action => {
        const defaultTime = moment().add(1, 'week').toDate();
        const alertEventsObservables = [];
        const segmentSize = 28 * 24 * 60 * 60 * 1000; // 4 weeks in milliseconds
        const timeSegments = this.splitTimeRangeIntoSegments(
          action.payload.time_0,
          action.payload.time_1,
          segmentSize
        );
        const compareSegments = this.splitTimeRangeIntoSegments(
          action.payload.compare_time_0,
          action.payload.compare_time_1,
          segmentSize
        );

        for (let i = 0; i < Math.max(timeSegments.length, compareSegments.length); i++) {
          const payloadSegmented = {
            time_0: defaultTime,
            time_1: defaultTime,
            compare_time_0: defaultTime,
            compare_time_1: defaultTime,
            vehicles: action.payload.vehicles,
          };

          if (i < timeSegments.length) {
            payloadSegmented.time_0 = timeSegments[i][0];
            payloadSegmented.time_1 = timeSegments[i][1];
          }

          if (i < compareSegments.length) {
            payloadSegmented.compare_time_0 = compareSegments[i][0];
            payloadSegmented.compare_time_1 = compareSegments[i][1];
          }

          const alertObservable = this.dataService.getAlertEventsInsights(payloadSegmented);

          alertEventsObservables.push(alertObservable);
        }
        return forkJoin(alertEventsObservables).pipe(
          map((results: any[]) => {
            const combinedAlertData = results.reduce(
              (acc, curr) => {
                if (curr) {
                  acc.current.push(...curr.current);
                  acc.compare.push(...curr.compare);
                }
                return acc;
              },
              { current: [], compare: [] }
            );
            return InsightsActions.setAlertEventsInsights({ alertInsights: combinedAlertData });
          }),
          catchError(() => of(InsightsActions.setAlertEventsInsights({ alertInsights: null })))
        );
      })
    )
  );

  constructor(private actions$: Actions, private dataService: InsightsDataService) {}

  splitTimeRangeIntoSegments(timeStart: Date, timeEnd: Date, segmentSize: number): Date[][] {
    const segments: Date[][] = [];
    let segmentStart = new Date(timeStart);
    while (segmentStart < timeEnd) {
      const segmentEnd = new Date(Math.min(+segmentStart + segmentSize, +timeEnd));
      segments.push([segmentStart, segmentEnd]);
      segmentStart = new Date(+segmentEnd);
    }
    return segments;
  }
}

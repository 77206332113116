import { SupportTicketType } from './support.models';

/**
 * Width of the support modal
 */
export const MODAL_WIDTH = '35vw';

/**
 * Height of the support modal
 */
export const MODAL_HEIGHT = '85vh';

/**
 * ID for the support modal
 */
export const MODAL_ID = 'support-modal';

export const SUPPORT_TICKET_TYPES: SupportTicketType[] = [
  { id: 1, value: 'Billing' },
  { id: 2, value: 'Technical' },
  { id: 3, value: 'Other' },
];

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { NgxEchartsModule } from 'ngx-echarts';
import { AnalyticsModule } from './analytics/analytics.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthEffects } from './auth/store/auth.effects';
import { AuthInterceptor } from './auth/utility/auth.interceptor';
import { AuthService } from './auth/utility/auth.service';
import { CoreModule } from './core/core.module';
import { IdleWarningDialogModule } from './core/idle-watch/idle-warning-dialog/idle-warning-dialog.module';
import { GlobalService } from './core/services/global.service';
import { InsightsModule } from './insights/insights.module';
import { MATERIAL_PROVIDERS } from './material.providers';
import { MonitoringModule } from './monitoring/monitoring.module';
import { AdsModule } from './shared/ads/ads.module';
import { SubscriptionEffects } from './shared/subscription/store/subscription.effects';
import { SubscriptionService } from './shared/subscription/subscription.service';
import { VehicleEffects } from './shared/vehicle/store/vehicle.effects';
import { metaReducers, reducers } from './store/app.reducers';
import { TA_LIB_PROVIDERS } from './ta-lib.providers';
import { getDateLocale, TranslocoRootModule } from './transloco-root.module';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeRu from '@angular/common/locales/ru';
import localeCn from '@angular/common/locales/zh';

// Register locales
registerLocaleData(localeFr);
registerLocaleData(localeEs);
registerLocaleData(localeRu);
registerLocaleData(localeCn);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    EffectsModule.forRoot([AuthEffects, VehicleEffects, SubscriptionEffects]),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    CoreModule,
    TranslocoRootModule,

    MatNativeDateModule,
    MatDialogModule,
    NgbModule,

    // TODO: These modules should ideally be lazy-loaded
    InsightsModule,
    AnalyticsModule,
    MonitoringModule,

    IdleWarningDialogModule,
    AdsModule,
  ],
  providers: [
    AuthService,
    GlobalService,
    SubscriptionService,
    // { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6LdCO0ApAAAAAGlgTlAVMm22JZHQ3qc89068aLH9' } as RecaptchaSettings,
    },
    ...MATERIAL_PROVIDERS,
    ...TA_LIB_PROVIDERS,
    { provide: MAT_DATE_LOCALE, useValue: getDateLocale() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

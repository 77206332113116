import { Action, createReducer, on } from '@ngrx/store';
import { Loadable } from '../../../shared/loading-state/loadable';
import { WidgetDataResponse } from '../../../shared/widget/widget.models';
import { resetState } from '../../../store/app.actions';
import {
  AnalyticsDataDateRange,
  BatteryCapacityData,
  BatteryEfficiencyData,
  BatteryLifeData,
  BatteryRatingResponseData,
  ChargeCycleData,
  ChargerLocation,
  ChargingAnalyticsData,
  GeofenceAlertsData,
  HeatmapDataPoint,
  HeatmapDataRequestError,
  RoadSpeedData,
  UsageAnalyticsData,
  VehicleRankingData,
} from '../../analytics.models';
import * as AnalyticsActions from './analytics.actions';

import { cloneDeep } from 'lodash';

export type AnalyticsGeneralState = {
  loadingStatus: {
    activityData: boolean;
    alertData: boolean;
    chargingData: boolean;
    usageChargingHeatmapData: boolean;
    usageAnalyticsData: boolean;
    chargingAnalyticsData: boolean;
    geofenceAlertsAnalyticsData: boolean;
    vehicleRanking: boolean;
    dataDateRange: boolean;
  };
  travelData: Loadable<WidgetDataResponse>;
  activityData?: WidgetDataResponse | null;
  alertData?: WidgetDataResponse | null;
  chargingData?: WidgetDataResponse | null;
  chargingTypeData: Loadable<WidgetDataResponse>;
  tripData: Loadable<WidgetDataResponse>;
  usageChargingHeatmapData?: HeatmapDataPoint[] | HeatmapDataRequestError;
  usageAnalyticsData?: UsageAnalyticsData | null;
  chargingAnalyticsData?: ChargingAnalyticsData | null;
  geofenceAlertsAnalyticsData?: GeofenceAlertsData[] | null;
  vehicleRanking?: VehicleRankingData | null;
  dataDateRange?: AnalyticsDataDateRange | null;
  superchargers: Loadable<ChargerLocation>;
  batteryEfficiency: Loadable<BatteryEfficiencyData>;
  batteryCapacity: Loadable<BatteryCapacityData>;
  batteryLife: Loadable<BatteryLifeData>;
  chargeCycles: Loadable<ChargeCycleData>;
  depletion: Loadable<WidgetDataResponse>;
  batteryRating: Loadable<BatteryRatingResponseData>;
  roadSpeedData: Loadable<RoadSpeedData>;
};

const initialState: AnalyticsGeneralState = {
  loadingStatus: {
    activityData: false,
    alertData: false,
    chargingData: false,
    usageChargingHeatmapData: false,
    usageAnalyticsData: false,
    chargingAnalyticsData: false,
    geofenceAlertsAnalyticsData: false,
    vehicleRanking: false,
    dataDateRange: false,
  },
  travelData: new Loadable(),
  activityData: undefined,
  alertData: undefined,
  chargingData: undefined,
  chargingTypeData: new Loadable(),
  tripData: new Loadable(),
  usageChargingHeatmapData: undefined,
  usageAnalyticsData: undefined,
  chargingAnalyticsData: undefined,
  geofenceAlertsAnalyticsData: undefined,
  vehicleRanking: undefined,
  dataDateRange: undefined,
  superchargers: new Loadable(),
  batteryEfficiency: new Loadable(),
  batteryCapacity: new Loadable(),
  batteryLife: new Loadable(),
  chargeCycles: new Loadable(),
  depletion: new Loadable(),
  batteryRating: new Loadable(),
  roadSpeedData: new Loadable(),
};

const reducer = createReducer<AnalyticsGeneralState>(
  initialState,
  on(resetState, () => ({ ...initialState })),
  // Reset State to initial
  on(AnalyticsActions.clearState, () => ({
    ...initialState,
  })),
  // Travel Widget
  on(AnalyticsActions.getVehicleTravelData, state => ({
    ...state,
    travelData: state.travelData.loadingCopy(true),
  })),
  on(AnalyticsActions.setVehicleTravelData, (state, { travelData }) => ({
    ...state,
    travelData: new Loadable(cloneDeep(travelData), false),
  })),
  on(AnalyticsActions.setVehicleTravelDataError, state => ({
    ...state,
    travelData: new Loadable<WidgetDataResponse>(undefined, false, 'Unknown Error'),
  })),
  // Activity Widget
  on(AnalyticsActions.getVehicleActivityData, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      activityData: true,
    },
  })),
  on(AnalyticsActions.setVehicleActivityData, (state, { activityData }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      activityData: false,
    },
    activityData,
  })),
  // Alert Widget
  on(AnalyticsActions.getVehicleAlertData, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      alertData: true,
    },
  })),
  on(AnalyticsActions.setVehicleAlertData, (state, { alertData }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      alertData: false,
    },
    alertData,
  })),
  // Charging Widget
  on(AnalyticsActions.getVehicleChargingData, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      chargingData: true,
    },
  })),
  on(AnalyticsActions.setVehicleChargingData, (state, { chargingData }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      chargingData: false,
    },
    chargingData,
  })),
  on(AnalyticsActions.getVehicleChargingTypeData, state => ({
    ...state,
    chargingTypeData: state.chargingTypeData.loadingCopy(true),
  })),
  on(AnalyticsActions.setVehicleChargingTypeData, (state, { chargingData }) => ({
    ...state,
    chargingTypeData: new Loadable(cloneDeep(chargingData), false),
  })),
  on(AnalyticsActions.setVehicleChargingTypeDataError, state => ({
    ...state,
    chargingTypeData: new Loadable<WidgetDataResponse>(undefined, false, 'Unknown Error'),
  })),
  // Trip Widget
  on(AnalyticsActions.getVehicleTripData, state => ({
    ...state,
    tripData: state.tripData.loadingCopy(true),
  })),
  on(AnalyticsActions.setVehicleTripData, (state, { tripData }) => ({
    ...state,
    tripData: new Loadable(cloneDeep(tripData), false),
  })),
  on(AnalyticsActions.setVehicleTripDataError, state => ({
    ...state,
    tripData: new Loadable<WidgetDataResponse>(undefined, false, 'Unknown Error'),
  })),
  // Usage/Charging Heatmap Data
  on(AnalyticsActions.getUsageChargingHeatmapData, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      usageChargingHeatmapData: true,
    },
  })),
  on(AnalyticsActions.setUsageChargingHeatmapData, (state, { usageChargingHeatmapData }) => {
    const newLoading = {
      ...state.loadingStatus,
      usageChargingHeatmapData: false,
    };

    return {
      ...state,
      loadingStatus: newLoading,
      usageChargingHeatmapData: usageChargingHeatmapData,
    };
  }),
  // Usage Analytics Data
  on(AnalyticsActions.getUsageAnalyticsData, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      usageAnalyticsData: true,
    },
  })),
  on(AnalyticsActions.setUsageAnalyticsData, (state, { usageAnalyticsData }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      usageAnalyticsData: false,
    },
    usageAnalyticsData,
  })),
  // Charging Analytics Data
  on(AnalyticsActions.getChargingAnalyticsData, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      chargingAnalyticsData: true,
    },
  })),
  on(AnalyticsActions.setChargingAnalyticsData, (state, { chargingAnalyticsData }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      chargingAnalyticsData: false,
    },
    chargingAnalyticsData,
  })),
  // Geofence Alerts Analytics Data
  on(AnalyticsActions.getGeofenceAlertsAnalyticsData, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      geofenceAlertsAnalyticsData: true,
    },
  })),
  on(AnalyticsActions.setGeofenceAlertsAnalyticsData, (state, { geofenceAlertsAnalyticsData }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      geofenceAlertsAnalyticsData: false,
    },
    geofenceAlertsAnalyticsData,
  })),
  // Vehicle Ranking
  on(AnalyticsActions.getVehicleRanking, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleRanking: true,
    },
  })),
  on(AnalyticsActions.setVehicleRanking, (state, { vehicleRanking }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleRanking: false,
    },
    vehicleRanking,
  })),
  // Metadata
  on(AnalyticsActions.getVehicleAnalyticsDataDateRange, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      dataDateRange: true,
    },
  })),
  on(AnalyticsActions.setVehicleAnalyticsDataDateRange, (state, { dataDateRange }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      dataDateRange: false,
    },
    dataDateRange,
  })),
  // Data
  on(AnalyticsActions.getSuperchargers, state => ({
    ...state,
    superchargers: state.superchargers.loadingCopy(true),
  })),
  on(AnalyticsActions.setSuperchargers, (state, { superchargers, error }) => ({
    ...state,
    superchargers:
      error !== undefined ? state.superchargers.errorCopy(error) : new Loadable(superchargers),
  })),
  on(AnalyticsActions.getBatteryCapacity, state => ({
    ...state,
    batteryCapacity: state.batteryCapacity.loadingCopy(true),
  })),
  on(AnalyticsActions.getBatteryEfficiency, state => ({
    ...state,
    batteryEfficiency: state.batteryEfficiency.loadingCopy(true),
  })),
  on(AnalyticsActions.getBatteryLife, state => ({
    ...state,
    batteryLife: state.batteryLife.loadingCopy(true),
  })),
  on(AnalyticsActions.setBatteryCapacity, (state, { capacityData }) => ({
    ...state,
    batteryCapacity: new Loadable(cloneDeep(capacityData), false),
  })),
  on(AnalyticsActions.setBatteryEfficiency, (state, { efficiencyData }) => ({
    ...state,
    batteryEfficiency: new Loadable(cloneDeep(efficiencyData), false),
  })),
  on(AnalyticsActions.setBatteryLife, (state, { batteryLifeData }) => ({
    ...state,
    batteryLife: new Loadable(cloneDeep(batteryLifeData), false),
  })),
  on(AnalyticsActions.getBatteryEfficiency, state => ({
    ...state,
    batteryEfficiency: state.batteryEfficiency.loadingCopy(true),
  })),
  on(AnalyticsActions.getBatteryCapacity, state => ({
    ...state,
    batteryCapacity: state.batteryCapacity.loadingCopy(true),
  })),
  on(AnalyticsActions.setBatteryCapacityError, state => ({
    ...state,
    batteryCapacity: new Loadable<BatteryCapacityData>(undefined, false, 'Unknown Error'),
  })),
  on(AnalyticsActions.setBatteryEfficiencyError, state => ({
    ...state,
    batteryEfficiency: new Loadable<BatteryEfficiencyData>(undefined, false, 'Unknown Error'),
  })),
  on(AnalyticsActions.setBatteryLifeError, state => ({
    ...state,
    batteryLife: new Loadable<BatteryLifeData>(undefined, false, 'Unknown Error'),
  })),
  on(AnalyticsActions.getChargeCycles, state => ({
    ...state,
    chargeCycles: state.chargeCycles.loadingCopy(true),
  })),
  on(AnalyticsActions.setChargeCycles, (state, { chargeCycleData }) => ({
    ...state,
    chargeCycles: new Loadable(cloneDeep(chargeCycleData), false),
  })),
  on(AnalyticsActions.setChargeCyclesError, state => ({
    ...state,
    chargeCycles: new Loadable<ChargeCycleData>(undefined, false, 'Unknown Error'),
  })),
  on(AnalyticsActions.getDepletion, state => ({
    ...state,
    depletion: state.depletion.loadingCopy(true),
  })),
  on(AnalyticsActions.setDepletion, (state, { depletionData }) => ({
    ...state,
    depletion: new Loadable(cloneDeep(depletionData), false),
  })),
  on(AnalyticsActions.setDepletionError, state => ({
    ...state,
    depletion: new Loadable<WidgetDataResponse>(undefined, false, 'Unknown Error'),
  })),
  on(AnalyticsActions.getBatteryRating, state => ({
    ...state,
    batteryRating: state.batteryRating.loadingCopy(true),
  })),
  on(AnalyticsActions.setBatteryRating, (state, { batteryRatingData }) => ({
    ...state,
    batteryRating: new Loadable(cloneDeep(batteryRatingData), false),
  })),
  on(AnalyticsActions.setBatteryRatingError, state => ({
    ...state,
    batteryRating: new Loadable<BatteryRatingResponseData>(undefined, false, 'Unknown Error'),
  })),
  on(AnalyticsActions.getRoadSpeedData, state => ({
    ...state,
    roadSpeedData: state.roadSpeedData.loadingCopy(true),
  })),
  on(AnalyticsActions.setRoadSpeed, (state, { roadSpeedData }) => ({
    ...state,
    roadSpeedData: new Loadable(cloneDeep(roadSpeedData), false),
  })),
  on(AnalyticsActions.setRoadSpeedError, state => ({
    ...state,
    roadSpeedData: new Loadable<RoadSpeedData>(undefined, false, 'Unknown Error'),
  }))
);

export function analyticsGeneralReducer(
  state: AnalyticsGeneralState | undefined,
  action: Action
): AnalyticsGeneralState {
  return reducer(state, action);
}
export const analyticsGeneralFeatureKey = 'analyticsGeneral';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { getRouterOutletState } from '../shared/helpers/utility';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonitoringComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  getState = getRouterOutletState;
}

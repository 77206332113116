import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, first, map } from 'rxjs';
import { AppState } from '../../store/app.reducers';
import { selectSession, selectUserProfile } from '../store/auth.selectors';

@Injectable({ providedIn: 'root' })
class AuthGuardService {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    options?: { repOnly?: boolean }
  ): Observable<boolean | UrlTree> {
    return combineLatest([
      this.store.select(selectUserProfile),
      this.store.select(selectSession),
    ]).pipe(
      first(([, isAuthenticated]) => isAuthenticated !== undefined),
      map(([user, isAuthenticated]) => {
        // If user is not authenticated, redirect to login page
        if (!isAuthenticated) return this.router.createUrlTree(['auth', 'login']);

        // If user email is not verified, redirect to verify email page
        // if (!user || !user.emailVerified) {
        //   if (route.data['isEmailVerificationPage']) return true;
        //   return this.router.createUrlTree(['auth', 'verify-email'], {
        //     queryParams: {
        //       redirect_uri: environment.frontEndUrl + state.url,
        //     },
        //   });
        // }

        // If user has not accepted t&c or privacy policy, redirect to verify legals page
        // if (
        //   !(
        //     !user.first_name ||
        //     !user.last_name ||
        //     !user.country ||
        //     !user.postal_code ||
        //     !user.birth_year
        //   ) &&
        //   (user.accepted_privacy_policy !== 'checked' ||
        //     user.accepted_terms_and_conditions !== 'checked' ||
        //     user.accepted_terms_of_service !== 'checked')
        // ) {
        //   if (route.data['isVerifyLegalsPage']) return true;
        //   return this.router.createUrlTree(['auth', 'verify-legals'], {
        //     queryParams: {
        //       redirect_uri: environment.frontEndUrl + state.url,
        //     },
        //   });
        // }

        // const isUserValid =
        //   user.first_name &&
        //   user.last_name &&
        //   user.accepted_privacy_policy === 'checked' &&
        //   user.accepted_terms_and_conditions === 'checked' &&
        //   user.accepted_terms_of_service === 'checked' &&
        //   (user.is_individual
        //     ? user.country && user.postal_code && user.birth_year && user.gender
        //     : user.phone_number);

        // If user has not completed profile, redirect to complete profile page
        // if (!isUserValid) {
        //   if (route.data['isProfileSetupPage']) return true;
        //   return this.router.createUrlTree(['auth', 'profile-setup'], {
        //     queryParams: {
        //       redirect_uri: environment.frontEndUrl + state.url,
        //     },
        //   });
        // }

        // If repOnly is true and user is not a Zeva employee, redirect to 403 page
        if (options?.repOnly && !user?.is_staff) {
          const currentUrl = state.url;
          return this.router.createUrlTree([currentUrl, 'error', '403']);
        }

        return true;
      })
    );
  }
}

/**
 * This functions checks if a user session exists and is authenticated,
 * it also checks if the authenticated user has verified their email
 *
 * If any of the conditions above is not met the function will deny access to requested path and
 * redirect to an appropriate page:
 * - No Session / Not Authenticated (Session Expired) - `/auth/login`
 * - Email not verified - `/auth/verify`
 */
export const isAuthenticated: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => inject(AuthGuardService).canActivate(route, state);
export const isZevaRep: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => inject(AuthGuardService).canActivate(route, state, { repOnly: true });

import { createAction, props } from '@ngrx/store';
import { GeofenceDTO } from '../geofences.models';

export const getGeofenceList = createAction('[Geofences] Get Geofence List');
export const setGeofenceList = createAction(
  '[Geofences] Set Geofence List',
  props<{ geofenceList: GeofenceDTO[] | undefined }>()
);

export const createGeofence = createAction('[Geofences] Create Geofence', props<any>());

export const editGeofence = createAction('[Geofences] Edit Geofence', props<any>());

export const deleteGeofence = createAction(
  '[Geofences] Delete Geofence',
  props<{ geofenceId: number[] }>()
);

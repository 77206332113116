/**
 * SSO page views
 */
export enum SSOPageView {
  LOADING = 'loading',
  ERROR = 'error',
}

/**
 * SSO providers
 */
export enum SSOProvider {
  TESLA = 'tesla',
}

/**
 * SSO actions
 */
export enum SSOAction {
  SIGNUP = 'signup',
  LOGIN = 'login',
  CONNECT = 'connect',
  RECONNECT = 'reconnect',
}

/**
 * Object representing an internal redirect URI for SSO completion
 */
export type InternalRedirectURI = { path: string; params: { [param: string]: string } };

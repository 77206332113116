import { generateRandomString } from 'src/app/shared/helpers/utility';
import { environment } from 'src/environments/environment';
import { Company } from '../../utility/auth.models';
import { InternalRedirectURI, SSOAction, SSOProvider } from './sso.models';

/**
 * SSO redirect URL
 */
export const SSO_REDIRECT_URL = environment.frontEndUrl + '/auth/sso';

/**
 * Tesla SSO constants
 */
const TESLA_APP_ID = 'd06a2f6dc8fe-456e-93e0-e2dffede052b';
const TESLA_SCOPES = [
  'user_data',
  'vehicle_device_data',
  'vehicle_cmds',
  'vehicle_charging_cmds',
  'energy_device_data',
  'energy_cmds',
  'openid',
  'offline_access',
].join(' ');
// eslint-disable-next-line max-len
export const TESLA_REVOKE_ACCESS_URL = `https://auth.tesla.com/en_au/user/revoke/consent?revoke_client_id=${TESLA_APP_ID}`;

/**
 * Returns the Tesla SSO URI
 * @param {string} state - The random state to verify
 * @param {string} redirectUri - The redirect URI
 * @returns {string} - The Tesla SSO URI
 */
export function getTeslaSSOUri(state: string, redirectUri: string): string {
  const url =
    'https://auth.tesla.com/oauth2/v3/authorize' +
    `?client_id=${TESLA_APP_ID}` +
    '&locale=en-CA' +
    '&prompt=login' +
    '&response_type=code' +
    `&scope=${TESLA_SCOPES}` +
    `&state=${state}` +
    `&redirect_uri=${redirectUri}`;
  return url;
}

/**
 * Encodes the internal redirect URI as a dictionary
 * @param {string} internalRedirect - The URI to redirect internally to after SSO is complete
 * @returns {InternalRedirectURI} - The internal redirect URI dictionary
 */
function encodeInternalRedirect(internalRedirect: string): InternalRedirectURI {
  const url = new URL(decodeURIComponent(internalRedirect));
  const params: { [param: string]: string } = {};
  url.searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return {
    path: url.pathname,
    params: params,
  };
}

/**
 * Decodes the internal redirect URI from a dictionary
 * @param {InternalRedirectURI} internalRedirect - The internal redirect URI dictionary
 * @returns {string} - The URI to redirect internally to after SSO is complete
 */
export function decodeInternalRedirect(internalRedirect: InternalRedirectURI): string {
  const url = new URL(internalRedirect.path, window.location.origin);
  for (const key in internalRedirect.params)
    url.searchParams.set(key, internalRedirect.params[key]);
  return url.toString();
}

/**
 * Returns the redirect URI parameter for an SSO URI
 * @param {SSOProvider} provider - The SSO provider
 * @param {SSOAction} action - The action to perform after SSO is complete
 * @param {string} internalRedirect - The URI to redirect internally to after SSO is complete
 * @param {string} company [optional] - The name of the company this SSO is for
 * @param {string} extra [optional] - Extra params to be passed
 * @returns {string} The SSO redirect URL
 * @author Juan Corral
 */
export function generateSSORedirectUrl(
  provider: SSOProvider,
  action: SSOAction,
  internalRedirect: string,
  company?: string,
  extra?: string,
  encode = true
): string {
  const internalRedirectURI = JSON.stringify(encodeInternalRedirect(internalRedirect));
  let url =
    SSO_REDIRECT_URL +
    `?provider=${provider}` +
    `&action=${action}` +
    `&internalRedirect=${internalRedirectURI}`;
  if (company) url += `&company=${company}`;
  if (extra) url += `&extra=${extra}`;
  if (encode) url = encodeURIComponent(url);
  return url;
}

/**
 * Returns an SSO provider's URI
 * @param {SSOProvider} provider - The SSO provider
 * @param {SSOAction} action - The action to perform after SSO is complete
 * @param {string} internalRedirect - The URI to redirect internally to after SSO is complete
 * @param {Company} company [optional] - The company this SSO is for
 * @param {string} extra [optional] - Extra params to be passed
 * @returns {string | undefined} - The SSO Provider URL or undefined if provider is not supported
 * @author Juan Corral
 */
export function getSSOProviderUri(
  provider: SSOProvider,
  action: SSOAction,
  internalRedirect: string,
  company?: Company,
  extra?: string
): string | undefined {
  const state = generateRandomString(10);
  const redirect = generateSSORedirectUrl(
    provider,
    action,
    internalRedirect,
    company?.company_name,
    extra
  );

  // Return URI
  switch (provider) {
    case SSOProvider.TESLA:
      return getTeslaSSOUri(state, redirect);
    default:
      return undefined;
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Loadable } from '../../../../shared/loading-state/loadable';
import { resetState } from '../../../../store/app.actions';
import { LiveTrafficData } from '../live.models';
import * as LiveActions from './live.actions';

export type LiveState = {
  trafficData: Loadable<LiveTrafficData>;
};

const initialState: LiveState = {
  trafficData: new Loadable(),
};

const reducer = createReducer<LiveState>(
  initialState,
  on(resetState, () => ({ ...initialState })),
  // -------- Traffic Data
  on(LiveActions.getLiveTrafficData, (state) => ({
    ...state,
    trafficData: state.trafficData.loadingCopy(true),
  })),
  on(LiveActions.setLiveTrafficData, (state, { data }) => ({
    ...state,
    trafficData: new Loadable(cloneDeep(data), false),
  })),
  on(LiveActions.setLiveTrafficDataError, (state) => ({
    ...state,
    trafficData: new Loadable<LiveTrafficData>(undefined, false, 'Unknown error'),
  })),
  on(LiveActions.getLiveRouteData, (state) => ({
    ...state,
    trafficData: state.trafficData.loadingCopy(true),
  })),
  on(LiveActions.setLiveRouteData, (state, { data }) => ({
    ...state,
    trafficData: new Loadable(cloneDeep(data), false),
  })),
  on(LiveActions.setLiveRouteDataError, (state) => ({
    ...state,
    trafficData: new Loadable<any>(undefined, false, 'Unknown error'),
  }))
);

export function liveReducer(state: LiveState | undefined, action: Action): any {
  return reducer(state, action);
}
export const liveFeatureKey = 'live';

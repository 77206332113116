import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HasPermissionsPipe, HasSomePermissionPipe } from './has-permissions.pipe';
import { PermissionsService } from './permissions.service';

@NgModule({
  declarations: [HasPermissionsPipe, HasSomePermissionPipe],
  exports: [HasPermissionsPipe, HasSomePermissionPipe],
  imports: [CommonModule],
  providers: [PermissionsService],
})
export class PermissionsModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { LoadingModule } from '../../shared/loading/loading.module';
import { SupportModalComponent } from './support-modal.component';
import { SupportService } from './support.service';

@NgModule({
  declarations: [SupportModalComponent],
  imports: [
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    LoadingModule,
    StoreModule,
    RouterModule,
    MatSelectModule,
    MatIconModule,
  ],
  exports: [SupportModalComponent],
  providers: [SupportService],
})
export class SupportModule {}

import { createAction, props } from '@ngrx/store';
import {
  Company,
  IpData,
  UserPermissions,
  UserPreferencesDTO,
  UserProfile,
} from '../utility/auth.models';

export const RESET = '[Auth] Reset State';
export const reset = createAction(RESET);

export const getCSRFToken = createAction('[Auth] Get CSRF Token');
export const setCSRFToken = createAction(
  '[Auth] Set CSRF Token',
  props<{ csrfToken: string | null }>()
);

export const getSession = createAction('[Auth] Get Session');
export const setSession = createAction(
  '[Auth] Set Session',
  props<{ session: { isAuthenticated: boolean | undefined; user?: UserProfile | null } }>()
);

export const signOut = createAction('[Auth] Sign Out');

export const setUserProfile = createAction(
  '[Auth] Set User Profile',
  props<{ userProfile: UserProfile | null }>()
);

export const getCompanies = createAction('[Auth] Get User Companies');
export const setCompanies = createAction(
  '[Auth] Set User Companies',
  props<{ companies: Company[] | null; error?: string }>()
);

export const setSelectedCompany = createAction(
  '[Auth] Set Selected Company',
  props<{ company: Company | undefined }>()
);

export const getUserPermissions = createAction('[Auth] Get User Permissions');
export const updateUserPermissions = createAction(
  '[Auth] Update UserPermissions',
  props<{ permissions: UserPermissions }>()
);

export const getUserPreferences = createAction('[Auth] Get User Preferences');
export const setUserPreferences = createAction(
  '[Auth] Set User Preferences',
  props<{ preferences: UserPreferencesDTO | null; error?: string }>()
);

export const getIpData = createAction('[Auth] Get IP Data');
export const setIpData = createAction(
  '[Auth] Set IP Data',
  props<{ ipData: IpData | null; error?: string }>()
);

import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import {
  ChargingInvoice,
  DriverInviteDictionary,
  HomeChargingInvoice,
  PhantomDrainResponse,
  RangeEstimateResponse,
  VehicleAlertsResponse,
  VehicleChargeLocationResponse,
  VehicleDestinationResponse,
  VehicleOwnership,
} from '../../../monitoring/monitoring.models';
import { Loadable } from '../../../shared/loading-state/loadable';
import { WidgetDataResponse } from '../../../shared/widget/widget.models';
import { resetState } from '../../../store/app.actions';
import {
  ChargingSchedule,
  ChargingSettings,
} from '../../pages/control/components/charging-management/charging-management.models';
import { CommandLogEntry } from '../../pages/control/components/vehicle-log/vehicle-log.models';
import { Weather } from '../../pages/live/live.models';
import * as MonitoringActions from './monitoring.actions';

export type MonitoringGeneralState = {
  loadingStatus: {
    vehicleAlerts: boolean;
    commandLogs: boolean;
  };
  vehicleAlerts?: VehicleAlertsResponse | null;
  vehicleLogs?: CommandLogEntry[] | null;
  chargingSchedules: Loadable<ChargingSchedule[] | null>;
  chargingSettings: Loadable<ChargingSettings | null>;
  destinations: Loadable<VehicleDestinationResponse>;
  chargeLocations: Loadable<VehicleChargeLocationResponse>;
  vehicleOwnership: Loadable<VehicleOwnership | null>;
  chargingInvoices: Loadable<ChargingInvoice[] | null>;
  homeChargingInvoices: Loadable<HomeChargingInvoice[] | null>;
  chargeHistory: Loadable<WidgetDataResponse<'Charge'>>;
  rangeEstimate: Loadable<RangeEstimateResponse>;
  phantomDrain: Loadable<PhantomDrainResponse>;
  driverInviteData: Loadable<DriverInviteDictionary>;
  weather: Loadable<Weather>;
};

const initialState: MonitoringGeneralState = {
  loadingStatus: {
    vehicleAlerts: false,
    commandLogs: false,
  },
  chargingSchedules: new Loadable(),
  chargingSettings: new Loadable(),
  destinations: new Loadable(),
  chargeLocations: new Loadable(),
  vehicleOwnership: new Loadable(),
  chargingInvoices: new Loadable(),
  homeChargingInvoices: new Loadable(),
  chargeHistory: new Loadable(),
  rangeEstimate: new Loadable(),
  phantomDrain: new Loadable(),
  driverInviteData: new Loadable(),
  weather: new Loadable(),
};

const reducer = createReducer<MonitoringGeneralState>(
  initialState,
  on(resetState, () => ({ ...initialState })),
  // -------- Vehicle Destinations
  on(MonitoringActions.getVehicleDestinations, (state) => ({
    ...state,
    destinations: state.destinations.loadingCopy(true),
  })),
  on(MonitoringActions.setVehicleDestinations, (state, { destinations }) => ({
    ...state,
    destinations: new Loadable(cloneDeep(destinations), false),
  })),
  on(MonitoringActions.setDestinationsError, (state) => ({
    ...state,
    destinations: new Loadable<VehicleDestinationResponse>(undefined, false, 'Unknown Error'),
  })),
  // -------- Vehicle Charge Locations
  on(MonitoringActions.getVehicleChargeLocations, (state) => ({
    ...state,
    chargeLocations: state.chargeLocations.loadingCopy(true),
  })),
  on(MonitoringActions.setVehicleChargeLocations, (state, { locations }) => ({
    ...state,
    chargeLocations: new Loadable(cloneDeep(locations), false),
  })),
  on(MonitoringActions.setChargeLocationsError, (state) => ({
    ...state,
    chargeLocations: new Loadable<VehicleChargeLocationResponse>(undefined, false, 'Unknown Error'),
  })),
  // -------- Vehicle Alerts
  on(MonitoringActions.getVehicleAlerts, (state) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleAlerts: true,
    },
  })),
  on(MonitoringActions.setVehicleAlerts, (state, { alerts }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleAlerts: false,
    },
    vehicleAlerts: alerts,
  })),
  // -------- Vehicle Charge History
  on(MonitoringActions.setChargeHistory, (state, { chargeHistory }) => ({
    ...state,
    chargeHistory: new Loadable(cloneDeep(chargeHistory), false, undefined),
  })),
  on(MonitoringActions.getChargeHistory, (state) => ({
    ...state,
    chargeHistory: state.chargeHistory.loadingCopy(true),
  })),
  on(MonitoringActions.setChargeHistoryError, (state) => ({
    ...state,
    chargeHistory: new Loadable<WidgetDataResponse<'Charge'>>(undefined, false, 'Unknown error'),
  })),
  // -------- Vehicle Command Log
  on(MonitoringActions.getVehicleLogs, (state) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      commandLogs: true,
    },
  })),
  on(MonitoringActions.setVehicleLogs, (state, { vehicleLogs }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleLogs: false,
    },
    vehicleLogs,
  })),
  // -------- Charging Schedules
  on(MonitoringActions.getVehicleChargingSchedules, (state) => ({
    ...state,
    chargingSchedules: state.chargingSchedules.loadingCopy(true),
  })),
  on(MonitoringActions.setVehicleChargingSchedules, (state, { schedules, error }) => ({
    ...state,
    chargingSchedules: !error ? new Loadable(schedules) : state.chargingSchedules.errorCopy(error),
  })),
  // -------- Charging Settings
  on(MonitoringActions.getVehicleChargingSettings, (state) => ({
    ...state,
    chargingSettings: state.chargingSettings.loadingCopy(true),
  })),
  on(MonitoringActions.setVehicleChargingSettings, (state, { settings, error }) => ({
    ...state,
    chargingSettings: !error ? new Loadable(settings) : state.chargingSettings.errorCopy(error),
  })),
  // -------- Charging Invoices
  on(MonitoringActions.getVehicleOwnership, (state) => ({
    ...state,
    vehicleOwnership: state.vehicleOwnership.loadingCopy(true),
  })),
  on(MonitoringActions.setVehicleOwnership, (state, { ownership }) => ({
    ...state,
    vehicleOwnership: new Loadable(ownership),
  })),
  on(MonitoringActions.getChargingInvoices, (state) => ({
    ...state,
    chargingInvoices: state.chargingInvoices.loadingCopy(true),
  })),
  on(MonitoringActions.setChargingInvoices, (state, { chargingInvoices, error }) => ({
    ...state,
    chargingInvoices: !error
      ? new Loadable(chargingInvoices)
      : state.chargingInvoices.errorCopy(error),
  })),
  // -------- Home Charging Invoices
  on(MonitoringActions.getHomeChargingInvoices, (state) => ({
    ...state,
    homeChargingInvoices: state.homeChargingInvoices.loadingCopy(true),
  })),
  on(MonitoringActions.setHomeChargingInvoices, (state, { chargingInvoices, error }) => ({
    ...state,
    homeChargingInvoices: !error
      ? new Loadable(chargingInvoices)
      : state.homeChargingInvoices.errorCopy(error),
  })),
  // --------- Range Estimates
  on(MonitoringActions.setRangeEstimate, (state, { rangeEstimate }) => ({
    ...state,
    rangeEstimate: new Loadable(cloneDeep(rangeEstimate), false, undefined),
  })),
  on(MonitoringActions.getRangeEstimate, (state) => ({
    ...state,
    rangeEstimate: state.rangeEstimate.loadingCopy(true),
  })),
  on(MonitoringActions.setRangeEstimateError, (state) => ({
    ...state,
    rangeEstimate: new Loadable<RangeEstimateResponse>(undefined, false, 'Unknown error'),
  })),
  // ---------- Phantom Drain
  on(MonitoringActions.setPhantomDrain, (state, { phantomDrain }) => ({
    ...state,
    phantomDrain: new Loadable(cloneDeep(phantomDrain), false, undefined),
  })),
  on(MonitoringActions.getPhantomDrain, (state) => ({
    ...state,
    phantomDrain: state.phantomDrain.loadingCopy(true),
  })),
  on(MonitoringActions.setPhantomDrainError, (state) => ({
    ...state,
    phantomDrain: new Loadable<PhantomDrainResponse>(undefined, false, 'Unknown error'),
  })),
  // ---------- Driver Invites
  on(MonitoringActions.getDriverInvites, (state) => ({
    ...state,
    inviteData: state.driverInviteData.loadingCopy(true),
  })),
  on(MonitoringActions.setDriverInvites, (state, { invite_data }) => ({
    ...state,
    driverInviteData: new Loadable<DriverInviteDictionary>(
      cloneDeep(invite_data),
      false,
      undefined
    ),
  })),
  on(MonitoringActions.setDriverInvitesError, (state, { error }) => ({
    ...state,
    driverInviteData: new Loadable<DriverInviteDictionary>(undefined, false, error),
  })),
  // -------- Weather
  on(MonitoringActions.getWeather, (state) => ({
    ...state,
    weather: state.weather.loadingCopy(true),
  })),
  on(MonitoringActions.setWeather, (state, { weather, error }) => ({
    ...state,
    weather: !error ? new Loadable(weather) : state.weather.errorCopy(error),
  }))
);

export function monitoringGeneralReducer(
  state: MonitoringGeneralState | undefined,
  action: Action
): MonitoringGeneralState {
  return reducer(state, action);
}
export const monitoringGeneralFeatureKey = 'monitoringGeneral';

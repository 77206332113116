import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: 'zeva-card-header, [zeva-card-header]',
})
export class CardHeaderDirective implements AfterViewInit {
  /** Projects the passed classes onto the card-header container */
  @Input('class')
  reflectClass: string | string[] | Set<string> = '';

  constructor(private readonly elem: ElementRef, private readonly renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.renderer.addClass(this.elem.nativeElement, 'card-header');
  }
}

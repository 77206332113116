import { Injectable } from '@angular/core';
import { NavigationGateway, Place } from '@temerity-analytics/ngx-ta-maps';
import { firstValueFrom, map } from 'rxjs';
import { HttpClientWrapper } from '../../http-client-wrapper';
import { AUTOCOMPLETE, DISCOVER, GEOCODE, LOOKUP } from './navigation-urls';

/**
 * A response representing a place that has not yet been converted into a place object
 */
type ValidPlaceResponse = {
  /**
   * The zoom level to display this place at
   */
  zoom: number;

  /**
   * The title of this place
   */
  title: string | null;

  /**
   * Unique ID for this place
   */
  location_id: string;

  /**
   * Address of this place
   */
  address: string | null;

  /**
   * Center of this place in long lat coordinates
   */
  center: [long: number, lat: number] | null;

  /**
   * Country in which this place is located
   */
  country: string | null;

  /**
   * Code of the country in which this place is located
   */
  country_code: string | null;

  /**
   * State in which this place is located
   */
  state: string | null;

  /**
   * Code of the state in which this place is located
   */
  state_code: string | null;
};

/**
 * Service for performing navigation related tasks
 */
// @Injectable annotation not necessarily currently needed, but when
// transition to class injection instead of injection token is done,
// it will be needed then
@Injectable()
export class NavigationService implements NavigationGateway {
  constructor(private httpClient: HttpClientWrapper) {}

  private _placeTypeGuard(response: unknown): response is ValidPlaceResponse {
    const converted = response as ValidPlaceResponse;
    return !!converted.location_id;
  }

  private _responseToPlace(response: ValidPlaceResponse): Place {
    return {
      ...response,
      location_id: undefined,
      country_code: undefined,
      state_code: undefined,
      id: response.location_id,
      countryCode: response.country_code,
      stateCode: response.state_code,
    } as Place;
  }

  geocode(searchString: string): Promise<Place | null> {
    return firstValueFrom(
      this.httpClient
        .get(GEOCODE, {
          place: searchString,
        })
        .pipe(
          map(response => (this._placeTypeGuard(response) ? this._responseToPlace(response) : null))
        )
    );
  }

  lookup(id: string): Promise<Place | null> {
    return firstValueFrom(
      this.httpClient
        .get(LOOKUP, {
          location_id: id,
        })
        .pipe(
          map(response => (this._placeTypeGuard(response) ? this._responseToPlace(response) : null))
        )
    );
  }

  autocomplete(searchString: string): Promise<Place[]> {
    return firstValueFrom(
      this.httpClient
        .get(AUTOCOMPLETE, {
          partial_place: searchString,
        })
        .pipe(
          map(response => response as ValidPlaceResponse[]),
          map(response =>
            response.filter(x => this._placeTypeGuard(x)).map(x => this._responseToPlace(x))
          )
        )
    );
  }

  discoverNear(place: string, lat: number, lng: number): Promise<Place[]> {
    return firstValueFrom(
      this.httpClient
        .get(DISCOVER, {
          place: place,
          lat: lat.toString(),
          lng: lng.toString(),
        })
        .pipe(
          map(response => response as ValidPlaceResponse[]),
          map(response =>
            response.filter(x => this._placeTypeGuard(x)).map(x => this._responseToPlace(x))
          )
        )
    );
  }
}

import { createAction, props } from '@ngrx/store';
import {
  ChargingInvoice,
  DriverInviteDictionary,
  DriverInviteUpdateData,
  HomeChargingInvoice,
  PhantomDrainResponse,
  RangeEstimateResponse,
  VehicleAlertsResponse,
  VehicleChargeLocationResponse,
  VehicleDestinationResponse,
  VehicleOwnership,
} from '../../../monitoring/monitoring.models';
import { WidgetDataResponse } from '../../../shared/widget/widget.models';
import {
  ChargingSchedule,
  ChargingSettings,
} from '../../pages/control/components/charging-management/charging-management.models';
import { CommandLogEntry } from '../../pages/control/components/vehicle-log/vehicle-log.models';
import { Weather } from '../../pages/live/live.models';

export const getRangeEstimate = createAction(
  '[Monitoring] Get Range Estimate',
  props<{ vehicles: number[] }>()
);
export const setRangeEstimate = createAction(
  '[Monitoring] Set Range Estimate',
  props<{ rangeEstimate: RangeEstimateResponse | null }>()
);
export const setRangeEstimateError = createAction('[Monitoring] Set Range Estimate Error');

export const getPhantomDrain = createAction(
  '[Monitoring] Get Phantom Drain',
  props<{
    vehicles: number[];
    time_0: Date;
    time_1: Date;
  }>()
);
export const setPhantomDrain = createAction(
  '[Monitoring] Set Phantom Drain',
  props<{ phantomDrain: PhantomDrainResponse | null }>()
);
export const setPhantomDrainError = createAction('[Monitoring] Set Phantom Drain Error');

export const getVehicleAlerts = createAction(
  '[Monitoring] Get Vehicle Alerts',
  props<{
    time_0?: string;
    time_1?: string;
    vehicle?: number[];
  }>()
);
export const setVehicleAlerts = createAction(
  '[Monitoring] Set Vehicle Alerts',
  props<{ alerts: VehicleAlertsResponse | null }>()
);

export const getVehicleDestinations = createAction(
  '[Monitoring] Get Vehicle Destinations',
  props<{ vehicle: number; count: number }>()
);
export const setVehicleDestinations = createAction(
  '[Monitoring] Set Vehicle Destinations',
  props<{ destinations: VehicleDestinationResponse }>()
);
export const setDestinationsError = createAction('[Monitoring] Set Vehicle Destinations Error');

export const getVehicleChargeLocations = createAction(
  '[Monitoring] Get Vehicle Charge Locations',
  props<{ vehicle: number; count: number }>()
);
export const setVehicleChargeLocations = createAction(
  '[Monitoring] Set Vehicle Charge Locations',
  props<{ locations: VehicleChargeLocationResponse }>()
);
export const setChargeLocationsError = createAction(
  '[Monitoring] Set Vehicle Charge Locations Error'
);

export const getVehicleLogs = createAction(
  '[Monitoring] Get Vehicle Logs',
  props<{ vehicles: number[] }>()
);
export const setVehicleLogs = createAction(
  '[Monitoring] Set Vehicle Logs',
  props<{ vehicleLogs: CommandLogEntry[] | null }>()
);

export const setChargeHistory = createAction(
  '[Monitoring] Set Charge History',
  props<{ chargeHistory: WidgetDataResponse<'Charge'> }>()
);
export const getChargeHistory = createAction(
  '[Monitoring] Get Charge History',
  props<{
    time_0: Date;
    time_1: Date;
    vehicle: number;
  }>()
);
export const setChargeHistoryError = createAction('[Monitoring] Set Charge History Error');

// -------------- Driver Invite
export const createDriverInvite = createAction(
  '[Control] Create Vehicle Driver Invites',
  props<{ vehicle: number; revoke_date: Date | null }>()
);

export const updateDriverInvite = createAction(
  '[Control] Update Vehicle Driver Invites',
  props<{ update: DriverInviteUpdateData; vehicle: number }>()
);

export const deleteDriverInvite = createAction(
  '[Control] Delete Vehicle Driver Invites',
  props<{ id: number; vehicle: number }>()
);

export const getDriverInvites = createAction(
  '[Control] Get Vehicle Driver Invites',
  props<{ vehicle: number }>()
);

export const setDriverInvites = createAction(
  '[Control] Set Vehicle Driver Invites',
  props<{ invite_data: DriverInviteDictionary | null }>()
);

export const setDriverInvitesError = createAction(
  '[Control] Set Vehicle Driver Invites Error',
  props<{ error: string | undefined }>()
);

// -------------- Charging Schedule
export const createVehicleChargingSchedule = createAction(
  '[Monitoring] Create Vehicle Charging Schedule',
  props<{ schedule: ChargingSchedule; vehicles: number[] }>()
);
export const deleteVehicleChargingSchedule = createAction(
  '[Monitoring] Delete Vehicle Charging Schedules',
  props<{ schedule_id: number; vehicles: number[] }>()
);
export const getVehicleChargingSchedules = createAction(
  '[Monitoring] Get Vehicle Charging Schedules',
  props<{ vehicles: number[] }>()
);
export const setVehicleChargingSchedules = createAction(
  '[Monitoring] Set Vehicle Charging Schedules',
  props<{ schedules: ChargingSchedule[] | null; error?: string }>()
);
export const setVehicleChargingScheduleError = createAction(
  '[Monitoring] Set Vehicle Charging Schedule Error',
  props<{ error: string }>()
);

// -------------- Charging Settings
export const getVehicleChargingSettings = createAction(
  '[Monitoring] Get Vehicle Charging Settings',
  props<{ vehicle: number }>()
);
export const setVehicleChargingSettings = createAction(
  '[Monitoring] Set Vehicle Charging Settings',
  props<{ settings: ChargingSettings | null; error?: string }>()
);

// -------------- Charging Costs
export const getVehicleOwnership = createAction(
  '[Monitoring] Get Vehicle Ownership',
  props<{ vehicles: number[] }>()
);
export const setVehicleOwnership = createAction(
  '[Monitoring] Set Vehicle Ownership',
  props<{ ownership: VehicleOwnership | null }>()
);

export const getChargingInvoices = createAction(
  '[Monitoring] Get Charging Costs',
  props<{ vehicles: number[]; time_0: Date; time_1: Date }>()
);
export const setChargingInvoices = createAction(
  '[Monitoring] Set Charging Costs',
  props<{ chargingInvoices: ChargingInvoice[] | null; error?: string }>()
);

export const getHomeChargingInvoices = createAction(
  '[Monitoring] Get Home Charging Costs',
  props<{ vehicles: number[]; time_0: Date; time_1: Date, pagination?: number }>()
);
export const setHomeChargingInvoices = createAction(
  '[Monitoring] Set Home Charging Costs',
  props<{ chargingInvoices: HomeChargingInvoice[] | null; error?: string }>()
);

// -------- Weather
export const getWeather = createAction(
  '[Monitoring] Get Vehicle Weather',
  props<{ vehicle: number }>()
);

export const setWeather = createAction(
  '[Monitoring] Set Vehicle Weather',
  props<{ weather: Weather | null; error?: string }>()
);

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { CardGridModule } from '../../core/card-grid/card-grid.module';
import { ContentPlaceholderModule } from '../content-placeholder/content-placeholder.module';
import { TimeFormatPipe } from '../helpers/pipes/unit-conversion/time-format.pipe';
import { WidgetHelpersModule } from './helpers/widget-helpers.module';
import { WidgetCarouselComponent } from './widget-carousel/widget-carousel.component';
import { WidgetComponent, WidgetSourceDirective } from './widget.component';
@NgModule({
  imports: [
    CommonModule,
    CardGridModule,
    WidgetHelpersModule,
    MatButtonModule,
    MatIconModule,
    NgbPopoverModule,
    DragDropModule,
    ContentPlaceholderModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatFormFieldModule,
    MatOptionModule,
    TranslocoModule,
  ],
  declarations: [WidgetComponent, WidgetSourceDirective, WidgetCarouselComponent],
  exports: [WidgetComponent, WidgetSourceDirective, WidgetCarouselComponent],
  providers: [TimeFormatPipe],
})
export class WidgetModule {}

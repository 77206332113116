import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: 'zeva-card-footer, [zeva-card-footer]',
})
export class CardFooterDirective {
  /** Projects the passed classes onto the card-footer container */
  @Input('class')
  reflectClass: string | string[] | Set<string> = '';

  constructor(private readonly elem: ElementRef, private readonly renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.renderer.addClass(this.elem.nativeElement, 'card-footer');
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { Loadable } from 'src/app/shared/loading-state/loadable';
import { resetState } from 'src/app/store/app.actions';
import { Company, IpData, UserPreferencesDTO, UserProfile } from '../utility/auth.models';
import * as AuthActions from './auth.actions';

export type AuthState = {
  loadingStatus: {
    session: boolean;
    signIn: boolean;
    userProfile: boolean;
  };
  isAuthenticated?: boolean;
  csrfToken: Loadable<string>;
  userProfile?: UserProfile | null;
  companies: Loadable<Company[] | null>;
  selectedCompany?: Company;
  userPreferences: Loadable<UserPreferencesDTO>;
  ipData: Loadable<IpData>;
};

const initialState: AuthState = {
  loadingStatus: {
    session: false,
    signIn: false,
    userProfile: false,
  },
  companies: new Loadable(),
  csrfToken: new Loadable(),
  userPreferences: new Loadable(),
  ipData: new Loadable(),
};

const reducer = createReducer<AuthState>(
  initialState,
  on(resetState, (state, { resetAuth }) => (resetAuth ? { ...initialState } : state)),
  on(AuthActions.getSession, state => state),
  on(AuthActions.setSession, (state, { session }) => {
    const newState = {
      ...state,
      isAuthenticated: session.isAuthenticated,
    };
    if (session.user) newState.userProfile = session.user;

    return newState;
  }),
  on(AuthActions.getCSRFToken, state => ({
    ...state,
    csrfToken: state.csrfToken.loadingCopy(true),
  })),
  on(AuthActions.setCSRFToken, (state, { csrfToken }) => ({
    ...state,
    csrfToken: new Loadable(csrfToken),
  })),
  on(AuthActions.setUserProfile, (state, { userProfile }) => {
    return {
      ...state,
      loadingStatus: {
        ...state.loadingStatus,
        signIn: false,
      },
      userProfile,
    };
  }),
  on(AuthActions.signOut, () => ({
    ...initialState,
  })),
  // Company List & Selection
  on(AuthActions.getCompanies, state => ({
    ...state,
    companies: state.companies.loadingCopy(true),
  })),
  on(AuthActions.setCompanies, (state, { companies, error }) => {
    const newCompanies = !error ? new Loadable(companies) : state.companies.errorCopy(error);
    const newState = {
      ...state,
      companies: newCompanies,
      selectedCompany: state.selectedCompany
        ? newCompanies.value?.find(c => c.id === state.selectedCompany!.id)
        : undefined,
    };
    return newState;
  }),
  on(AuthActions.setSelectedCompany, (state, { company }) => ({
    ...state,
    selectedCompany: company,
  })),
  // User Permissions
  on(AuthActions.getUserPermissions, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      userProfile: true,
    },
  })),
  on(AuthActions.updateUserPermissions, (state, { permissions }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      userProfile: false,
    },
    userProfile: { ...state.userProfile!, permissions },
  })),
  // User Preferences
  on(AuthActions.getUserPreferences, state => ({
    ...state,
    userPreferences: state.userPreferences.loadingCopy(true),
  })),
  on(AuthActions.setUserPreferences, (state, { preferences, error }) => ({
    ...state,
    userPreferences:
      error === undefined ? new Loadable(preferences) : state.userPreferences.errorCopy(error),
  })),
  // IP Data
  on(AuthActions.getIpData, state => ({
    ...state,
    ipData: state.ipData.loadingCopy(true),
  })),
  on(AuthActions.setIpData, (state, { ipData, error }) => ({
    ...state,
    ipData: error === undefined ? new Loadable(ipData) : state.ipData.errorCopy(error),
  }))
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
  return reducer(state, action);
}
export const authFeatureKey = 'auth';

// providers (OEM)
export const connectAPIUrl = 'connections/connect/';
export const providersUrl = 'connections/providers/';

// users
export const usersUrl = 'client/users/';
export const signupUserUrl = 'client/signup_user/';
export const invitesUrl = 'client/invite/';
export const invitesFormUrl = 'client/invite_form/';
export const legalStuffUrl = 'client/legal/';

// permissions
export const permissionsListUrl = 'client/permissions/all/';
export const permissionsGroupsUrl = 'client/permissions/groups/';
export const permissionsRequestTransferUrl = 'client/permissions/request_transfer/';
export const permissionsOwnershipTransferUrl = 'client/permissions/ownership_transfer/';

// billing
export const billingDetailsUrl = 'billing/billing-details/';
export const billingCardUrl = 'billing/card/';
export const subscriptionInvoicesUrl = 'billing/invoices/';
export const updateSpotsUrl = 'billing/subscriptions/update_spots/';

export const vehicleAccessUrl = 'client/vehicle_access/';

export const trackingUrl = 'internal/tracker/';

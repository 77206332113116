import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';
import { AppState } from 'src/app/store/app.reducers';
import { VehicleDataService } from './vehicle-data.service';
import * as VehicleActions from './vehicle.actions';

@Injectable()
export class VehicleEffects {
  getVehicleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.getVehicleList),
      switchMap(() =>
        this.dataService.getVehicleList().pipe(
          map(vehicleList => VehicleActions.setVehicleList({ vehicleList })),
          catchError(() => of(VehicleActions.setVehicleList({ vehicleList: null })))
        )
      )
    )
  );

  createVehicleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.createVehicleList),
      switchMap(action =>
        this.dataService.createVehicleList(action.vehicleList, action.tokenId).pipe(
          map(vehicleList => VehicleActions.setVehicleList({ vehicleList })),
          catchError(() => of(VehicleActions.setVehicleList({ vehicleList: null, noUpdate: true })))
        )
      )
    )
  );

  updateVehicleDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.updateVehicleDetails),
      switchMap(action =>
        this.dataService.updateVehicleDetails(action.vehicle).pipe(
          map(vehicleList => VehicleActions.setVehicleList({ vehicleList })),
          catchError(() => of(VehicleActions.setVehicleList({ vehicleList: null, noUpdate: true })))
        )
      )
    )
  );

  deleteVehicleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.deleteVehicleList),
      switchMap(action =>
        this.dataService.deleteVehicleList(action.vehicleIds, action.permanent).pipe(
          map(vehicleList => VehicleActions.setVehicleList({ vehicleList })),
          catchError(() => of(VehicleActions.setVehicleList({ vehicleList: null, noUpdate: true })))
        )
      )
    )
  );

  unarchiveVehicleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.unarchiveVehicleList),
      switchMap(action =>
        this.dataService.unarchiveVehicleList(action.vehicleIds).pipe(
          map(vehicleList => VehicleActions.setVehicleList({ vehicleList })),
          catchError(() => of(VehicleActions.setVehicleList({ vehicleList: null, noUpdate: true })))
        )
      )
    )
  );

  updateVehicleColor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.updateVehicleColor),
      switchMap(action =>
        this.dataService.updateVehicleColor(action.vehicleId, action.newColor).pipe(
          map(colorData =>
            VehicleActions.setVehicleColor({
              vehicleId: action.vehicleId,
              newColor: colorData.after,
            })
          ),
          catchError(() => of())
        )
      )
    )
  );

  getFleetList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.getFleetList),
      switchMap(() =>
        this.dataService.getFleetList().pipe(
          map(fleetList => VehicleActions.setFleetList({ fleetList })),
          catchError(() => of(VehicleActions.setFleetList({ fleetList: null })))
        )
      )
    )
  );

  createFleetList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.createFleetList),
      switchMap(action =>
        this.dataService.createFleetList(action.fleetList).pipe(
          map(fleetList => VehicleActions.setFleetList({ fleetList })),
          catchError(() => of(VehicleActions.setFleetList({ fleetList: null, noUpdate: true })))
        )
      )
    )
  );

  deleteFleetList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.deleteFleetList),
      switchMap(action =>
        this.dataService.deleteFleetList(action.fleetIds).pipe(
          map(fleetList => VehicleActions.setFleetList({ fleetList })),
          catchError(() => of(VehicleActions.setFleetList({ fleetList: null, noUpdate: true })))
        )
      )
    )
  );

  removeFromFleet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.removeFromFleet),
      switchMap(action =>
        this.dataService.removeFromFleet(action.fleet, action.vehicles).pipe(
          map(fleetList => VehicleActions.setFleetList({ fleetList })),
          catchError(() => of(VehicleActions.setFleetList({ fleetList: null, noUpdate: true })))
        )
      )
    )
  );

  getProviderCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.getProviderCredentials),
      switchMap(() =>
        this.dataService.getProviderCredentials().pipe(
          map(providerCredentials =>
            VehicleActions.setProviderCredentials({ providerCredentials })
          ),
          catchError(() => of(VehicleActions.setProviderCredentials({ providerCredentials: null })))
        )
      )
    )
  );

  deleteProviderCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehicleActions.deleteProviderCredentials),
      switchMap(action =>
        this.dataService.deleteProviderCredentials(action.credential).pipe(
          map(providerCredentials =>
            VehicleActions.setProviderCredentials({ providerCredentials })
          ),
          catchError(() => of(VehicleActions.setProviderCredentials({ providerCredentials: null })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private dataService: VehicleDataService,
    private store: Store<AppState>
  ) {}
}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Observable, filter, map, tap } from 'rxjs';
import { selectUserProfile } from '../store/auth.selectors';
import { UserProfile } from '../utility/auth.models';

@Injectable()
export class PermissionsService {
  constructor(private readonly store: Store, private readonly snackBar: MatSnackBar) {}

  public hasPermissions(permissions: string | string[], showMessage = false): Observable<boolean> {
    return this.store.select(selectUserProfile).pipe(
      filter(user => !!user && !!user.permissions && user.permissions.length > 0),
      map((user: UserProfile | null | undefined) => {
        if (!permissions || permissions.length === 0) return true;

        if (Array.isArray(permissions))
          return permissions.every(permission => user!.permissions?.includes(permission));

        return user!.permissions.includes(permissions);
      }),
      tap((hasPermission: boolean) => {
        if (!hasPermission && showMessage)
          this.snackBar.open('You do not have permission to perform this action', 'Dismiss');
      })
    );
  }

  public hasSomePermission(
    permissions: string | string[],
    showMessage = false
  ): Observable<boolean> {
    return this.store.select(selectUserProfile).pipe(
      map(user => {
        if (!user) return false;
        if (!permissions || permissions.length === 0) return true;

        if (Array.isArray(permissions))
          return permissions.some(permission => user.permissions?.includes(permission));

        return user.permissions.includes(permissions);
      }),
      tap((hasPermission: boolean) => {
        if (!hasPermission && showMessage)
          this.snackBar.open('You do not have permission to perform this action', 'Dismiss');
      })
    );
  }
}

<div class="zeva-main-container">
  <mat-sidenav-container class="zeva-main-container">
    <mat-sidenav
      #sidenav
      mode="side"
      [opened]="sidenavService.opened"
      class="sidenav"
    >
      <app-sidenav></app-sidenav>
    </mat-sidenav>

    <mat-sidenav-content class="drawer-content">
      <div class="drawer-boundary">
        <button
          mat-icon-button
          (click)="sidenavService.toggle()"
        >
        <i class="material-icons">{{ sidenav.opened ? 'chevron_left' : 'menu' }}</i>
      </button>
    </div>

    <div class="content">
        <app-header></app-header>
        <router-outlet class="page-container"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

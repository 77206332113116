import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { trackingUrl } from '../../admin/admin.urls';
import { HttpClientWrapper } from '../../http-client-wrapper';

interface TrackerEvent {
  event_type: string;
  action_type?: string;
  page_name?: string;
  element_name?: string;
  element_type?: string;
  duration?: number;
  previous_page?: string;
  premium_feature_accessed?: boolean;
  premium_attempts?: number;
  additional_data?: JSON;
  redirect_from_signin?: boolean;
  end_time?: Date;
  device_type: string;
}

enum TrackerEventType {
  PAGE_VISIT = 'PAGE_VISIT',
  ELEMENT_INTERACTION = 'ELEMENT_INTERACTION',
  ACTION = 'ACTION',
}

@Injectable({
  providedIn: 'root',
})
export class TrackerService {
  private hoverStartTime: number | null = null;

  constructor(private http: HttpClientWrapper) {}

  // General method for tracking events
  trackEvent(eventData: TrackerEvent): Observable<any> {
    return this.http.post(trackingUrl, eventData).pipe();
  }

  // Method for tracking page visits
  trackPageVisit(
    page_name: string,
    duration?: number,
    previous_page?: string,
    redirect_from_signin?: boolean
  ): Observable<any> {
    const eventData: TrackerEvent = {
      event_type: TrackerEventType.PAGE_VISIT,
      page_name,
      duration,
      previous_page,
      redirect_from_signin,
      device_type: 'Desktop',
    };
    return this.trackEvent(eventData);
  }

  // Method for tracking element clicks
  trackElementClick(
    element_name: string,
    element_type: string,
    page_name: string,
    additional_data?: any
  ): Observable<any> {
    const eventData: TrackerEvent = {
      event_type: TrackerEventType.ELEMENT_INTERACTION,
      element_name,
      element_type,
      page_name,
      additional_data,
      device_type: 'Desktop',
    };
    return this.trackEvent(eventData);
  }

  // Method for tracking element hover
  trackElementHover(
    element_name: string,
    element_type: string,
    page_name: string,
    duration?: number,
    additional_data?: any
  ): Observable<any> {
    const eventData: TrackerEvent = {
      event_type: TrackerEventType.ELEMENT_INTERACTION,
      element_name,
      element_type,
      page_name,
      duration,
      additional_data,
      device_type: 'Desktop',
    };
    return this.trackEvent(eventData);
  }

  // Method for tracking specific actions
  trackAction(
    action_type: string,
    page_name: string,
    additional_data?: any,
    premium_feature_accessed?: boolean
  ): Observable<any> {
    const eventData: TrackerEvent = {
      event_type: TrackerEventType.ACTION,
      page_name,
      action_type,
      premium_feature_accessed,
      additional_data,
      device_type: 'Desktop',
    };
    return this.trackEvent(eventData);
  }

  // Method for tracking premium feature attempts
  trackPremiumFeatureAttempt(
    element_name: string,
    premium_feature_accessed: boolean,
    premium_attempts: number,
    additional_data?: any
  ): Observable<any> {
    const eventData: TrackerEvent = {
      event_type: TrackerEventType.ELEMENT_INTERACTION,
      element_name,
      premium_feature_accessed,
      premium_attempts,
      additional_data,
      device_type: 'Desktop',
    };
    return this.trackEvent(eventData);
  }

  updateEvent(eventId: string, updateData: Partial<TrackerEvent>): Observable<any> {
    const updateUrl = `${trackingUrl}${eventId}/`;
    return this.http.patch(updateUrl, updateData).pipe();
  }

  startHover(): void {
    this.hoverStartTime = Date.now();
  }

  endHover(elementName: string, elementType: string, pageName: string): void {
    if (this.hoverStartTime) {
      const hoverEndTime = Date.now();
      const hoverDuration = (hoverEndTime - this.hoverStartTime) / 1000;
      this.hoverStartTime = null;

      void firstValueFrom(
        this.trackElementHover(elementName, elementType, pageName, hoverDuration, {
          hover: true,
        })
      );
    }
  }
}

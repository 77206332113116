import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription, distinctUntilChanged, interval, takeWhile } from 'rxjs';
import { IdleCheckService } from '../idle-check.service';

// eslint-disable-next-line no-shadow
export const enum IdleWarningDialogResponse {
  REFRESH,
  EXIT,
}

@Component({
  selector: 'app-idle-warning-dialog',
  templateUrl: './idle-warning-dialog.component.html',
  styleUrls: ['./idle-warning-dialog.component.scss'],
})
export class IdleWarningDialogComponent implements OnInit, OnDestroy {
  /**
   * Number of seconds after which user will be signed out and sent back to sign-in page
   */
  countdown = 60;
  countdown$?: Subscription;

  isIdle = true;
  isIdle$?: Subscription;

  constructor(
    private dialogRef: MatDialogRef<IdleWarningDialogComponent, IdleWarningDialogResponse>,
    public idleCheck: IdleCheckService
  ) {}

  ngOnInit(): void {
    this.isIdle$ = this.idleCheck.isIdle$
      .asObservable()
      .pipe(distinctUntilChanged())
      .subscribe(isIdle => {
        this.isIdle = isIdle;

        if (this.isIdle) this.startCounting();
      });
  }

  ngOnDestroy(): void {
    this.countdown$?.unsubscribe();
    this.isIdle$?.unsubscribe();
  }

  startCounting(): void {
    this.countdown = 60;
    this.countdown$?.unsubscribe();
    this.countdown$ = interval(1000)
      .pipe(takeWhile(() => this.countdown >= 0))
      .subscribe(() => {
        if (this.countdown === 0 && this.isIdle) {
          this.onLeave();
          return;
        }

        --this.countdown;
      });
  }

  onStay(): void {
    this.countdown$?.unsubscribe();
    this.dialogRef.close(IdleWarningDialogResponse.REFRESH);
  }

  onLeave(): void {
    this.countdown$?.unsubscribe();
    this.dialogRef.close(IdleWarningDialogResponse.EXIT);
  }
}

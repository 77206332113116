<img *ngIf="!darkMode" src="/assets/zeva-logo-no-icon.png" class="floating-logo" routerLink="/" />
<img *ngIf="darkMode" src="/assets/zeva-logo-white.png" class="floating-logo" routerLink="/" />

<div class="error-box">
  <div class="text">
    <p class="mat-headline-1">{{ errorCode }}</p>
    <p class="mat-subtitle-2 secondary caption">{{ errorMessage }}</p>
    <button mat-flat-button color="primary" routerLink="/">Return to Homepage</button>
  </div>
  <div class="illustration">
    <!-- <img class="pin" src="/assets/icons/raster/{{ errorCode }}.png" [alt]="errorCode" /> -->
    <img *ngIf="!darkMode" class="pin" src="/assets/error-hero.png" [alt]="errorCode" />
    <img *ngIf="darkMode" class="pin" src="/assets/error-hero-dark.png" [alt]="errorCode" />
  </div>
</div>

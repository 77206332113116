import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientWrapper } from '../../../../http-client-wrapper';
import { TripSummary, VehicleState } from '../../../../shared/vehicle/models/vehicle.models';
import { vehicleTripSummaryUrl, vehicleTripsUrl } from '../../../monitoring.urls';

@Injectable({ providedIn: 'root' })
export class ReplayDataService {
  constructor(private httpClientWrapper: HttpClientWrapper, private httpClient: HttpClient) {}

  getVehicleTrips(time_0: Date, time_1: Date, vehicle: number): Observable<VehicleState[]> {
    return this.httpClientWrapper.get(vehicleTripsUrl, { time_0, time_1, vehicle });
  }

  getVehicleTripSummaries(
    time_0: Date,
    time_1: Date,
    vehicle: [number],
    pagination?: number
  ): Observable<TripSummary[]> {
    if (pagination) return this.httpClientWrapper.get(vehicleTripSummaryUrl, { time_0, time_1, vehicles: vehicle, pagination });
    return this.httpClientWrapper.get(vehicleTripSummaryUrl, { time_0, time_1, vehicles: vehicle });
  }

  getVehicleTrip(tripId: number): Observable<VehicleState[]> {
    return this.httpClientWrapper.get(`${vehicleTripSummaryUrl}${tripId}/`);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { subscriptionPlansUrl, subscriptionsUrl } from 'src/app/auth/utility/auth.urls';
import { HttpClientWrapper } from 'src/app/http-client-wrapper';
import { ListDTO } from '../../loading-state/api.models';
import { SubscriptionDTO, SubscriptionPlanDTO } from '../subscription.models';

@Injectable({ providedIn: 'root' })
export class SubscriptionDataService {
  constructor(private wrapper: HttpClientWrapper) {}

  public getSubscriptionPlans(): Observable<ListDTO<SubscriptionPlanDTO>> {
    return this.wrapper.get<ListDTO<SubscriptionPlanDTO>>(subscriptionPlansUrl);
  }

  public getSubscription(): Observable<SubscriptionDTO> {
    return this.wrapper.get<SubscriptionDTO>(subscriptionsUrl);
  }
}

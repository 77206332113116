import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CardGridModule } from '../../../core/card-grid/card-grid.module';
import { ContentPlaceholderModule } from '../../../shared/content-placeholder/content-placeholder.module';
import { UnitConversionModule } from '../../../shared/helpers/pipes/unit-conversion/unit-conversion.module';
import { ChargeCyclesAnalyticsModule } from '../../components/charge-cycles-analytics/charge-cycles-analytics.module';
import { DepletionAnalyticsModule } from '../../components/depletion-analytics/depletion-analytics.module';
import { GeofenceAnalyticsModule } from '../geofence-analytics/geofence-analytics.module';
import { UsageAnalyticsAnalysisComponent } from './usage-analytics-analysis/usage-analytics-analysis.component';
import { UsageAnalyticsHeatmapModule } from './usage-analytics-heatmap/usage-analytics-heatmap.module';
import { UsageAnalyticsComponent } from './usage-analytics.component';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslocoModule } from '@ngneat/transloco';
import {
  NgxTaEChartsModule,
  TaEchartsCustomLegendModule,
} from '@temerity-analytics/ngx-ta-echarts';
import { AdsModule } from '../../../shared/ads/ads.module';
import { IterableToMatTableDataSourcePipe } from '../../../shared/helpers/pipes/common/common-pipes.pipe';

@NgModule({
  declarations: [UsageAnalyticsComponent, UsageAnalyticsAnalysisComponent],
  exports: [UsageAnalyticsComponent],
  imports: [
    CommonModule,
    CardGridModule,
    NgxTaEChartsModule,
    UnitConversionModule,
    ContentPlaceholderModule,
    FormsModule,
    TaEchartsCustomLegendModule,
    MatButtonToggleModule,
    MatSliderModule,
    MatTableModule,
    MatSortModule,
    UsageAnalyticsHeatmapModule,
    GeofenceAnalyticsModule,
    ChargeCyclesAnalyticsModule,
    DepletionAnalyticsModule,
    IterableToMatTableDataSourcePipe,
    AdsModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslocoModule,
  ],
})
export class UsageAnalyticsModule {}

import { cloneDeep } from 'lodash';

/**
 * Class wrapping information coming over the network. Indicates whether the information
 * is still being received, and in the case that the information has been received, what
 * the information was.
 *
 * @author Jacob
 */
export class Loadable<T> {
  /**
   * Determines whether currently loading
   */
  isLoading = false;

  /**
   * Determines whether currently in an error state
   */
  error?: string;

  /**
   * Value  of Loadable<T>, or null if currently loading
   */
  _value?: T | null;

  /**
   * Gets the value currently contained by this Loadable<T>
   */
  public get value() {
    return this.isLoading || !!this.error ? undefined : this._value;
  }

  /**
   * Creates a new Loadable<T>
   * @param value the initial value for this Loadable<T>
   * @param loading whether this Loadable<T> is loading
   * @param error whether this Loadable<T> is currently in an error state
   */
  constructor(
    value: T | null | undefined = undefined,
    loading = false,
    error: string | undefined = undefined
  ) {
    this._value = value;
    this.isLoading = loading;
    this.error = error;
  }

  /**
   * @param loading whether loading
   * @returns a copy of this Loadable<T> with the same value and error status
   */
  loadingCopy(loading: boolean) {
    return new Loadable(cloneDeep(this.value), loading, this.error);
  }

  /**
   * @param value the potential new value
   * @returns a copy of this Loadable<T> with the same loading status
   * and error status, but a potentially different value
   */
  valueCopy(value?: T) {
    return new Loadable(cloneDeep(value), this.isLoading);
  }

  /**
   *
   * @param error the error status
   * @returns a copy of this Loadable<T> with a different
   * error status
   */
  errorCopy(error: string | undefined) {
    return new Loadable(cloneDeep(this.value), false, error);
  }

  /**
   * @returns an identical copy of this Loadable<T>
   */
  copy() {
    return new Loadable(cloneDeep(this.value), this.isLoading, this.error);
  }
}

import { NgModule } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterModule, Routes } from '@angular/router';
import { DocumentPageComponent } from './adhoc/document-page/document-page.component';
import { FaqComponent } from './adhoc/faq/faq.component';
import { GenericErrorComponent } from './adhoc/generic-error/generic-error.component';
import { setupWizard } from './admin/pages/setup-wizard/setup-wizard.guard';
import { hasPermissions } from './auth/permissions/permissions.guard';
import { hasAccountAccess } from './auth/utility/account.guard';
import { isAuthenticated, isZevaRep } from './auth/utility/auth.guard';
import { ipGuard } from './auth/utility/ip.guard';
import { ZevaPageSelector } from './core/core.models';
import { ZEVA_PAGE_VIEW_PERMISSION, ZEVA_SUBSYSTEMS } from './core/core.static';
import { HomeComponent } from './core/home/home.component';

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: ZEVA_SUBSYSTEMS.monitoring.slug },
      {
        path: ZEVA_SUBSYSTEMS.insights.slug,
        canActivate: [
          isAuthenticated,
          hasPermissions(ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.INSIGHTS]),
          hasAccountAccess,
          setupWizard,
          ipGuard,
        ],
        loadChildren: () => import('./insights/insights.module').then((m) => m.InsightsModule),
        data: { title: ZEVA_SUBSYSTEMS.insights.name },
      },
      {
        path: ZEVA_SUBSYSTEMS.analytics.slug,
        canActivate: [
          isAuthenticated,
          hasPermissions(ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.ANALYTICS_VEHICLE]),
          hasAccountAccess,
          setupWizard,
          ipGuard,
        ],
        loadChildren: () => import('./analytics/analytics.module').then((m) => m.AnalyticsModule),
        data: { title: ZEVA_SUBSYSTEMS.analytics.name },
      },
      {
        path: ZEVA_SUBSYSTEMS.monitoring.slug,
        canActivate: [isAuthenticated, hasAccountAccess, setupWizard, ipGuard],
        loadChildren: () =>
          import('./monitoring/monitoring.module').then((m) => m.MonitoringModule),
        data: { title: ZEVA_SUBSYSTEMS.monitoring.name },
      },
      {
        path: ZEVA_SUBSYSTEMS.admin.slug,
        canActivate: [isAuthenticated, hasAccountAccess, ipGuard],
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
        data: { title: ZEVA_SUBSYSTEMS.admin.name, skipSubscriptionGuard: true },
      },
      {
        path: 'faq',
        canActivate: [isAuthenticated, hasAccountAccess, ipGuard],
        loadChildren: () => import('./adhoc/faq/faq.module').then((m) => m.FaqModule),
        component: FaqComponent,
        data: { title: 'FAQ' },
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  { path: 'invite', redirectTo: 'auth/invite' },
  {
    path: 'privacy-policy',
    pathMatch: 'full',
    // canActivate: [ipGuard],
    loadChildren: () =>
      import('./adhoc/document-page/document-page.module').then((m) => m.DocumentPageModule),
    data: { title: 'Privacy Policy' },
    component: DocumentPageComponent,
  },
  {
    path: 'terms-of-use',
    pathMatch: 'full',
    // canActivate: [ipGuard],
    loadChildren: () =>
      import('./adhoc/document-page/document-page.module').then((m) => m.DocumentPageModule),
    data: { title: 'Terms of Use' },
    component: DocumentPageComponent,
  },
  {
    path: 'terms-of-service',
    pathMatch: 'full',
    // canActivate: [ipGuard],
    loadChildren: () =>
      import('./adhoc/document-page/document-page.module').then((m) => m.DocumentPageModule),
    data: { title: 'Terms of Services' },
    component: DocumentPageComponent,
  },
  {
    path: 'internal',
    canActivate: [isZevaRep, ipGuard],
    loadChildren: () => import('./internal/internal.module').then((m) => m.InternalModule),
  },
  {
    path: 'error/:code',
    canActivate: [ipGuard],
    component: GenericErrorComponent,
  },
  {
    path: '**',
    component: GenericErrorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: false, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [MatSnackBar],
})
export class AppRoutingModule {}

import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ZEVA_DATA_PLACEHOLDER, ZEVA_NUMBER_FORMAT } from '../../../../core/core.static';

/**
 * Returns time delta value as formatted string
 * @example '19h 59m'
 */
@Pipe({
  name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
  constructor(private decimal: DecimalPipe, private translocoService: TranslocoService) {}

  transform(value: number | undefined, format: 'plaintext' | 'html' = 'plaintext'): string {
    if (value === undefined) return ZEVA_DATA_PLACEHOLDER;

    const hours = this.decimal.transform(Math.floor(value / 60), ZEVA_NUMBER_FORMAT)!;
    const minutes = this.decimal.transform(Math.floor(value % 60), ZEVA_NUMBER_FORMAT)!;

    const hourLabel = this.translocoService.translate('time.hours');
    const minuteLabel = this.translocoService.translate('time.minutes');

    switch (format) {
      case 'html':
        return hours !== '0'
          ? `${hours}<span class="subscript">${hourLabel}</span> ${minutes}<span class="subscript">${minuteLabel}</span>`
          : `${minutes}<span class="subscript">${minuteLabel}</span>`;
      case 'plaintext':
      default:
        return hours !== '0'
          ? `${hours}${hourLabel} ${minutes}${minuteLabel}`
          : `${minutes}${minuteLabel}`;
    }
  }
}

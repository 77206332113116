<zeva-card class="action-prompt">
  <zeva-card-header class="action-header" [class.compress]="!prompts.title">
    <p class="mat-headline-2" style="text-align: center; margin-top: 1rem; width: 100%;">{{ prompts.title }}</p>

    <button
      mat-icon-button
      mat-dialog-close
      tabindex="-1"
      *ngIf="!prompts.disableClose"
      style="margin: -0.5rem -0.5rem 0 -0.5rem"
    >
      <mat-icon>close</mat-icon>
    </button>
  </zeva-card-header>

  <zeva-card-header class="heading" *ngIf="prompts.heading || prompts.icon">
    <mat-icon class="icon" [svgIcon]="prompts.icon" *ngIf="prompts.icon" color="primary"></mat-icon>
    <p class="mat-headline-3" *ngIf="prompts.heading">{{ prompts.heading }}</p>
  </zeva-card-header>

  <div class="action left" *ngIf="prompts.message || prompts.messageTml || prompts.messages || prompts.centeredMessage">
    <p *ngIf="prompts.message">{{ prompts.message }}</p>
    <p *ngIf="prompts.centeredMessage" style="text-align: center;">{{ prompts.centeredMessage }}</p>
    <ng-container *ngIf="prompts.messages">
      <p *ngFor="let message of prompts.messages" class="messages">{{ message }}</p>
    </ng-container>
    <ng-container *ngIf="prompts.messageTml">
      <ng-container
        *ngTemplateOutlet="prompts.messageTml; context: prompts.messageContext"
      ></ng-container>
    </ng-container>
  </div>

  <zeva-card-footer class="action-footer">
    <button
      *ngIf="!prompts.removeCancelAction"
      mat-stroked-button
      color="primary"
      (click)="onCancel()"
      tabindex="-1"
    >
      {{ prompts.cancelActionCaption ?? 'Cancel' }}
    </button>

    <button
      *ngIf="prompts.otherActionCaption"
      mat-flat-button
      color="primary"
      (click)="onOther(prompts.otherActionCaption)"
      tabindex="-1"
    >
      {{ prompts.otherActionCaption }}
    </button>

    <button
      *ngIf="!prompts.removeConfirmAction"
      mat-flat-button
      color="primary"
      [disabled]="prompts.disableConfirmWhile ? prompts.disableConfirmWhile() : false"
      (click)="onConfirm()"
      tabindex="-1"
    >
      {{ prompts.confirmActionCaption ?? 'Confirm' }}
    </button>
  </zeva-card-footer>
</zeva-card>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientWrapper } from '../../../../http-client-wrapper';
import { liveRouteUrl, liveTrafficUrl } from '../../../monitoring.urls';
import { LiveTrafficData } from '../live.models';

@Injectable()
export class LiveStoreService {
  constructor(private httpClientWrapper: HttpClientWrapper, private httpClient: HttpClient) {}

  getLiveTrafficData(
    lat1: number,
    lat2: number,
    lng1: number,
    lng2: number,
    functionalClasses?: number[]
  ): Observable<LiveTrafficData> {
    return this.httpClientWrapper.get(liveTrafficUrl, {
      lat1,
      lat2,
      lng1,
      lng2,
      functional_classes: functionalClasses ?? [],
    });
  }

  /**
   * Gets an optimized route between two points..
   * @param {number} lat1 -  lat coordinate of the starting location
   * @param {number} lng1 - lng coordinate of the starting location
   * @param {number} lat2 -  lat coordinate of the destination
   * @param {number} lng2 - lng coordinate of the destination
   * @author Anna Chester
   */

  getLiveRouteData(lat1: number, lng1: number, lat2: number, lng2: number): Observable<any> {
    return this.httpClientWrapper.get<any>(liveRouteUrl, {
      lat1,
      lat2,
      lng1,
      lng2,
    });
  }
}

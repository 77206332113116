import { Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type ActionPromptConfig = {
  title?: string;
  icon?: string;
  heading?: string;
  message?: string;
  centeredMessage?: string;
  messages?: string[];
  messageTml?: TemplateRef<unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messageContext?: any;
  // actions
  // confirm action
  confirmActionCaption?: string;
  removeConfirmAction?: boolean;
  disableConfirmWhile?: () => boolean;
  // cancel action
  cancelActionCaption?: string;
  removeCancelAction?: boolean;
  otherActionCaption?: string;

  disableClose?: boolean;
};

@Component({
  selector: 'app-action-prompt',
  templateUrl: './action-prompt.component.html',
  styleUrls: ['./action-prompt.component.scss'],
})
export class ActionPromptDialog {
  constructor(
    public readonly dialogRef: MatDialogRef<ActionPromptDialog>,
    @Inject(MAT_DIALOG_DATA) public prompts: ActionPromptConfig
  ) {
    if (this.prompts.disableClose) this.dialogRef.disableClose = true;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onOther(text: string): void {
    this.dialogRef.close(text);
  }
}

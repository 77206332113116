import { createAction, props } from '@ngrx/store';
import { LiveTrafficData } from '../live.models';

export const getLiveTrafficData = createAction(
  '[Live] Get Live Traffic Data',
  props<{ lat1: number; lat2: number; lng1: number; lng2: number; functionalClasses?: number[] }>()
);
export const setLiveTrafficData = createAction(
  '[Live] Set Live Traffic Data',
  props<{ data: LiveTrafficData }>()
);
export const setLiveTrafficDataError = createAction('[Live] Set Live Traffic Data Error State');

export const getLiveRouteData = createAction(
  '[Live] Get Live Route Data',
  props<{ lat1: number; lat2: number; lng1: number; lng2: number }>()
);
export const setLiveRouteData = createAction('[Live] Set Live Route Data', props<{ data: any }>());
export const setLiveRouteDataError = createAction('[Live] Set Live Traffic Data Error State');

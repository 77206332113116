import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Subscription, tap } from 'rxjs';
import { selectUserProfile } from '../../auth/store/auth.selectors';
import { UserProfile } from '../../auth/utility/auth.models';
import { Theme, ThemeService } from '../services/theme.service';
import { FeedbackService } from './feedback.service';

@Component({
  selector: 'feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss'],
})
export class FeedbackModalComponent implements OnInit, OnDestroy {
  error?: string;
  loading = false;
  formSubmitted = false;
  private _profile?: UserProfile | null;
  private _subscription?: Subscription;

  form: FormGroup = new FormGroup({
    email: new FormControl({ value: '', disabled: false }, [Validators.required.bind(this)]),
    rating: new FormControl({ value: '', disabled: false }, [Validators.required.bind(this)]),
    info: new FormControl({ value: '', disabled: false }, [Validators.required.bind(this)]),
  });

  submitConfirmationMsg1 = `Thank you for your feedback!
  We appreciate your support in our journey to excellence.`;

  readonly SUBMIT_CONFIRMATION_MODAL_HEIGHT = '325px';

  darkMode = false;

  constructor(
    private dialogRef: MatDialogRef<FeedbackModalComponent>,
    private readonly store: Store,
    private service: FeedbackService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private themeService: ThemeService,
    private translocoService: TranslocoService
  ) {}

  ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

  ngOnInit() {
    this.submitConfirmationMsg1 = this.translocoService.translate('submitConfirmationMsg1');

    const theme = this.themeService.currentTheme;
    if (theme === Theme.DARK) this.darkMode = true;
    else this.darkMode = false;

    const $profilePipe = this.store.select(selectUserProfile).pipe(
      tap((profile) => {
        this._profile = profile;
        if (profile?.email) this.form.controls['email'].setValue(profile?.email);
      })
    );
    this._subscription = $profilePipe.subscribe();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmitForm() {
    this.formSubmitted = true;
    this.dialogRef.updateSize('500px', this.SUBMIT_CONFIRMATION_MODAL_HEIGHT);
    this.cdr.markForCheck();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmitFormError(err: any) {
    this.error = this.translocoService.translate('feedback.errorMessage', { message: err.message });
    this.loading = false;
    this.cdr.markForCheck();
  }

  submitForm() {
    // assert that a profile ID always defined with real accounts
    if (!this._profile?.id) return;

    this.loading = true;
    this._subscription?.unsubscribe();
    this._subscription = this.service
      .feedbackForm({
        id: this._profile.id,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        reply_email: this.form.value.email,
        user_email: this._profile.email,
        current_url: this.router.url,
        rating: this.form.value.rating,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        content: this.form.value.info,
      })
      .subscribe({
        next: this.onSubmitForm.bind(this),
        error: this.onSubmitFormError.bind(this),
      });
  }

  close(): void {
    this.dialogRef.close();
  }
}

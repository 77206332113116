import { createSelector } from '@ngrx/store';
import { selectFeatureSubscription } from 'src/app/store/app.selectors';
import { SubscriptionState } from './subscription.reducer';

export const selectSubscriptionPlans = createSelector(
  selectFeatureSubscription,
  (state: SubscriptionState) => state.subscriptionPlans
);

export const selectSubscription = createSelector(
  selectFeatureSubscription,
  (state: SubscriptionState) => state.subscription
);

<div class="feedback-modal-wrapper">
  <div
    mat-dialog-title
    style="float: right; font-weight: 700; cursor: pointer"
    (click)="close()"
    class="close-btn"
  >
    <mat-icon class="close">close</mat-icon>
  </div>

  <form [formGroup]="form" *ngIf="!formSubmitted">
    <div class="feedback-logo-container">
      <img *ngIf="!darkMode" src="/assets/zeva-logo-no-icon.png" class="feedback-logo" />
      <img *ngIf="darkMode" src="/assets/zeva-logo-white.png" class="feedback-logo" />
    </div>

    <div class="fields-wrapper">
      <p class="mat-headline-3 title">
        {{ 'feedback.shareYourFeedback' | transloco }}
      </p>

      <p class="info">
        {{ 'feedback.infoMessage' | transloco }}
      </p>

      <mat-form-field>
        <input matInput formControlName="email" type="text" readonly />
        <mat-label>{{ 'email' | transloco }}</mat-label>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'feedback.rateYourExperience' | transloco }}</mat-label>
        <mat-select formControlName="rating">
          <mat-option value="5">{{ 'feedback.excellent' | transloco }}</mat-option>
          <mat-option value="4">{{ 'feedback.good' | transloco }}</mat-option>
          <mat-option value="3">{{ 'feedback.average' | transloco }}</mat-option>
          <mat-option value="2">{{ 'feedback.fair' | transloco }}</mat-option>
          <mat-option value="1">{{ 'feedback.poor' | transloco }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'feedback.yourFeedback' | transloco }}</mat-label>
        <textarea matInput formControlName="info"></textarea>
      </mat-form-field>
    </div>

    <div class="bottom-wrapper">
      <div *ngIf="error" class="error-text">{{ error }}</div>
      <div class="buttons-wrapper {{ error ? 'error-shown' : '' }}">
        <button
          [ngClass]="{ 'loading-shimmer': loading }"
          mat-flat-button
          [disabled]="form.invalid"
          type="submit"
          color="primary"
          (click)="submitForm()"
        >
          {{ 'feedback.submit' | transloco }}
        </button>
      </div>
    </div>
  </form>

  <div class="submit-confirmation-dialog-container" *ngIf="formSubmitted">
    <div class="feedback-logo-container">
      <img src="/assets/zeva-logo-no-icon.png" class="feedback-logo" />
    </div>
    <p class="mat-headline-3 title">
      {{ 'feedback.feedbackSubmitted' | transloco }}
    </p>
    <p class="submit-confirmation-msg">
      {{ submitConfirmationMsg1 }}
    </p>
    <div class="close-confirm-button-wrapper">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        {{ 'close' | transloco }}
      </button>
    </div>
  </div>
</div>

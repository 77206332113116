import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientWrapper } from '../../../http-client-wrapper';
import {
    ActivityInsightsData,
    AlertEventsData,
    ChargingLevelsData,
    EnergyConsumptionData,
    GenericInsightsRequestPayload,
    MileageRankingData,
    UsageInsightsData,
} from '../../insights.models';
import {
    activityInsightsUrl,
    alertInsightsUrl,
    chargingLevelsInsightsUrl,
    energyConsumptionRankingUrl,
    mileageRankingUrl,
    usageInsightsUrl,
} from '../../insights.urls';

@Injectable({ providedIn: 'root' })
export class InsightsDataService {
  constructor(private httpClientWrapper: HttpClientWrapper) {}

  public getUsageInsights(payload: GenericInsightsRequestPayload): Observable<UsageInsightsData> {
    return this.httpClientWrapper.post(usageInsightsUrl, payload);
  }

  public getMileageRanking(payload: GenericInsightsRequestPayload): Observable<MileageRankingData> {
    return this.httpClientWrapper.post(mileageRankingUrl, payload);
  }

  public getActivityInsights(
    payload: GenericInsightsRequestPayload
  ): Observable<ActivityInsightsData> {
    return this.httpClientWrapper.post(activityInsightsUrl, payload);
  }

  public getChargingLevelsInsights(
    payload: GenericInsightsRequestPayload
  ): Observable<ChargingLevelsData> {
    return this.httpClientWrapper.post(chargingLevelsInsightsUrl, payload);
  }

  public getEnergyConsumptionRanking(
    payload: GenericInsightsRequestPayload
  ): Observable<EnergyConsumptionData> {
    return this.httpClientWrapper.post(energyConsumptionRankingUrl, payload);
  }

  public getAlertEventsInsights(
    payload: GenericInsightsRequestPayload
  ): Observable<AlertEventsData> {
    return this.httpClientWrapper.post(alertInsightsUrl, payload);
  }
}

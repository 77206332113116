import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CardModule } from 'src/app/core/card-grid/components/card/card.module';
import { LoadingModule } from '../loading/loading.module';
import { SuperchargerInfoWindowComponent } from './supercharger-info-window/supercharger-info-window.component';

@NgModule({
  declarations: [SuperchargerInfoWindowComponent],
  imports: [CommonModule, LoadingModule, CardModule, MatIconModule, MatTooltipModule],
  exports: [SuperchargerInfoWindowComponent],
})
export class SuperchargersModule {}

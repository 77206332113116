import { Action, createReducer, on } from '@ngrx/store';
import { ListDTO } from 'src/app/shared/loading-state/api.models';
import { UserGroup, UserProfile } from '../../../auth/utility/auth.models';
import { Loadable } from '../../../shared/loading-state/loadable';
import { resetState } from '../../../store/app.actions';
import { Invite, Permission, Provider, VehicleAccess } from '../../admin.models';
import {
  BillingCardDTO,
  BillingDetailsDTO,
} from '../../components/payment-form/payment-form.models';
import { SubscriptionInvoiceDTO } from '../../pages/payment-history/payment-history.models';
import * as AdminActions from './admin.actions';

export type AdminGeneralState = {
  loadingStatus: {
    providers: boolean;
  };
  providers?: Provider[] | null;
  users: Loadable<UserProfile[]>;
  permissionsList: Loadable<Permission[]>;
  permissionGroups: Loadable<UserGroup[]>;
  memberCounts: Loadable<{ name: string; member_count: number }[]>;
  invites: Loadable<Invite[]>;
  billingDetails: Loadable<BillingDetailsDTO>;
  billingCard: Loadable<BillingCardDTO>;
  subscriptionInvoices: Loadable<ListDTO<SubscriptionInvoiceDTO>>;
  vehicleAccess: Loadable<VehicleAccess[]>;
};

const initialState: AdminGeneralState = {
  loadingStatus: {
    providers: false,
  },
  users: new Loadable(),
  permissionsList: new Loadable(),
  permissionGroups: new Loadable(),
  memberCounts: new Loadable(),
  invites: new Loadable(),
  billingDetails: new Loadable(),
  billingCard: new Loadable(),
  subscriptionInvoices: new Loadable(),
  vehicleAccess: new Loadable()
};

const reducer = createReducer<AdminGeneralState>(
  initialState,
  on(resetState, () => ({ ...initialState })),
  // Reset State to initial
  on(AdminActions.clearState, () => ({
    ...initialState,
  })),
  // List of Supported Providers (Vehicle Brands)
  on(AdminActions.getSupportedProviders, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      providers: true,
    },
  })),
  on(AdminActions.setSupportedProviders, (state, { providers }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      providers: false,
    },
    providers,
  })),
  // Users
  on(AdminActions.getUsers, AdminActions.updateUserGroups, state => ({
    ...state,
    users: state.users.loadingCopy(true),
  })),
  on(AdminActions.setUsers, (state, { users, error }) => ({
    ...state,
    users: error === undefined ? new Loadable(users) : state.users.errorCopy(error),
  })),
  on(AdminActions.createUser, state => ({
    ...state,
    users: state.users.loadingCopy(true),
  })),
  on(AdminActions.deleteUser, state => ({
    ...state,
    users: state.users.loadingCopy(true),
  })),
  // Permissions
  on(AdminActions.getPermissionsList, state => ({
    ...state,
    permissionsList: state.permissionsList.loadingCopy(true),
  })),
  on(AdminActions.setPermissionsList, (state, { permissions, error }) => ({
    ...state,
    permissionsList:
      error === undefined ? new Loadable(permissions) : state.permissionsList.errorCopy(error),
  })),
  on(
    AdminActions.getPermissionGroups,
    AdminActions.createPermissionGroup,
    AdminActions.deletePermissionGroup,
    state => ({
      ...state,
      permissionGroups: state.permissionGroups.loadingCopy(true),
    })
  ),
  on(AdminActions.setPermissionGroups, (state, { permissionGroups, error }) => ({
    ...state,
    permissionGroups:
      error === undefined
        ? new Loadable(permissionGroups)
        : state.permissionGroups.errorCopy(error),
  })),
  on(AdminActions.getMemberCounts, state => ({
    ...state,
    memberCounts: state.memberCounts.loadingCopy(true),
  })),
  on(AdminActions.setMemberCounts, (state, { memberCounts, error }) => ({
    ...state,
    memberCounts:
      error === undefined ? new Loadable(memberCounts) : state.memberCounts.errorCopy(error),
  })),
  // Invites
  on(AdminActions.getInvites, AdminActions.createInvite, AdminActions.deleteInvite, state => ({
    ...state,
    invites: state.invites.loadingCopy(true),
  })),
  on(AdminActions.setInvites, (state, { invites, error }) => ({
    ...state,
    invites: error ? state.invites.errorCopy(error) : new Loadable(invites),
  })),
  // Billing Details
  on(AdminActions.getBillingDetails, state => ({
    ...state,
    billingDetails: state.billingDetails.loadingCopy(true),
  })),
  on(AdminActions.setBillingDetails, (state, { billingDetails, error }) => ({
    ...state,
    billingDetails:
      error === undefined ? new Loadable(billingDetails) : state.billingDetails.errorCopy(error),
  })),
  // Billing Card
  on(AdminActions.getBillingCard, state => ({
    ...state,
    billingCard: state.billingCard.loadingCopy(true),
  })),
  on(AdminActions.setBillingCard, (state, { card, error }) => ({
    ...state,
    billingCard: error === undefined ? new Loadable(card) : state.billingCard.errorCopy(error),
  })),
  // Subscription Invoices
  on(AdminActions.getSubscriptionInvoices, state => ({
    ...state,
    subscriptionInvoices: state.subscriptionInvoices.loadingCopy(true),
  })),
  on(AdminActions.setSubscriptionInvoices, (state, { invoices, error }) => ({
    ...state,
    subscriptionInvoices:
      error === undefined ? new Loadable(invoices) : state.subscriptionInvoices.errorCopy(error),
  })),
  on(AdminActions.getVehicleAccess, state => ({
    ...state,
    vehicle_access: state.vehicleAccess.loadingCopy(true),
  })),
  on(AdminActions.setVehicleAccess, (state, { vehicle_ids, email, user, error }) => ({
    ...state,
    vehicle_access: error ? state.vehicleAccess.errorCopy(error) : new Loadable(state.vehicleAccess.value, false, undefined),
  })),
  on(AdminActions.deleteVehicleAccess, (state, { vehicle_id, user }) => ({
    ...state,
    vehicle_access: state.vehicleAccess.loadingCopy(true),
  })),
);

export function adminGeneralReducer(
  state: AdminGeneralState | undefined,
  action: Action
): AdminGeneralState {
  return reducer(state, action);
}
export const adminGeneralFeatureKey = 'adminGeneral';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { GlobalService } from 'src/app/core/services/global.service';
import { Theme, ThemeService } from '../../core/services/theme.service';
import { AppState } from '../../store/app.reducers';
import { AuthService } from '../utility/auth.service';
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
  getSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getSession),
      switchMap(() =>
        this.authService.getSession().pipe(
          map((session) => AuthActions.setSession({ session })),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 403) this.store.dispatch(AuthActions.getCSRFToken());
            return of(AuthActions.setSession({ session: { isAuthenticated: false } }));
          })
        )
      )
    )
  );

  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signOut),
      switchMap(() =>
        this.authService.signOut().pipe(
          map(() => AuthActions.reset()),
          catchError((err) => {
            console.log('Unable to Sign Out');
            console.error(err);

            return of();
          })
        )
      )
    )
  );

  getCSRFToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getCSRFToken),
      switchMap(() =>
        this.authService.getCSRFToken().pipe(
          map((response) =>
            AuthActions.setCSRFToken({ csrfToken: response?.headers.get('X-CSRFToken') as string })
          ),
          catchError(() => of(AuthActions.setCSRFToken({ csrfToken: null })))
        )
      )
    )
  );

  getCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getCompanies),
      switchMap(() =>
        this.authService.getCompanies().pipe(
          map((companies) => AuthActions.setCompanies({ companies })),
          catchError((error) => of(AuthActions.setCompanies({ companies: null, error })))
        )
      )
    )
  );

  getUserPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getUserPermissions),
      switchMap(() =>
        this.authService.getUserPermissions().pipe(
          map((permissions) => AuthActions.updateUserPermissions({ permissions })),
          catchError(() => of())
        )
      )
    )
  );

  getUserPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getUserPreferences, AuthActions.setSession),
      switchMap(() =>
        this.authService.getUserPreferences().pipe(
          tap((preferences) => {
            if (preferences?.distance_unit)
              this.global.setUnitMultiplier(preferences.distance_unit === 'km');
            if (preferences?.timezone) this.global.setTimeZone(preferences.timezone);
            if (preferences?.appearance) {
              if (preferences.appearance === 'dark') this.themeService.setTheme(Theme.DARK);
              else if (preferences.appearance === 'light') this.themeService.setTheme(Theme.LIGHT);
            }
          }),
          map((preferences) => AuthActions.setUserPreferences({ preferences })),
          catchError((error) => of(AuthActions.setUserPreferences({ preferences: null, error })))
        )
      )
    )
  );

  getIpData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getIpData),
      switchMap(() =>
        this.authService.getIpData().pipe(
          map((ipData) => AuthActions.setIpData({ ipData })),
          catchError((error) => of(AuthActions.setIpData({ ipData: null, error })))
        )
      )
    )
  );

  // getWhoAmI$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.getWhoAmI),
  //     switchMap(() =>
  //       this.authService.whoAmI().pipe(
  //         map(userProfile => {
  //           this.store.dispatch(AuthActions.setSession({ isAuthenticated: true }));
  //           return AuthActions.setUserProfile({ userProfile })
  //         }),
  //         catchError(error => {
  //           console.log('Could not get identity', error);
  //           return of(AuthActions.setUserProfile({ userProfile: null }));
  //         })
  //       )
  //     )
  //   )
  // );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<AppState>,
    private global: GlobalService,
    private themeService: ThemeService
  ) {}
}

export const signInUrl = 'client/login/';
export const ssoSignInUrl = 'client/sso_login/';
export const ssoSignUpUrl = 'client/sso_signup/';
export const signOutUrl = 'client/logout/';
export const csrfUrl = 'client/csrf/';
export const sessionUrl = 'client/session/';
export const permissionsUrl = 'client/permissions/';
export const permissionCheckUrl = 'client/permissions/check/';
export const permissionsUserUrl = 'client/permissions/list/';
export const namedPermissionsUserUrl = 'client/permissions/get_perm/';
export const refreshSessionUrl = 'client/session_refresh/';
export const updateUserUrl = 'client/update_user/';
export const emailVerificationUrl = 'client/verify_email/';
export const forgotPasswordUrl = 'client/forgot_password/';
export const resetPasswordUrl = 'client/reset_password/';
export const changePasswordUrl = 'client/change_password/';
export const userCompaniesUrl = 'client/companies/';
export const userPreferencesUrl = 'client/user_config/';
export const updateUserPreferencesUrl = 'client/update_user_config/';
export const userDeleteUrl = 'client/user_delete/';
export const vehicleInterestUrl = 'client/vehicle_interest/';
export const companyUrl = 'client/company/';

// subscriptions
export const subscriptionPlansUrl = 'billing/subscription-plans/';
export const subscriptionsUrl = 'billing/subscriptions/';
export const taxesUrl = 'billing/taxes/';
export const demoCodeUrl = 'billing/demo-codes/';
export const setupIntentUrl = 'billing/setup-intent/';

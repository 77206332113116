import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscriptionSnackbarComponent } from './subscription-snackbar.component';

@Injectable({ providedIn: 'root' })
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) { }

  showSnackbar(message: string) {
    this.snackBar.openFromComponent(SubscriptionSnackbarComponent, {
      duration: 5000,
      panelClass: ['custom-sub-updated-snackbar'],
      data: { message }
    });
  }
}

import { createFeatureSelector } from '@ngrx/store';
import { authFeatureKey, AuthState } from '../auth/store/auth.reducer';
import {
  subscriptionFeatureKey,
  SubscriptionState,
} from '../shared/subscription/store/subscription.reducer';
import { vehicleFeatureKey, VehicleState } from '../shared/vehicle/store/vehicle.reducer';
import { downloadFeatureKey, DownloadState } from '../shared/download/store/download.reducer';

export const selectFeatureAuth = createFeatureSelector<AuthState>(authFeatureKey);
export const selectFeatureVehicle = createFeatureSelector<VehicleState>(vehicleFeatureKey);
export const selectFeatureSubscription =
  createFeatureSelector<SubscriptionState>(subscriptionFeatureKey);
export const selectFeatureDownload = createFeatureSelector<DownloadState>(downloadFeatureKey);

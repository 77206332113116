import { Action, createReducer, on } from '@ngrx/store';
import { ProviderCredential } from 'src/app/admin/admin.models';
import { resetState } from '../../../store/app.actions';
import { Loadable } from '../../loading-state/loadable';
import { Vehicle } from '../models/vehicle.class';
import { Fleet } from '../models/vehicle.models';
import * as VehicleActions from './vehicle.actions';

export type VehicleState = {
  loadingStatus: {
    vehicleList: boolean;
    fleetList: boolean;
  };
  vehicleList?: Vehicle[] | null;
  fleetList?: Fleet[] | null;
  providerCredentials: Loadable<ProviderCredential[]>;
};

const initialState: VehicleState = {
  loadingStatus: {
    vehicleList: false,
    fleetList: false,
  },
  providerCredentials: new Loadable(),
};

const reducer = createReducer<VehicleState>(
  initialState,
  on(resetState, () => ({ ...initialState })),
  // -------- Individual Vehicle
  on(VehicleActions.setVehicleColor, (state, { vehicleId, newColor }) => {
    const vehicleIndex = state.vehicleList?.findIndex((v: Vehicle) => v.id === vehicleId);
    if (vehicleIndex === undefined || vehicleIndex === -1) return state;
    const vehicle = structuredClone(state.vehicleList![vehicleIndex]);
    vehicle.user_color = newColor;
    const newVehicleList = [
      ...state.vehicleList!.slice(0, vehicleIndex),
      vehicle,
      ...state.vehicleList!.slice(vehicleIndex + 1),
    ];
    return { ...state, vehicleList: newVehicleList };
  }),
  // -------- Vehicle List
  on(
    VehicleActions.getVehicleList,
    VehicleActions.createVehicleList,
    VehicleActions.deleteVehicleList,
    VehicleActions.unarchiveVehicleList,
    state => ({
      ...state,
      loadingStatus: {
        ...state.loadingStatus,
        vehicleList: true,
      },
    })
  ),
  on(VehicleActions.setVehicleList, (state, { vehicleList, noUpdate }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleList: false,
    },
    vehicleList: noUpdate === undefined ? vehicleList : state.vehicleList,
  })),
  // -------- Fleet List
  on(
    VehicleActions.getFleetList,
    VehicleActions.createFleetList,
    VehicleActions.deleteFleetList,
    state => ({
      ...state,
      loadingStatus: {
        ...state.loadingStatus,
        fleetList: true,
      },
    })
  ),
  on(VehicleActions.setFleetList, (state, { fleetList }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      fleetList: false,
    },
    fleetList,
  })),
  // -------- Vehicle Provider Credentials
  on(VehicleActions.getProviderCredentials, state => ({
    ...state,
    providerCredentials: state.providerCredentials?.loadingCopy(true),
  })),
  on(VehicleActions.setProviderCredentials, (state, { providerCredentials, error }) => ({
    ...state,
    providerCredentials:
      error === undefined
        ? new Loadable(providerCredentials)
        : state.providerCredentials.errorCopy(error),
  }))
);

export function vehicleReducer(state: VehicleState | undefined, action: Action): VehicleState {
  return reducer(state, action);
}
export const vehicleFeatureKey = 'vehicle';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PermissionsModule } from '../../auth/permissions/permissions.module';
import { IsPageActivePipe, SidenavComponent } from './sidenav.component';

import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [SidenavComponent, IsPageActivePipe],
  imports: [
    CommonModule,
    RouterModule,
    MatExpansionModule,
    MatIconModule,
    MatDividerModule,
    PermissionsModule,
    TranslocoModule,
  ],
  exports: [SidenavComponent],
})
export class SidenavModule {}

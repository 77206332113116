import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { TranslocoModule } from '@ngneat/transloco';
import {
  NgxTaEChartsModule,
  TaEchartsCustomLegendModule,
} from '@temerity-analytics/ngx-ta-echarts';
import { CardGridModule } from '../../../core/card-grid/card-grid.module';
import { ContentPlaceholderModule } from '../../../shared/content-placeholder/content-placeholder.module';
import { GeofenceAlertsAnalyticsComponent } from './geofence-alerts-analytics/geofence-alerts-analytics.component';
import { GeofenceAnalyticsComponent } from './geofence-analytics.component';
import { GeofenceTimeAnalyticsComponent } from './geofence-time-analytics/geofence-time-analytics.component';

@NgModule({
  declarations: [
    GeofenceAnalyticsComponent,
    GeofenceAlertsAnalyticsComponent,
    GeofenceTimeAnalyticsComponent,
  ],
  imports: [
    CommonModule,
    NgxTaEChartsModule,
    TaEchartsCustomLegendModule,
    CardGridModule,
    ContentPlaceholderModule,
    MatTableModule,
    TranslocoModule,
  ],
  exports: [GeofenceAnalyticsComponent],
})
export class GeofenceAnalyticsModule {}

import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, first, from, lastValueFrom, map, startWith, take, takeWhile, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppState } from '../../store/app.reducers';
import { getCSRFToken } from '../store/auth.actions';
import { selectCSRFToken, selectSelectedCompany } from '../store/auth.selectors';
import { AuthService } from './auth.service';
import { csrfUrl, sessionUrl, signOutUrl } from './auth.urls';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public static readonly CSRF_EXCLUDED_ENDPOINTS = new Set(
    [
      csrfUrl,
      sessionUrl,
      signOutUrl,
      // Add other links you might want to exclude from CSRF token check here...
    ].map((url) => environment.apiUrl + url)
  );

  constructor(private store: Store<AppState>, private auth: AuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.handleRequest(req, next, 'cookie'));
  }

  private async handleRequest(
    req: HttpRequest<unknown>,
    next: HttpHandler,
    method: 'jwt' | 'cookie'
  ): Promise<HttpEvent<unknown>> {
    switch (method) {
      case 'cookie': {
        const selectedCompany$ = await lastValueFrom(
          this.store.select(selectSelectedCompany).pipe(take(1), startWith(undefined))
        );

        if (selectedCompany$ !== undefined && selectedCompany$ !== null)
          req = req.clone({ params: req.params.append('company', selectedCompany$.company_name) });

        // Append CSRF Token to the request if it is not excluded from CSRF check,
        // see CSRF_EXCLUDED_ENDPOINTS above
        if (!AuthInterceptor.CSRF_EXCLUDED_ENDPOINTS.has(req.url)) {
          const csrfToken$ = await lastValueFrom(
            this.store.pipe(
              select(selectCSRFToken),
              takeWhile((token) => token.value === undefined, true),
              tap((token) => {
                if (!token.value && !token.isLoading) this.store.dispatch(getCSRFToken());
              }),
              first((token) => token.value !== undefined && token.value !== null),
              map((token) => token.value!)
            )
          );

          req = req.clone({
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
              .set('X-CSRFToken', csrfToken$),
          });
        }

        break;
      }
      case 'jwt': {
        // Handle JWT case if needed
        break;
      }
    }

    return await lastValueFrom(next.handle(req));
  }

  // private addCompanyReference(request: HttpRequest<any>): HttpRequest<any> {
  //   return request.clone({
  //     params: request.params.set('company', this.global.getCompany()),
  //   });
  // }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { LoadingModule } from '../loading/loading.module';
import {
  ContentPlaceholderComponent,
  CustomContentPlaceholderDirective,
} from './content-placeholder.component';

@NgModule({
  imports: [CommonModule, LoadingModule, NgbPopoverModule, MatIconModule, TranslocoModule],
  declarations: [ContentPlaceholderComponent, CustomContentPlaceholderDirective],
  exports: [ContentPlaceholderComponent, CustomContentPlaceholderDirective],
})
export class ContentPlaceholderModule {}

<div class="zeva-page">
  <zeva-grid [columns]="2">
    <zeva-card gridItem columnSpan="one" class="tile card-fullscreen">
      <ng-container *ngFor="let faq of faqs">
        <zeva-card
          gridItem
          columnSpan="one"
          class="faq-container"
          [collapsed]="faq.collapsed"
          collapsible
          #collapsible="cardCollapsible"
        >
          <zeva-card-header class="action-header">
            <p class="mat-headline-5">{{ faq.question }}</p>
            <button mat-icon-button (click)="collapsible.toggle()">
              <mat-icon *ngIf="collapsible.collapsed && !darkMode" svgIcon="expand"></mat-icon>
              <mat-icon *ngIf="collapsible.collapsed && darkMode" svgIcon="expand-dark"></mat-icon>
              <mat-icon *ngIf="!collapsible.collapsed" svgIcon="collapse"></mat-icon>
            </button>
          </zeva-card-header>
          <div [innerHTML]="faq.answer"></div>
        </zeva-card>
      </ng-container>
    </zeva-card>

    <zeva-card
      gridItem
      columnSpan="one"
      class="tile help-request"
      [ngClass]="{ 'ticket-submitted': ticketSubmitted }"
    >
      <div class="support-modal-wrapper">
        <form [formGroup]="form" *ngIf="!ticketSubmitted">
          <div class="fields-wrapper">
            <p class="mat-headline-5 title">{{ "submit_a_help_request" | transloco }}</p>

            <p class="info">{{ "were_here_to_help_please_enter_the_details_of_your_request_below_upon_submission_you_will_receive_an_email_confirmation" | transloco }}</p>

            <mat-form-field>
              <input matInput formControlName="email" type="text" readonly />
              <mat-label>{{ "email" | transloco }}</mat-label>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ "request_type" | transloco }}</mat-label>
              <mat-select formControlName="title">
                <mat-option
                  *ngFor="let ticketType of SUPPORT_TICKET_TYPES"
                  [value]="ticketType.value"
                >
                  {{ ticketType.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ "request_details" | transloco }}</mat-label>
              <textarea matInput formControlName="info"></textarea>
            </mat-form-field>
          </div>
        </form>
        <div class="bottom-wrapper" *ngIf="!ticketSubmitted">
          <div *ngIf="error" class="error-text">{{ error }}</div>
          <div class="buttons-wrapper {{ error ? 'error-shown' : '' }}">
            <button
              mat-flat-button
              [disabled]="form.invalid"
              type="button"
              color="primary"
              (click)="submitTicket($event)"
            >{{ "submit" | transloco }}</button>
          </div>
        </div>
        <div class="submit-confirmation-dialog-container" *ngIf="ticketSubmitted">
          <p class="mat-headline-5 title">{{ "ticket_sent" | transloco }}</p>
          <p class="submit-confirmation-msg">{{ submitConfirmationMsg1 }}</p>
          <p class="submit-confirmation-msg">{{ submitConfirmationMsg2 }}</p>
          <div class="new-confirm-button-wrapper">
            <button mat-stroked-button color="primary" (click)="newForm()">{{ "submit_another_request" | transloco }}</button>
          </div>
        </div>
      </div>
    </zeva-card>
  </zeva-grid>
  <core-footer class="footer_container" [ngClass]="{ sidenav_open: sidenav.opened }"></core-footer>
</div>

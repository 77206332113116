import { createAction, props } from '@ngrx/store';
import { ProviderCredential } from 'src/app/admin/admin.models';
import { Vehicle } from '../models/vehicle.class';
import { Fleet, FleetDTO, VehicleDTO } from '../models/vehicle.models';

export const getVehicleList = createAction('[Vehicle] Get Vehicle List');
export const setVehicleList = createAction(
  '[Vehicle] Set Vehicle List',
  props<{ vehicleList: Vehicle[] | null; noUpdate?: true }>()
);
export const createVehicleList = createAction(
  '[Vehicle] Create Vehicle List',
  props<{ vehicleList: VehicleDTO[]; tokenId?: number }>()
);
export const updateVehicleDetails = createAction(
  '[Vehicle] Update Vehicle List',
  props<{ vehicle: VehicleDTO }>()
);
export const deleteVehicleList = createAction(
  '[Vehicle] Delete From Vehicle List',
  props<{ vehicleIds: string[]; permanent?: boolean }>()
);
export const unarchiveVehicleList = createAction(
  '[Vehicle] Unarchive Vehicles',
  props<{ vehicleIds: string[] }>()
);

export const updateVehicleColor = createAction(
  '[Vehicle] Update Vehicle User Color',
  props<{ vehicleId: string; newColor: string }>()
);
export const setVehicleColor = createAction(
  '[Vehicle] Set Vehicle User Color',
  props<{ vehicleId: string; newColor: string }>()
);

export const getFleetList = createAction('[Vehicle] Get Vehicle Fleets');
export const setFleetList = createAction(
  '[Vehicle] Set Vehicle Fleets',
  props<{ fleetList: Fleet[] | null; noUpdate?: true }>()
);
export const createFleetList = createAction(
  '[Vehicle] Create Fleet List',
  props<{ fleetList: FleetDTO[] }>()
);
export const deleteFleetList = createAction(
  '[Vehicle] Delete Fleet List',
  props<{ fleetIds: number[] }>()
);
export const removeFromFleet = createAction(
  '[Vehicle] Remove Vehicles From Fleet',
  props<{ fleet: number; vehicles: string[] }>()
);

export const getProviderCredentials = createAction('[Admin] Get Provider Credentials');
export const setProviderCredentials = createAction(
  '[Admin] Set Provider Credentials',
  props<{ providerCredentials: ProviderCredential[] | null; error?: string }>()
);
export const deleteProviderCredentials = createAction(
  '[Admin] Delete Provider Credentials',
  props<{ credential: ProviderCredential }>()
);

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientWrapper } from '../../http-client-wrapper';
import { SupportTicketResponse } from './support.models';
import { supportTicketUrl } from './support.urls';

interface SupportTicketArgs {
  id: number;
  reply_email: string;
  user_email: string;
  current_url: string;
  title: string;
  content: string;
}

@Injectable()
export class SupportService {
  constructor(private wrapper: HttpClientWrapper) {}

  public supportTicket({
    id,
    reply_email,
    user_email,
    current_url,
    title,
    content,
  }: SupportTicketArgs): Observable<SupportTicketResponse> {
    return this.wrapper.post<SupportTicketResponse>(supportTicketUrl, {
      state: {
        auth: {
          id: id,
          auth_email: user_email,
        },
        current_url: current_url,
      },
      ticket: {
        reply_email: reply_email,
        title: title,
        content: content,
      },
    });
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SnackbarService } from '../../../shared/subscription-snackbar/subscription-snackbar.service';
import { MonitoringAppState } from '../monitoring.reducer';
import { MonitoringDataService } from './monitoring-data.service';
import * as MonitoringActions from './monitoring.actions';

@Injectable()
export class MonitoringGeneralEffects {
  getChargeHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getChargeHistory),
      switchMap((action) =>
        this.dataService.getChargeHistory(action.vehicle, action.time_0, action.time_1).pipe(
          map((chargeHistory) => MonitoringActions.setChargeHistory({ chargeHistory })),
          catchError(() => of(MonitoringActions.setChargeHistoryError()))
        )
      )
    )
  );

  getVehicleAlerts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getVehicleAlerts),
      switchMap((action) =>
        this.dataService.getVehicleAlerts(action.time_0, action.time_1, action.vehicle).pipe(
          map((alerts) => MonitoringActions.setVehicleAlerts({ alerts })),
          catchError(() => of(MonitoringActions.setVehicleAlerts({ alerts: null })))
        )
      )
    )
  );

  getVehicleCommandLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getVehicleLogs),
      switchMap((action) =>
        this.dataService.getVehicleLogs(action.vehicles).pipe(
          map((vehicleLogs) => MonitoringActions.setVehicleLogs({ vehicleLogs })),
          catchError(() => of(MonitoringActions.setVehicleLogs({ vehicleLogs: null })))
        )
      )
    )
  );

  getVehicleDestinations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getVehicleDestinations),
      switchMap((action) =>
        this.dataService.getVehicleDestinations(action.vehicle, action.count).pipe(
          map((destinations) => MonitoringActions.setVehicleDestinations({ destinations })),
          catchError(() => of(MonitoringActions.setDestinationsError()))
        )
      )
    )
  );

  getVehicleChargeLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getVehicleChargeLocations),
      switchMap((action) =>
        this.dataService.getVehicleChargeLocations(action.vehicle, action.count).pipe(
          map((locations) => MonitoringActions.setVehicleChargeLocations({ locations })),
          catchError(() => of(MonitoringActions.setChargeLocationsError()))
        )
      )
    )
  );

  // --------------- Driver Invites

  createDriverInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.createDriverInvite),
      switchMap((action) =>
        this.dataService
          .createDriverInvites([action.vehicle], {
            [action.vehicle]: action.revoke_date,
          })
          .pipe(
            map(() => MonitoringActions.getDriverInvites({ vehicle: action.vehicle })),
            catchError((err: Error) =>
              of(MonitoringActions.setDriverInvitesError({ error: err.message }))
            )
          )
      )
    )
  );

  updateDriverInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.updateDriverInvite),
      switchMap((action) =>
        this.dataService.updateDriverInvites([action.update]).pipe(
          map(() => MonitoringActions.getDriverInvites({ vehicle: action.vehicle })),
          catchError((err: Error) =>
            of(MonitoringActions.setDriverInvitesError({ error: err.message }))
          )
        )
      )
    )
  );

  deleteDriverInvites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.deleteDriverInvite),
      switchMap((action) =>
        this.dataService.deleteDriverInvites([action.id]).pipe(
          map(() => MonitoringActions.getDriverInvites({ vehicle: action.vehicle })),
          catchError((err: Error) =>
            of(MonitoringActions.setDriverInvitesError({ error: err.message }))
          )
        )
      )
    )
  );

  getDriverInvites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getDriverInvites),
      switchMap((action) =>
        this.dataService.getDriverInvites([action.vehicle]).pipe(
          map((invite_data) => MonitoringActions.setDriverInvites({ invite_data })),
          catchError((err: Error) =>
            of(MonitoringActions.setDriverInvitesError({ error: err.message }))
          )
        )
      )
    )
  );

  // --------------- Charging Schedules

  createChargingSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.createVehicleChargingSchedule),
      switchMap((action) =>
        this.dataService.createChargingSchedule(action.schedule, action.vehicles).pipe(
          map((schedules) => MonitoringActions.setVehicleChargingSchedules({ schedules })),
          tap(() => {
            if (!action.schedule.id)
              this.snackbar.showSnackbar('The charging schedule has been successfully created');
            else this.snackbar.showSnackbar('The charging schedule has been successfully updated');
          }),
          catchError((err: Error) =>
            of(
              MonitoringActions.setVehicleChargingSchedules({ schedules: null, error: err.message })
            )
          )
        )
      )
    )
  );

  getChargingSchedules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getVehicleChargingSchedules),
      switchMap((action) =>
        this.dataService.getChargingSchedules(action.vehicles).pipe(
          map((schedules) => MonitoringActions.setVehicleChargingSchedules({ schedules })),
          catchError((err: Error) =>
            of(
              MonitoringActions.setVehicleChargingSchedules({ schedules: null, error: err.message })
            )
          )
        )
      )
    )
  );

  deleteChargingSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.deleteVehicleChargingSchedule),
      switchMap((action) =>
        this.dataService.deleteChargingSchedule(action.schedule_id, action.vehicles).pipe(
          map((schedules) => MonitoringActions.setVehicleChargingSchedules({ schedules })),
          catchError((err) =>
            of(
              MonitoringActions.setVehicleChargingSchedules({ schedules: null, error: err.message })
            )
          )
        )
      )
    )
  );

  // --------------- Charging Settings

  getChargingSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getVehicleChargingSettings),
      switchMap((action) =>
        this.dataService.getChargingSettings(action.vehicle).pipe(
          map((settings) => MonitoringActions.setVehicleChargingSettings({ settings })),
          catchError((err: Error) =>
            of(MonitoringActions.setVehicleChargingSettings({ settings: null, error: err.message }))
          )
        )
      )
    )
  );

  getRangeEstimate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getRangeEstimate),
      switchMap((action) =>
        this.dataService.getRangeEstimate(action.vehicles).pipe(
          map((rangeEstimate) => MonitoringActions.setRangeEstimate({ rangeEstimate })),
          catchError(() => of(MonitoringActions.setRangeEstimateError()))
        )
      )
    )
  );

  getPhantomDrain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getPhantomDrain),
      switchMap((action) =>
        this.dataService.getPhantomDrain(action.vehicles, action.time_0, action.time_1).pipe(
          map((phantomDrain) => MonitoringActions.setPhantomDrain({ phantomDrain })),
          catchError(() => of(MonitoringActions.setPhantomDrainError()))
        )
      )
    )
  );

  // --------------- Charging Invoices

  getVehicleOwnership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getVehicleOwnership),
      switchMap((action) =>
        this.dataService.getVehicleOwnership(action.vehicles).pipe(
          map((ownership) => MonitoringActions.setVehicleOwnership({ ownership })),
          catchError(() => of(MonitoringActions.setVehicleOwnership({ ownership: null })))
        )
      )
    )
  );

  getChargingInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getChargingInvoices),
      switchMap((action) =>
        this.dataService.getChargingInvoices(action.vehicles, action.time_0, action.time_1).pipe(
          map((chargingInvoices) => MonitoringActions.setChargingInvoices({ chargingInvoices })),
          catchError((err) =>
            of(MonitoringActions.setChargingInvoices({ chargingInvoices: null, error: err }))
          )
        )
      )
    )
  );

  getHomeChargingInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getHomeChargingInvoices),
      switchMap((action) =>
        this.dataService
          .getHomeChargingInvoices(action.vehicles, action.time_0, action.time_1, action.pagination)
          .pipe(
            map((chargingInvoices) =>
              MonitoringActions.setHomeChargingInvoices({ chargingInvoices })
            ),
            catchError((err) =>
              of(MonitoringActions.setHomeChargingInvoices({ chargingInvoices: null, error: err }))
            )
          )
      )
    )
  );

  // -------- weather
  getWeather$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MonitoringActions.getWeather),
      switchMap((action) =>
        this.dataService.getWeatherData(action.vehicle).pipe(
          map((weather) => MonitoringActions.setWeather({ weather })),
          catchError((err: Error) =>
            of(MonitoringActions.setWeather({ weather: null, error: err.message }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private dataService: MonitoringDataService,
    private store: Store<MonitoringAppState>,
    private snackbar: SnackbarService
  ) {}
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { LiveStoreService } from './live-store.service';
import * as LiveActions from './live.actions';

@Injectable()
export class LiveEffects {
  getLiveTrafficData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LiveActions.getLiveTrafficData),
      concatMap((action) =>
        this.liveService
          .getLiveTrafficData(
            action.lat1,
            action.lat2,
            action.lng1,
            action.lng2,
            action.functionalClasses
          )
          .pipe(
            map((data) => LiveActions.setLiveTrafficData({ data })),
            catchError(() => of(LiveActions.setLiveTrafficDataError()))
          )
      )
    )
  );

  getLiveRouteData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LiveActions.getLiveRouteData),
      concatMap((action) =>
        this.liveService.getLiveRouteData(action.lat1, action.lat2, action.lng1, action.lng2).pipe(
          map((data) => LiveActions.setLiveRouteData({ data })),
          catchError(() => of(LiveActions.setLiveRouteDataError()))
        )
      )
    )
  );

  constructor(private actions$: Actions, private liveService: LiveStoreService) {}
}

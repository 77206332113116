import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, filter, interval, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { signOut } from '../../auth/store/auth.actions';
import { selectSession } from '../../auth/store/auth.selectors';
import { AuthService } from '../../auth/utility/auth.service';
import { AppState } from '../../store/app.reducers';
import { IdleCheckService } from './idle-check.service';
import {
  IdleWarningDialogComponent,
  IdleWarningDialogResponse,
} from './idle-warning-dialog/idle-warning-dialog.component';

@Component({
  selector: 'app-idle-watch',
  template: '',
  styleUrls: ['./idle-watch.component.scss'],
})
export class IdleWatchComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  // [14 minutes (59 minutes for dev)] after how long to prompt an idle warning dialog
  private readonly promptIdleAfter = environment.production ? 60 * 14 : 60 * 60 - 1;

  private whenAuthenticated$?: Subscription;
  private refresh$?: Subscription;
  private idle$?: Subscription;

  private dialogRef?: MatDialogRef<IdleWarningDialogComponent, IdleWarningDialogResponse>;

  constructor(
    private idleService: IdleCheckService,
    private store: Store<AppState>,
    private auth: AuthService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.whenAuthenticated$ = this.store
      .pipe(
        select(selectSession),
        tap((isAuthenticated?: boolean) => {
          if (isAuthenticated) this.start();
          else this.stop();
        })
      )
      .subscribe();
  }

  private start(): void {
    this.idle$ = this.idleService
      .watch(this.promptIdleAfter)
      .pipe(
        filter(() => !this.auth.keepSignedIn),
        filter((isIdle: boolean) => isIdle && !this.dialogRef),
        tap((isIdle: boolean) => {
          if (isIdle && !this.dialogRef) {
            this.dialogRef = this.dialog.open(IdleWarningDialogComponent, {
              width: '30rem',
              hasBackdrop: true,
            });

            this.dialogRef.afterClosed().subscribe((result) => {
              switch (result) {
                case IdleWarningDialogResponse.EXIT:
                  this.stop();
                  this.store.dispatch(signOut());
                  void this.router.navigate(['auth']);
                  break;
              }

              this.dialogRef = undefined;
            });
          }
        })
      )
      .subscribe();

    // refresh session every `this.promptIdleAfter` seconds before it expires
    this.refresh$ = interval(this.promptIdleAfter * 1000).subscribe(() =>
      this.auth.refreshSession().subscribe()
    );
  }

  private stop(): void {
    this.idle$?.unsubscribe();
    this.idle$ = undefined;

    this.refresh$?.unsubscribe();
    this.refresh$ = undefined;

    this.dialogRef?.close();

    this.idleService.dispose();
  }

  ngOnDestroy(): void {
    this.whenAuthenticated$?.unsubscribe();
    this.stop();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardModule } from './components/card/card.module';
import {
  GridByDimensionsPipe,
  GridByItemWidthPipe,
  GridComponent,
} from './components/grid/grid.component';
import { GridItemDirective } from './directives/grid/grid-item.directive';

@NgModule({
  imports: [CommonModule, CardModule],
  declarations: [GridComponent, GridItemDirective, GridByDimensionsPipe, GridByItemWidthPipe],
  exports: [
    GridComponent,
    GridItemDirective,
    GridByDimensionsPipe,
    GridByItemWidthPipe,
    CardModule,
  ],
})
export class CardGridModule {}

import {
  Component,
  Input,
  OnChanges,
  Pipe,
  PipeTransform,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { PixelDimensions } from 'src/app/shared/helpers/directives/listens-dimensions.directive';
import { environment } from 'src/environments/environment';

const COLUMNS_NUMBER_MAPPING: { [key: number]: string } = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  // 7: 'seven',
  // 8: 'eight',
  // 9: 'nine',
  // 10: 'ten',
  // 11: 'eleven',
  // 12: 'twelve',
};

@Component({
  selector: 'zeva-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GridComponent implements OnChanges {
  /**
   * Decides how many items should normally fit in one row (before wrapping is forced)
   * @default 'three'
   */
  @Input() columns: string | number = 'three';
  columnsNum = 3;

  /**
   * Minimal width that an element of row can be resized to until it wraps onto the next row
   * @default 'auto'
   * @todo Actually implement this // TODO
   */
  @Input()
  itemMinWidth = 'auto';

  @Input('class')
  reflectClass?: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['columns']) {
      if (typeof this.columns === 'number') {
        if (COLUMNS_NUMBER_MAPPING[this.columns]) {
          this.columnsNum = this.columns;
          this.columns = COLUMNS_NUMBER_MAPPING[this.columns];
        } else {
          if (environment.debug) {
            console.error(
              `[DEBUG Grid] Invalid Grid Implementation: Accepted \`column\` values are:
                ${ Object.keys(COLUMNS_NUMBER_MAPPING).toString() }
                got:
                ${ this.columns }
              `
            );
          }

          this.columns = 'three';
          this.columnsNum = 3;
        }
      }
    }
  }
}

/** @deprecated unsuccessful attempt, do not use */
@Pipe({ name: 'gridByDimensions' })
export class GridByDimensionsPipe implements PipeTransform {
  transform(dimensions: PixelDimensions | null, thresholds: number[]): number {
    if (!dimensions) return thresholds.length;

    if (dimensions.width < thresholds[0]) return 1;

    for (let i = 1; i < thresholds.length; i++)
      if (thresholds[i - 1] < dimensions.width && dimensions.width <= thresholds[i]) return i;

    return thresholds.length;
  }
}

/**
 * Identifies how many columns zeva-grid can fit
 * depending of its own or its container's current dimensions
 * and the minimum accepted item width
 *
 * @param dimensions - pixel dimensions of the grid or its container,
 *  you can grab these from `ListensDimensionsDirective`
 *
 * @param min - minimum accepted width of a grid item (in pixels)
 *
 * @param options.ignorePaddings - optional parameter to flag
 *  that the grid or its container does not have paddings around grid items
 *
 * @param options.ignoreGutter - optional parameter to flag
 *  that the grid has 0 gaps between columns and rows
 */
@Pipe({ name: 'gridByItemWidth' })
export class GridByItemWidthPipe implements PipeTransform {
  transform(
    dimensions: PixelDimensions | null,
    min: number,
    options?: { ignorePaddings?: boolean; ignoreGutter?: boolean }
  ): number | undefined {
    if (!dimensions) return undefined;

    for (let i = 6; i >= 1; i--) {
      let projectedItemWidth = dimensions.width;

      // subtract 1.5rem 2 times for each padding side (if not ignored)
      if (!options?.ignorePaddings) projectedItemWidth -= 1.5 * 16 * 2;

      // subtract 1.5rem for each gutter gap in the grid (if not ignored)
      if (!options?.ignoreGutter) projectedItemWidth -= 1.5 * 16 * (i - 1);

      projectedItemWidth = projectedItemWidth / i;

      // return current index if the projected item width becomes lesser than min
      if (projectedItemWidth > min) return i;
    }

    return 1;
  }
}

import { ActionReducerMap } from '@ngrx/store';
import {
  GeofencesState,
  geofencesReducer,
} from '../pages/geofences/store/geofences.reducers';
import {
  LiveState,
  liveReducer,
} from '../pages/live/store/live.reducers';
import {
  ReplayState,
  replayReducer,
} from '../pages/replay/store/replay.reducer';
import { MonitoringGeneralState, monitoringGeneralReducer } from './general/monitoring.reducer';

export type MonitoringAppState = {
  monitoringGeneral: MonitoringGeneralState;
  live: LiveState;
  replay: ReplayState;
  geofences: GeofencesState;
};

export const monitoringAppReducers: ActionReducerMap<MonitoringAppState> = {
  monitoringGeneral: monitoringGeneralReducer,
  live: liveReducer,
  replay: replayReducer,
  geofences: geofencesReducer,
};

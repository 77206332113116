import { Permission } from '../../admin/admin.models';

export type UserPermissions = string[];

export enum CompanyDataType {
  FM = 'FM',
  IN = 'IN',
  SI = 'SI',
}

export type CompanyDTO = {
  company_name: string;
  data_type: CompanyDataType;
};

/**
 * Subscription states.
 */
export enum CompanyState {
  ACTIVE = 'ACTIVE', // Active
  TERMINATING = 'TERMINATING',
  TERMINATED = 'TERMINATED',
}

export type Company = CompanyDTO & {
  id: number;
  status: CompanyState;
  terminate_after: string | null;
  owners: { first_name: string; last_name: string; id: number }[];
  vehicles: string[];
  country: string;
  city: string;
  date_created: Date;
};

/**
 * Display names for the Company states.
 */
export const COMPANY_STATE_DISPLAY: { [key in CompanyState]: string } = {
  [CompanyState.ACTIVE]: 'Active',
  [CompanyState.TERMINATING]: 'Scheduled Deletion',
  [CompanyState.TERMINATED]: 'Deleted',
};

/**
 * Icon for the COMPANY states.
 */
export const COMPANY_STATE_ICON: {
  [key in CompanyState]: 'checkmark' | 'block' | 'error_outline';
} = {
  [CompanyState.ACTIVE]: 'checkmark',
  [CompanyState.TERMINATING]: 'error_outline',
  [CompanyState.TERMINATED]: 'error_outline',
};

export type UserProfileDTO = {
  email: string;
  first_name: string | null;
  last_name: string | null;
  groups?: number[];
};
export type UserProfile = UserProfileDTO & {
  id: number;
  date_joined: Date | null;
  last_login: Date | null;
  phone_number: string | null;
  groups: number[];
  permissions: UserPermissions;
  emailVerified: boolean;
  subscribed_to_emails: boolean;
  accepted_terms_and_conditions: string;
  accepted_privacy_policy: string;
  accepted_terms_of_service: string;
  country: string | null;
  postal_code: string | null;
  birth_year: number | null;
  gender: string | null;
  is_staff: boolean;
  is_individual: boolean;
  sso_provider: string;
  is_superuser: boolean;
};
export type UserProfileForm = {
  email: string;
  password: string;
};

export type UserPreferencesDTO = {
  distance_unit: string;
  appearance: string;
  language: string;
  timezone: string;
};

export type SessionResponse = {
  isAuthenticated: boolean;
  user?: UserProfile;
  preferences?: UserPreferencesDTO;
};

export type SignInResponse = {
  user: UserProfile;
  detail: string;
  expireIn: number;
};

export type ForgotPasswordResponse = {
  success: boolean;
  message: string;
};

export type ResetPasswordResponse = {
  message: string;
  success: boolean;
};

export type UserGroupDTO = {
  id?: number;
  name: string;
  permissions: number[];
  color: string;
};
export type UserGroup = UserGroupDTO & {
  id: number;
  can_modify: boolean;
  permissions: Permission[];
};

export type UserCanDeleteResponse = {
  can_delete: boolean;
  ownerships?: string[];
};

export type DemoCodeDTO = {
  code: string;
  days: number;
  valid_from: string;
  valid_until: string | null;
  uses_left: number;
};

export type IpData = {
  ip: string | null;
  country: string | null;
};

export type Tax = {
  name: string;
  rate: number;
  display_name: string;
  tax_type: string;
};

import {
  TA_ECHARTS_DEFAULT_OPTIONS,
  TA_ECHARTS_RADIAL_DEFAULT_OPTIONS,
  TA_ECHARTS_RADIAL_SERIES_DEFAULT_OPTIONS,
  TA_ECHARTS_SERIES_DEFAULT_OPTIONS,
} from '@temerity-analytics/ngx-ta-echarts';
import {
  TA_GOOGLE_MAPS_CUSTOM_THEMES,
  TA_GOOGLE_MAPS_DEFAULT_OPTIONS,
  TA_GOOGLE_MAPS_DEFAULT_STYLING_FUNCTION,
  TA_GOOGLE_MAPS_DEFAULT_THEME,
  TA_GOOGLE_MAPS_NAVIGATION_GATEWAY,
  TA_GOOGLE_MAPS_THEME,
} from '@temerity-analytics/ngx-ta-maps';
import { BehaviorSubject } from 'rxjs';
import { ZEVA_DEFAULT_CHART_OPTIONS } from './core/core.static';
import {
  DEFAULT_SERIES_OPTIONS,
  UNSTACKED_PIE_SERIES_OPTIONS,
} from './shared/charts/charts.static';
import { DefaultGoogleMapsOptions } from './shared/map/map.static';
import { MAP_THEME_DARK, MAP_THEME_SILVER } from './shared/map/map.styles';
import { NavigationService } from './shared/map/navigation.service';

export const TA_LIB_PROVIDERS = [
  {
    provide: TA_ECHARTS_DEFAULT_OPTIONS,
    useValue: ZEVA_DEFAULT_CHART_OPTIONS,
  },
  { provide: TA_ECHARTS_SERIES_DEFAULT_OPTIONS, useValue: DEFAULT_SERIES_OPTIONS },
  {
    provide: TA_ECHARTS_RADIAL_SERIES_DEFAULT_OPTIONS,
    useValue: { barMaxWidth: '25%', barGap: '100%', showBackground: true },
  },
  {
    provide: TA_ECHARTS_RADIAL_DEFAULT_OPTIONS,
    useValue: UNSTACKED_PIE_SERIES_OPTIONS,
  },
  {
    provide: TA_GOOGLE_MAPS_DEFAULT_THEME,
    useValue: 'dark',
  },
  {
    provide: TA_GOOGLE_MAPS_THEME,
    useValue: new BehaviorSubject('dark'),
  },
  {
    provide: TA_GOOGLE_MAPS_CUSTOM_THEMES,
    useValue: {
      light: MAP_THEME_SILVER,
      dark: MAP_THEME_DARK,
    },
  },
  {
    provide: TA_GOOGLE_MAPS_NAVIGATION_GATEWAY,
    useClass: NavigationService,
  },
  {
    provide: TA_GOOGLE_MAPS_DEFAULT_OPTIONS,
    useValue: DefaultGoogleMapsOptions,
  },
  {
    provide: TA_GOOGLE_MAPS_DEFAULT_STYLING_FUNCTION,
    useValue: (() => ({
      strokeColor: '#003aa6',
      strokeWeight: 0,
      fillColor: '#ffffff00',
      fillOpacity: 0.5,
    })) as google.maps.Data.StylingFunction,
  },
];

import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { RouterOutlet } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import * as moment from 'moment';
import { Observable, OperatorFunction, catchError, takeWhile, tap } from 'rxjs';

export type ColorTuple = [red: number, green: number, blue: number];

/**
 * Apply mixins to a class that needs to
 * @param derivedConstructor
 * @param baseConstructors
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function applyMixins(derivedConstructor: any, baseConstructors: any[]): void {
  baseConstructors.forEach((baseConstructor) => {
    Object.getOwnPropertyNames(baseConstructor.prototype).forEach((name) => {
      Object.defineProperty(
        derivedConstructor.prototype,
        name,
        Object.getOwnPropertyDescriptor(baseConstructor.prototype, name) || Object.create(null)
      );
    });
  });
}

/**
 * Creates a promise that resolves after a number of seconds passed from the parameters.
 * @usage Halts async functions when used with async/await
 * @example
 * async function Func() {
 *  console.log("Uuugghhh, I'm gonna be late"); // this executes immediately
 *  await sleep(60 * 60 * 1000); // sleeps for 1 hour
 *  console.log("Damn, I'm late"); // this executes 1 hour later
 * }
 */
export function sleep(milliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 * Converts input to string and adds leading zeroes to fill length passed from the parameters
 * @example
 * addZeroes(64, 4) -> '0064'
 * addZeroes(2) -> '02'
 * @param input - input to be converted into string with leading zeroes
 * @param length - length of the resulting string
 */
export function addZeroes(input: number | string, length = 2): string {
  const s = typeof input === 'number' ? input.toString() : input;
  return ('0000000000' + s).slice(-1 * length);
}

/**
 * Recursively looks up the DOM tree to see
 * if the provided element has a parent with the provided selector
 * @param parentElement
 * @param tagName
 * @returns
 */
export function hasParentRecursive(
  element: HTMLElement | null,
  selector: string,
  lookBy: 'className' | 'id' | 'tagName'
): boolean {
  if (element === null || element.parentElement === null) return false;

  switch (lookBy) {
    case 'className':
      // console.log(element.classList, selector);
      if (element.classList.contains(selector)) return true;
      break;
    case 'id':
      // console.log(element.id, selector);
      if (element.id === selector) return true;
      break;
    case 'tagName':
      // console.log(element.tagName, selector);
      if (element.tagName === selector.toUpperCase()) return true;
      break;
  }

  return hasParentRecursive(element.parentElement, selector, lookBy);
}

/**
 * Converts input Date to UTC Date
 * @param date - input to be converted into UTC Date format
 */
export function convertToUTC(date: Date): Date {
  const localTime = date.getTime();

  const utcOffset = date.getTimezoneOffset() * 60000;
  const utcTime = localTime - utcOffset;
  const utcDate = new Date(utcTime);

  return utcDate;
}

/**
 * Converts input date to UTC end of day
 * @param time - input to be converted
 */
export function toEndOfDay(time: Date): Date {
  return moment(time).add(1, 'day').subtract(1, 'millisecond').toDate();
}

/**
 * Converts input date to given timezone
 * @param time - input to be converted
 * @param timezone - timezone to be converted to
 */
export function convertToTimezone(time: Date, timezone: string): Date {
  const options = { timeZone: timezone };
  const date = new Date(time.toLocaleString('en-US', options));
  return date;
}

/**
 * Converts date to given timezone
 * @param time - input to be converted
 * @param timezone - timezone to be converted to
 */
export function convertFromTimezoneToUTC(time: Date, timezone: string): Date {
  const dateWithTz = convertToTimezone(time, timezone);
  const diff = moment(convertToUTC(time)).diff(moment(convertToUTC(dateWithTz)));
  return moment(time).add(diff).toDate();
}

/**
 * Converts the timezone string into its equivalent 2-letter format (excluding UTC)
 * @param timezone - timezone to be converted
 */
export function convertFromTimezoneToShortCode(timezone: string) {
  const timeZoneMapping: { [key: string]: string } = {
    'PST8PDT': 'PT',  // Pacific Time
    'MST7MDT': 'MT',  // Mountain Time
    'CST6CDT': 'CT',  // Central Time
    'EST5EDT': 'ET',  // Eastern Time
    'UTC': 'UTC',     // Coordinated Universal Time (same)
    'WET': 'WE',      // Western European Time
    'CET': 'CE',      // Central European Time
    'EET': 'EE',      // Eastern European Time
  };
  return timezone in timeZoneMapping ? timeZoneMapping[timezone] : timezone;
}

/**
 * Searches through a list of timezones and returns the matching one
 */
export function findMatchingTimezone(tz_one: string, all_tzs: any): any {
  const date = new Date(2000, 0, 1);
  for (let i = 0; i < all_tzs.length; i++) {
    if (
      new Date(date).toLocaleString('en-US', { timeZone: tz_one }) ===
      new Date(date).toLocaleString('en-US', { timeZone: all_tzs[i].slug })
    )
      return all_tzs[i];
  }
}

/**
 * Returns a supposedly-unique title of a router outlet route for navigation & route animation
 * @param outlet {RouterOutlet} - reference to a local router outlet component
 */
export function getRouterOutletState(outlet: RouterOutlet): void {
  return outlet.activatedRouteData['title'];
}

/** Return a Date object of the current date at 00:00 time */
export const START_OF_TODAY = (() => {
  const today = new Date();
  today.setHours(0, 0);
  return today;
})();
/** Return a Date object of the current date at 23:59 time */
export const END_OF_TODAY = (() => {
  const today = new Date();
  today.setHours(23, 59);
  return today;
})();
/** Return a DateRange object from the start of today to the end of today */
export const TODAY_RANGE = (() => {
  return new DateRange(START_OF_TODAY, END_OF_TODAY);
})();

// TODO: Enforce proper typing through templates
export function startRequestSequence(
  store: Store,
  actions: Actions,
  getAction: (props?: any) => unknown & TypedAction<any>,
  setAction: TypedAction<any>,
  paramSequence: number | unknown[],
  callback?: (action: any) => void
): Observable<any> {
  let sequenceStep = 0;

  const reactionFn = () => {
    if (typeof paramSequence === 'number') store.dispatch(getAction());
    else store.dispatch(getAction(paramSequence[sequenceStep]));
    ++sequenceStep;
  };
  reactionFn();

  return actions.pipe(
    ofType(setAction.type),
    // filter(state => state !== undefined && !Object.values(state).some(v => v === undefined)),
    tap(callback),
    takeWhile(
      () =>
        sequenceStep < (typeof paramSequence === 'number' ? paramSequence : paramSequence.length)
    ),
    tap(reactionFn)
  );
}

/**
 * HTTP Request interceptor for handled error responses that return a `200` status code
 * but carry an `error` member to signal that the backend knows of the error
 * but assumes it to be within expected behaviour.
 *
 * When caught throws an error so that it can be handled
 * within an observable stream with `catchError()` or in sync code with `try/catch` block
 *
 * @throws HttpErrorResponse({ error: 'Text message of the handler error' })
 */
export function handleErrorResponse<T>(): (stream$: Observable<T>) => Observable<T> {
  return (stream$) =>
    stream$.pipe(
      tap((data: T) => {
        if (data && typeof data === 'object' && 'error' in data)
          throw new Error(data.error as string);
      })
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const catchErrorResponse: OperatorFunction<any, any> = catchError(
  (error: HttpErrorResponse) => {
    // ensure that error.error is a obj containing an error_message key
    // otherwise scrub the response to an unknown error message
    if (error.error && typeof error.error === 'object') {
      if (error.error.error_message) throw new Error(error.error.error_message);
      else if (error.error.detail)
        throw new Error(error.error.detail); // e.g., from a permissions error in the backend
      else if (error.error.error)
        throw new Error(error.error.error); // e.g., from handled errors in the backend
      else if (Array.isArray(error.error))
        throw new Error(error.error.join('; ')); // e.g., from a ValidationError raised in backend
    }

    // If error.error is not an object or is null/undefined, return a generic error message
    throw new Error('An unexpected error occurred, please try again later.');
  }
);

export function isEmail(value: string): boolean {
  const emailRegex =
    // eslint-disable-next-line no-control-regex, max-len
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  return value.match(emailRegex) !== null;
}

export function HEXValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return !/^#(?:[0-9a-fA-F]{3}){1,2}$/.test(control.value) ? { notaHEX: true } : null;
  };
}

// GENERATING CODE VERIFIER
export function dec2hex(dec: number): string {
  return ('0' + dec.toString(16)).slice(-2);
}

export function generateRandomString(length: number): string {
  const array = new Uint32Array(length / 2);
  window.crypto.getRandomValues(array);

  return Array.from(array, dec2hex).join('');
}

// returns promise ArrayBuffer
export function sha256(plain: string): Promise<ArrayBuffer> {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);

  return window.crypto.subtle.digest('SHA-256', data);
}

export function base64urlencode(a: ArrayBuffer): string {
  let str = '';
  const bytes = new Uint8Array(a);
  const len = bytes.byteLength;

  for (let i = 0; i < len; i++) str += String.fromCharCode(bytes[i]);

  return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export function getBoundsZoomLevel(
  bounds: google.maps.LatLngBounds,
  mapDim: { height: number; width: number }
) {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  function latRad(lat: number) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx: number, worldPx: number, fraction: number) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

/**
 * Helper function that can be attached to a (scroll) event on any element
 * which tracks scroll position and executes a passed callback when reaches end of scroll
 * @param $event - scroll event reference from (scroll)
 * @param callback - callback function to be executed when scroll reaches its end
 */
export function detectScrollToBottom($event: Event, callback: () => void): void {
  const target = $event.target as HTMLElement;
  // visible height + pixel scrolled >= total height
  if (target.offsetHeight + target.scrollTop >= target.scrollHeight - 10) callback();
}

/**
 * Gets a color css string from a color tuple
 */
export function getColorString(t: ColorTuple): string {
  return `rgb(${t[0]}, ${t[1]}, ${t[2]})`;
}

/**
 * Gets a linear gradient color string
 */
export function getGradient(start: ColorTuple, end: ColorTuple, progress: number): string {
  const redStart = start[0];
  const redEnd = end[0];
  const greenStart = start[1];
  const greenEnd = end[1];
  const blueStart = start[2];
  const blueEnd = end[2];

  const red = redStart + progress * (redEnd - redStart);
  const green = greenStart + progress * (greenEnd - greenStart);
  const blue = blueStart + progress * (blueEnd - blueStart);

  return getColorString([red, green, blue]);
}

/**
 * Returns the browser name
 * @returns {string} - browser name
 * @author Juan Corral
 */
export function getBrowser(): string {
  const agent = window.navigator.userAgent.toLowerCase();
  const browser =
    agent.indexOf('edge') > -1
      ? 'edge'
      : agent.indexOf('edg') > -1
      ? 'chromium based edge'
      : agent.indexOf('opr') > -1 && 'opr' in window
      ? 'opera'
      : agent.indexOf('chrome') > -1 && 'chrome' in window
      ? 'chrome'
      : agent.indexOf('trident') > -1
      ? 'ie'
      : agent.indexOf('firefox') > -1
      ? 'firefox'
      : agent.indexOf('safari') > -1
      ? 'safari'
      : 'other';
  return browser;
}

export function getMobilePlatform(): string {
  const agent = window.navigator.userAgent.toLowerCase();
  const platform =
    agent.indexOf('android') > -1 ? 'android' : agent.indexOf('iphone') > -1 ? 'iphone' : 'other';
  return platform;
}

export function redirectToAndroid(result: any): void {
  const url = 'https://play.google.com/store/apps/details?id=com.zevaglobal.zeva&hl=en_US';
  if (result) {
    window.location.replace(
      'intent://zevaglobal.com/#Intent;scheme=zevamobile;package=com.zevaglobal.zeva;end'
    );
  } else if (result === 'Learn more') {
    window.location.href = url;
  }
}
export function redirectToIOS(result: any): void {
  const url = 'zevamobile://';
  if (result === true) window.location.replace(url);
  else if (result === 'Learn more')
    window.location.replace('https://apps.apple.com/us/app/zeva/id6476576625');
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { APP_VERSION } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'core-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  /**
   * Footer data
   */
  public readonly year = new Date().getFullYear();
  public readonly appVersion = APP_VERSION;
  public readonly debug = environment.debug;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardFooterDirective } from '../../directives/card/card-footer.directive';
import { CardHeaderDirective } from '../../directives/card/card-header.directive';
import { CardTitleDirective } from '../../directives/card/card-title.directive';
import { CardCollapsibleDirective, CardComponent } from './card.component';

@NgModule({
  declarations: [
    CardComponent,
    CardCollapsibleDirective,
    CardHeaderDirective,
    CardFooterDirective,
    CardTitleDirective,
  ],
  exports: [
    CardComponent,
    CardCollapsibleDirective,
    CardHeaderDirective,
    CardFooterDirective,
    CardTitleDirective,
  ],
  imports: [CommonModule],
})
export class CardModule {}

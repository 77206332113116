/**
 * Routes to not include when getting the previous route
 */
export const IGNORED_ROUTES: Array<string | ((s: string) => boolean)> = [
  s => s.startsWith('/auth/reset'),
  '/auth/forgot',
  '/auth/verify-email',
  '/auth/select-account',
  '/auth/login',
];

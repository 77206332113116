import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, tap } from 'rxjs';
import { COUNTRY_CODES } from 'src/app/core/core.static';
import { Loadable } from 'src/app/shared/loading-state/loadable';
import { getIpData } from '../store/auth.actions';
import { selectIpData } from '../store/auth.selectors';
import { IpData } from './auth.models';

/**
 * Checks if the current user is in Canada,
 * redirects to error page if not
 */
export const ipGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);
  return store.select(selectIpData).pipe(
    tap((ipData: Loadable<IpData>) => {
      if (ipData.value === undefined && !ipData.isLoading && !ipData.error)
        store.dispatch(getIpData());
    }),
    filter(
      (ipData: Loadable<IpData>) =>
        (ipData.value !== undefined || !!ipData.error) && !ipData.isLoading
    ),
    map((ipData: Loadable<IpData>) => {
      if (!ipData.value || !ipData.value.country || COUNTRY_CODES.includes(ipData.value.country))
        return true;
      return router.createUrlTree(['auth', 'coming-soon']);
    })
  );
};

<div class="footer th-color-secondary-text">
  <span><!-- offset element for flexbox --></span>
  <span class="copyright">
    Copyright &copy; {{ year }}
    <a href="https://zevaglobal.com/" target="_blank">ZEVA Global Inc</a>. All rights reserved |
    <a routerLink="/terms-of-use" target="_blank">Terms of Use</a> |
    <a routerLink="/privacy-policy" target="_blank">Privacy Policy</a> |
    <a routerLink="/terms-of-service" target="_blank">Terms of Service</a>
  </span>
  <span class="version">{{ debug ? appVersion : '' }}</span>
</div>

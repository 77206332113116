import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';
import {
  NgxTaEChartsModule,
  TaEchartsCustomLegendModule,
} from '@temerity-analytics/ngx-ta-echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { CardGridModule } from '../../../core/card-grid/card-grid.module';
import { ContentPlaceholderModule } from '../../content-placeholder/content-placeholder.module';
import { WidgetHelpersModule } from '../../widget/helpers/widget-helpers.module';
import {
  ChartDataCheckPipe,
  ChartTimeFormatPipe,
  SeriesModifierPipe,
  TimeBasedChartComponent,
} from './time-based-chart.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxEchartsModule,
    WidgetHelpersModule,
    NgxTaEChartsModule,
    TaEchartsCustomLegendModule,
    ContentPlaceholderModule,
    MatFormFieldModule,
    MatSelectModule,
    CardGridModule,
    TranslocoModule
  ],
  declarations: [
    TimeBasedChartComponent,
    ChartDataCheckPipe,
    ChartTimeFormatPipe,
    SeriesModifierPipe,
    // ChartTimeSeriesSortPipe,
  ],
  exports: [TimeBasedChartComponent, ChartDataCheckPipe, ChartTimeFormatPipe],
})
export class TimeBasedChartModule {}

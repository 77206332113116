/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';
import { APP_VERSION } from './app/app.component';

if (environment.production) enableProdMode();

Sentry.init({
  dsn: 'https://eb151c75ccaf478db4cc4208e085c4bc@o369613.ingest.sentry.io/4505110717267968',
  release: APP_VERSION,
  environment: environment.name,
  enabled: environment.production,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [new Sentry.Replay()],
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { PermissionsService } from './permissions.service';

@Pipe({
  name: 'hasPermissions',
})
export class HasPermissionsPipe implements PipeTransform {
  constructor(private readonly permissions: PermissionsService) {}

  transform(permissions: string | string[]): Observable<boolean> {
    return this.permissions.hasPermissions(permissions);
  }
}

@Pipe({
  name: 'hasSomePermission',
})
export class HasSomePermissionPipe implements PipeTransform {
  constructor(private readonly permissions: PermissionsService) {}

  transform(permissions: string | string[]): Observable<boolean> {
    return this.permissions.hasPermissions(permissions);
  }
}

import { createAction, props } from '@ngrx/store';

/**
 * When dispatched triggers all states to be reset to their initial states.
 *
 * ------------
 *
 * NOTE:
 * When creating a new ngrx/reducer make sure to add the following to the reducer:
 * ```
 * import { resetState } from '/store/app.actions'
 *
 * const initialState = {
 *   // your Reducer's initial state...
 * }
 *
 * const reducer = createReducer<MonitoringGeneralState>(
 *   initialState,
 *   on(resetState, () => ({
 *     ...initialState,
 *   })),
 *   // ...
 * )
 * ```
 *
 * ------------
 *
 * Auth's Reducer is made to ignore typical `resetState({})` call;
 * so, call `resetState({ resetAuth: true })` to force it to reset together with everything else
 */
export const resetState = createAction(
  '[App] Reset all States to Initial',
  props<{ resetAuth?: boolean }>()
);

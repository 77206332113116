import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, Observable } from 'rxjs';

/**
 * Service for monitoring browser state when it changes between idle and non-idle
 * @author Anton Valeev
 */
@Injectable({
  providedIn: 'root',
})
export class IdleCheckService {
  /** Number of seconds after which user should be deemed idle if inactive */
  private idleAfter = 0;
  /**  */
  private idleAt: number = Date.now();

  public readonly isIdle$ = new BehaviorSubject<boolean>(false);

  public static readonly DEFAULT_EVENTS = ['keyup', 'mousemove', 'mouseup', 'touchstart', 'scroll'];
  private listeners: string[] = [];

  private interval?: number;

  constructor() {}

  /**
   * Initiates Idle State watch
   * @param idleAfter - amount of seconds after which a user is considered to be idle
   * @param listenEvents - array of keys of events that define a user as non-idle.
   *    Optional, defaults to `['keyup', 'mousemove', 'mouseup', 'touchstart', 'scroll']`
   * @returns - boolean observable that emits an idle status value
   * every time it changes between true and false
   * @author Anton Valeev
   */
  public watch(idleAfter: number, listenEvents?: string[]): Observable<boolean> {
    this.idleAfter = idleAfter;
    this.idleAt = Date.now() + this.idleAfter * 1000;

    this.listeners = listenEvents ? listenEvents : IdleCheckService.DEFAULT_EVENTS;
    this.track();

    this.startInterval();

    return this.isIdle$.asObservable().pipe(
      distinctUntilChanged(),
      filter(isIdle => isIdle !== undefined && isIdle !== null)
    );
  }

  /**
   * Clears all listeners and intervals making service inactive, as it will not track any activity
   */
  public dispose(): void {
    if (this.interval) window.clearInterval(this.interval);
    this.listeners.forEach(key => window.removeEventListener(key, this._eventHandler));
  }

  /**
   * Updates the timestamp at which user will be deemed idle
   * by adding the set number of seconds from `this.idleAfter` to the `Date.now()` timestamp
   */
  private updateIdleTime(): void {
    this.idleAt = Date.now() + this.idleAfter * 1000;
    this.isIdle$.next(false);
  }
  private readonly _eventHandler = this.updateIdleTime.bind(this);

  /**
   * Initiates an interval that will check if user is idle
   * by checking if `Date.now()` is equal to `this.idleAt` timestamp set by `this.updateIdleTime()`
   */
  private startInterval(): void {
    this.updateIdleTime();

    // stop previous interval if any
    if (this.interval) window.clearInterval(this.interval);
    // set interval to check every second if user is idle or not
    this.interval = window.setInterval(() => {
      if (this.idleAt < Date.now()) this.isIdle$.next(true);
    }, 1000);
  }

  /**
   * Sets up event listeners that should update idle state to true when triggered
   */
  private track(): void {
    this.dispose();
    this.listeners.forEach(key => addEventListener(key, this._eventHandler), { passive: true });
  }
}

import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PopoverComponent, PopoverTriggerDirective } from './popover.component';

@NgModule({
  declarations: [PopoverComponent, PopoverTriggerDirective],
  exports: [PopoverComponent, PopoverTriggerDirective, OverlayModule],
  imports: [CommonModule, OverlayModule],
})
export class PopoverModule {}

import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivateFn, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, catchError, filter, map, of, switchMap, timeout } from 'rxjs';
import { HttpClientWrapper } from '../../http-client-wrapper';
import { selectSelectedCompany } from '../store/auth.selectors';
import { permissionCheckUrl } from '../utility/auth.urls';

@Injectable({ providedIn: 'root' })
class PermissionsGuardService {
  constructor(
    private httpClient: HttpClientWrapper,
    private _snackBar: MatSnackBar,
    private router: Router,
    private store: Store
  ) {}

  canActivate(permissions: string[], routeSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(selectSelectedCompany).pipe(
      filter(company => !!company),
      switchMap(() =>
        this.httpClient.get<{ allowed: boolean }>(permissionCheckUrl, { p: permissions }).pipe(
          timeout({
            each: 5000,
            with: () => {
              throw Error('Service Unresponsive. Please, try again later');
            },
          }),
          map(response => {
            if (!response.allowed) {
              this._snackBar.open(
                'Insufficient Permissions. Missing: ' + permissions.join(', '),
                'Dismiss',
                {
                  duration: 5000,
                }
              );
              const currentUrl = routeSnapshot.url.join('/');
              void this.router.navigate([currentUrl, 'error', '403']);
            }

            return response.allowed;
          }),
          catchError(error => {
            console.error(error);
            const currentUrl = routeSnapshot.url.join('/');
            void this.router.navigate([currentUrl, 'error', '500']);
            return of(false);
          })
        )
      )
    );
  }
}

export function hasPermissions(...permissions: string[]): CanActivateFn {
  return (route: ActivatedRouteSnapshot) => {
    return inject(PermissionsGuardService).canActivate(permissions, route);
  };
}

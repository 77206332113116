import {
    Component,
    ContentChild,
    Directive,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
    ViewEncapsulation,
} from '@angular/core';
import { CardFooterDirective } from '../../directives/card/card-footer.directive';
import { CardHeaderDirective } from '../../directives/card/card-header.directive';

@Component({
  selector: 'zeva-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardComponent implements OnInit {
  @ContentChild(CardHeaderDirective)
  header?: CardHeaderDirective;

  @ContentChild(CardFooterDirective)
  footer?: CardFooterDirective;

  @Input('class')
  reflectClass?: string;

  constructor() {}

  ngOnInit() {}
}

@Directive({
  selector: 'zeva-card[collapsible]',
  exportAs: 'cardCollapsible',
})
export class CardCollapsibleDirective {
  @Input()
  set collapsed(collapsed) {
    this.toggle(collapsed);
  }
  get collapsed() {
    return this._collapsed;
  }
  private _collapsed = false;

  constructor(
    private readonly elem: ElementRef<CardComponent>,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.elem.nativeElement, 'collapsible');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.elem.nativeElement, 'collapsible');
  }

  public toggle(collapse = !this._collapsed): void {
    if (collapse) this.collapse();
    else this.expand();
  }

  public collapse(): void {
    this._collapsed = true;
    this.renderer.addClass(this.elem.nativeElement, 'collapsed');
  }

  public expand(): void {
    this._collapsed = false;
    this.renderer.removeClass(this.elem.nativeElement, 'collapsed');
  }
}

import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[gridItem]',
})
export class GridItemDirective implements OnChanges {
  /**
   * Decides how many places grid column will take within its parent row
   * (can't be more than number of total columns of the parent row)
   * @default 'one'
   */
  @Input() columnSpan: string | undefined;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    renderer.addClass(el.nativeElement, 'grid-item');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['columnSpan']) {
      if (changes['columnSpan'].previousValue !== undefined) {
        this.renderer.removeClass(
          this.el.nativeElement,
          'takes-' + (changes['columnSpan'].previousValue as string)
        );
      }
      if (this.columnSpan !== undefined)
        this.renderer.addClass(this.el.nativeElement, 'takes-' + this.columnSpan);
    }
  }
}

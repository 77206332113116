import { Action, createReducer, on } from '@ngrx/store';
import { GeofenceDTO } from '../geofences.models';
import * as GeofencesActions from './geofences.actions';

export type GeofencesState = {
  loadingStatus: {
    geofenceList: boolean;
    vehicles: boolean;
  };
  geofenceList: GeofenceDTO[] | undefined;
};

const initialState: GeofencesState = {
  loadingStatus: {
    geofenceList: false,
    vehicles: false,
  },
  geofenceList: undefined,
};

const reducer = createReducer<GeofencesState>(
  initialState,
  // -------- Geofence List
  on(GeofencesActions.getGeofenceList, (state) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      geofenceList: true,
    },
  })),
  on(GeofencesActions.setGeofenceList, (state, { geofenceList }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      geofenceList: false,
    },
    geofenceList,
  }))
);

export function geofencesReducer(state: GeofencesState | undefined, action: Action): any {
  return reducer(state, action);
}
export const geofencesFeatureKey = 'geofences';

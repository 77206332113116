import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Loadable } from '../../../../shared/loading-state/loadable';
import { TripSummary, VehicleState } from '../../../../shared/vehicle/models/vehicle.models';
import { VehicleAlertsResponse } from '../../../monitoring.models';
import * as HTActions from './replay.actions';

export type ReplayState = {
  loadingStatus: {
    vehicleTrips: boolean;
    vehicleAlerts: boolean;
  };
  vehicleTrips?: VehicleState[] | null;
  vehicleTripSummaries: Loadable<TripSummary[]>;
  currentTrip: Loadable<VehicleState[]>;
  vehicleAlerts?: VehicleAlertsResponse | null;
};

const initialState: ReplayState = {
  loadingStatus: {
    vehicleTrips: false,
    vehicleAlerts: false,
  },
  vehicleTrips: undefined,
  vehicleAlerts: undefined,
  vehicleTripSummaries: new Loadable(),
  currentTrip: new Loadable(),
};

const reducer = createReducer<ReplayState>(
  initialState,
  // -------- Vehicle List
  on(HTActions.getVehicleTrips, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleTrips: true,
    },
  })),
  on(HTActions.setVehicleTrips, (state, { tripsStates }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleTrips: false,
    },
    vehicleTrips: tripsStates,
  })),
  on(HTActions.getVehicleHistoricalAlerts, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      vehicleAlerts: true,
    },
  })),
  on(HTActions.setVehicleHistoricalAlerts, (state, { alerts }) => {
    return {
      ...state,
      loadingStatus: {
        ...state.loadingStatus,
        vehicleAlerts: false,
      },
      vehicleAlerts: alerts,
    };
  }),
  on(HTActions.setVehicleTripSummaries, (state, { summaries }) => {
    return {
      ...state,
      vehicleTripSummaries: new Loadable(cloneDeep(summaries), false),
    };
  }),
  on(HTActions.getVehicleTripSummaries, state => {
    return {
      ...state,
      vehicleTripSummaries: state.vehicleTripSummaries.loadingCopy(true),
    };
  }),
  on(HTActions.setVehicleTripSummariesError, state => {
    return {
      ...state,
      vehicleTripSummaries: state.vehicleTripSummaries.errorCopy('unknown error'),
    };
  }),
  on(HTActions.setCurrentTrip, (state, { states }) => {
    return {
      ...state,
      currentTrip: new Loadable(cloneDeep(states), false),
    };
  }),
  on(HTActions.getCurrentTrip, state => {
    return {
      ...state,
      currentTrip: state.currentTrip.loadingCopy(true),
    };
  }),
  on(HTActions.setCurrentTripError, state => {
    return {
      ...state,
      currentTrip: state.currentTrip.errorCopy('unknown error'),
    };
  })
);

export function replayReducer(
  state: ReplayState | undefined,
  action: Action
): ReplayState {
  return reducer(state, action);
}
export const replayFeatureKey = 'replay';

import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TA_GOOGLE_MAPS_THEME } from '@temerity-analytics/ngx-ta-maps';
import { BehaviorSubject, filter, Observable, Subscription, tap } from 'rxjs';
import { getUserPreferences } from '../../auth/store/auth.actions';
import { selectUserPreferences } from '../../auth/store/auth.selectors';
import { UserPreferencesDTO } from '../../auth/utility/auth.models';
import { Loadable } from '../../shared/loading-state/loadable';
import { AppState } from '../../store/app.reducers';

export enum Theme {
  LIGHT = 'theme--light',
  DARK = 'theme--dark',
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  theme$ = new BehaviorSubject<Theme>(Theme.LIGHT);
  currentTheme$ = this.theme$.asObservable();
  private readonly subscription = new Subscription();

  constructor(
    private ref: ApplicationRef,
    private readonly store: Store<AppState>,
    @Inject(TA_GOOGLE_MAPS_THEME) private readonly mapTheme: BehaviorSubject<string>
  ) {
    let preference = 'auto';
    const firstUserPreferences$ = this.store.select(selectUserPreferences).pipe(
      tap((preferences: Loadable<UserPreferencesDTO | null>) => {
        if (preferences.value === undefined && !preferences.error && !preferences.isLoading)
          this.store.dispatch(getUserPreferences());
      }),
      filter((preferences: Loadable<UserPreferencesDTO | null>) => !!preferences.value),
      tap((preferences: Loadable<UserPreferencesDTO | null>) => {
        const value: UserPreferencesDTO = preferences.value as UserPreferencesDTO;
        if (value.appearance) {
          if (value.appearance === 'dark') preference = Theme.DARK;
          else if (value.appearance === 'light') preference = Theme.LIGHT;
          else preference = 'auto';
          if (preference === 'auto') this.setAuto();
          else this.setTheme(preference as Theme);
        }
      })
    );
    this.subscription.add(firstUserPreferences$.subscribe());
    // Trigger refresh of UI
    this.ref.tick();
  }

  setTheme(theme: Theme): void {
    if (theme === Theme.DARK) {
      this.mapTheme.next('dark');
      document.getElementById('app-body')!.classList.remove(Theme.LIGHT);
    } else if (theme === Theme.LIGHT) {
      this.mapTheme.next('light');
      document.getElementById('app-body')!.classList.remove(Theme.DARK);
    }
    document.getElementById('app-body')!.classList.add(theme);
    this.theme$.next(theme);
  }

  // Returns an observable for the user's appearance preferences and sets the theme accordingly
  getTheme(): Observable<Loadable<UserPreferencesDTO | null>> {
    const listenUserPreferences$ = this.store.select(selectUserPreferences).pipe(
      tap((preferences: Loadable<UserPreferencesDTO | null>) => {
        if (preferences && preferences.value) {
          const value: UserPreferencesDTO = preferences.value;
          if (value.appearance) {
            if (value.appearance === 'dark') this.setTheme(Theme.DARK);
            else if (value.appearance === 'light') this.setTheme(Theme.LIGHT);
            else this.setAuto();
          } else {
            this.setAuto();
          }
        } else {
          this.setAuto();
        }
      })
    );
    return listenUserPreferences$;
  }

  // Returns the current theme
  get currentTheme(): Theme {
    return this.theme$.value;
  }

  // Set the theme based on the user's system preferences
  setAuto() {
    // determine which theme to use depending on user's browser/system theme preference
    const darkModeOn =
      window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    if (darkModeOn) this.setTheme(Theme.DARK);
    else this.setTheme(Theme.LIGHT);

    // Listen for changes of the preference
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
      const turnOn = e.matches;
      this.setTheme(turnOn ? Theme.DARK : Theme.LIGHT);
    });
  }
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { routerTransition } from '../core/core.static';
import { SidenavService } from '../core/sidenav/sidenav.service';
import { getRouterOutletState } from '../shared/helpers/utility';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  animations: [routerTransition],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsComponent implements OnInit {
  constructor(private readonly sidenav: SidenavService) {
    this.sidenav.opened = true;
  }

  ngOnInit(): void {}

  getState = getRouterOutletState;
}

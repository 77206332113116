import { createAction, props } from '@ngrx/store';
import { ListDTO } from '../../loading-state/api.models';
import { SubscriptionDTO, SubscriptionPlanDTO } from '../subscription.models';

export const getSubscriptionPlans = createAction('[Subscription] Get Subscription Plans');
export const setSubscriptionPlans = createAction(
  '[Subscription] Set Subscription Plans',
  props<{ subscriptionPlans: ListDTO<SubscriptionPlanDTO> | null; error?: string }>()
);

export const getSubscription = createAction('[Subscription] Get Subscription');
export const setSubscription = createAction(
  '[Subscription] Set Subscription',
  props<{ subscription: SubscriptionDTO | null; error?: string }>()
);


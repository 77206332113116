import { Action, createReducer, on } from "@ngrx/store";
import { resetState } from '../../../store/app.actions';
import * as LoadingActions from './download.actions';

export type DownloadState = {
  isDownloading: boolean;
}

const initialState: DownloadState = {
  isDownloading: false,
}

const reducer = createReducer<DownloadState>(
  initialState,
  on(resetState, () => ({ ...initialState })),
  on(LoadingActions.startDownload, state => ({ ...state, isDownloading: true })),
  on(LoadingActions.endDownload, state => ({ ...state, isDownloading: false }))
);

export function downloadReducer(
  state: DownloadState | undefined,
  action: Action
): DownloadState {
  return reducer(state, action);
}

export const downloadFeatureKey = 'download';

import { Action, createReducer, on } from '@ngrx/store';
import { DatetimeComparePickerRange } from 'src/app/shared/mat-extended/datetime-picker/datetime-compare-picker/datetime-compare-picker.component';
import { resetState } from '../../../store/app.actions';
import {
  ActivityInsightsData,
  AlertEventsData,
  ChargingLevelsData,
  EnergyConsumptionData,
  MileageRankingData,
  UsageInsightsData,
} from '../../insights.models';
import * as InsightsActions from './insights.actions';

export type InsightsGeneralState = {
  loadingStatus: {
    usageInsights: boolean;
    mileageRanking: boolean;
    activityInsights: boolean;
    chargingLevels: boolean;
    energyConsumption: boolean;
    alertInsights: boolean;
  };
  filters?: DatetimeComparePickerRange | null;
  usageInsights?: UsageInsightsData | null;
  mileageRanking?: MileageRankingData | null;
  activityInsights?: ActivityInsightsData | null;
  chargingLevels?: ChargingLevelsData | null;
  energyConsumption?: EnergyConsumptionData | null;
  alertInsights?: AlertEventsData | null;
};

const initialState: InsightsGeneralState = {
  loadingStatus: {
    usageInsights: false,
    mileageRanking: false,
    activityInsights: false,
    chargingLevels: false,
    energyConsumption: false,
    alertInsights: false,
  },
};

const reducer = createReducer<InsightsGeneralState>(
  initialState,
  on(resetState, () => ({ ...initialState })),
  // Reset State to initial
  on(InsightsActions.clearState, () => ({
    ...initialState,
  })),
  on(InsightsActions.setInsightsFilters, (state, { filters }) => ({
    ...state,
    filters,
  })),
  // Vehicle Usage Insights
  on(InsightsActions.getUsageInsights, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      usageInsights: true,
    },
  })),
  on(InsightsActions.setUsageInsights, (state, { usageInsights }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      usageInsights: false,
    },
    usageInsights,
  })),
  // Vehicle Mileage Ranking
  on(InsightsActions.getMileageRanking, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      mileageRanking: true,
    },
  })),
  on(InsightsActions.setMileageRanking, (state, { mileageRanking }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      mileageRanking: false,
    },
    mileageRanking,
  })),
  // Activity Insights
  on(InsightsActions.getActivityInsights, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      activityInsights: true,
    },
  })),
  on(InsightsActions.setActivityInsights, (state, { activityInsights }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      activityInsights: false,
    },
    activityInsights,
  })),
  // Charging Levels Insights
  on(InsightsActions.getChargingLevelsInsights, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      chargingLevels: true,
    },
  })),
  on(InsightsActions.setChargingLevelsInsights, (state, { chargingLevels }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      chargingLevels: false,
    },
    chargingLevels,
  })),
  // Energy Consumption Ranking
  on(InsightsActions.getEnergyConsumptionRanking, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      energyConsumption: true,
    },
  })),
  on(InsightsActions.setEnergyConsumptionRanking, (state, { energyConsumption }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      energyConsumption: false,
    },
    energyConsumption,
  })),
  // Alert Events Insights
  on(InsightsActions.getAlertEventsInsights, state => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      alertInsights: true,
    },
  })),
  on(InsightsActions.setAlertEventsInsights, (state, { alertInsights }) => ({
    ...state,
    loadingStatus: {
      ...state.loadingStatus,
      alertInsights: false,
    },
    alertInsights,
  }))
);

export function insightsGeneralReducer(
  state: InsightsGeneralState | undefined,
  action: Action
): InsightsGeneralState {
  return reducer(state, action);
}
export const insightsGeneralFeatureKey = 'insightsGeneral';

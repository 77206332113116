import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientWrapper } from '../../http-client-wrapper';
import { FeedbackFormResponse } from './feedback.models';
import { feedbackFormUrl } from './feedback.urls';

interface FeedbackFormArgs {
  id: number;
  reply_email: string;
  user_email: string;
  current_url: string;
  rating: number;
  content: string;
}

@Injectable()
export class FeedbackService {
  constructor(private wrapper: HttpClientWrapper) {}

  public feedbackForm({
    id,
    reply_email,
    user_email,
    current_url,
    content,
    rating,
  }: FeedbackFormArgs): Observable<FeedbackFormResponse> {
    return this.wrapper.post<FeedbackFormResponse>(feedbackFormUrl, {
      state: {
        auth: {
          id: id,
          auth_email: user_email,
        },
        current_url: current_url,
      },
      form: {
        reply_email: reply_email,
        content: content,
        rating: rating,
      },
    });
  }
}

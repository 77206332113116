import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, catchError, filter, first, map, of, tap } from 'rxjs';
import { resetState } from 'src/app/store/app.actions';
import { signOut } from '../../auth/store/auth.actions';
import {
  selectCompanies,
  selectSelectedCompany,
  selectSession,
  selectUserProfile,
} from '../../auth/store/auth.selectors';
import { Company, CompanyDataType, UserProfile } from '../../auth/utility/auth.models';
import { SubscriptionService } from '../../shared/subscription/subscription.service';
import { FeedbackModalComponent } from '../feedback/feedback-modal.component';
import { Theme, ThemeService } from '../services/theme.service';
import { SidenavService } from '../sidenav/sidenav.service';
import { SupportModalComponent } from '../support/support-modal.component';
import { MODAL_HEIGHT, MODAL_ID, MODAL_WIDTH } from '../support/support.static';
import { HeaderService } from './header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() onSidenavButtonClick = new EventEmitter<void>();

  quickAccessTemplate?: TemplateRef<unknown>;
  private quickAccessItem$?: Subscription;

  user$?: Observable<UserProfile>;
  account?: Company;
  accounts: Company[] = [];

  dialogConfig = new MatDialogConfig();
  modalDialog: MatDialogRef<SupportModalComponent> | undefined;
  feedbackModalDialog: MatDialogRef<FeedbackModalComponent> | undefined;

  // announcements: { message: string; visible: boolean; link?: string }[] = [
  //   {
  //     message: 'Upgrade to Premium!',
  //     visible: true,
  //     link: '/admin/manage-subscription',
  //   },
  // ];
  announcements: { message: string; visible: boolean; link?: string }[] = [];

  private readonly subscription = new Subscription();

  public readonly AccountType = CompanyDataType;
  public readonly Theme = Theme;

  constructor(
    private readonly store: Store,
    private readonly headerService: HeaderService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly matDialog: MatDialog,
    public readonly themeService: ThemeService,
    public readonly sidenavService: SidenavService,
    public subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.quickAccessItem$ = this.headerService.quickAccessItem$.subscribe((item) => {
      if (item instanceof TemplateRef) this.quickAccessTemplate = item;
      else this.quickAccessTemplate = undefined;

      this.cdr.markForCheck();
    });

    this.user$ = this.store.select(selectUserProfile).pipe(
      filter((up) => up !== undefined && up !== null),
      map((up) => up!)
    );

    const account$ = this.store.select(selectSelectedCompany).pipe(
      filter((acc) => acc !== undefined && acc !== null),
      tap((acc) => {
        this.account = acc!;
      })
    );
    this.subscription.add(account$.subscribe());

    const accounts$ = this.store.select(selectCompanies).pipe(
      filter((acc) => !!acc.value),
      tap((acc) => {
        this.accounts = acc.value!;
      })
    );
    this.subscription.add(accounts$.subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.quickAccessItem$?.unsubscribe();
  }

  onSignOut(): void {
    this.store.dispatch(signOut());
    const session = this.store
      .pipe(
        select(selectSession),
        first((isAuthenticated) => isAuthenticated === undefined || isAuthenticated === false),
        tap(() => {
          void this.router.navigate(['auth', 'login']).then(() => {
            this.store.dispatch(resetState({ resetAuth: true }));
          });
        }),
        catchError((err) => {
          console.log('Unable to sign out. Because of the following:');
          console.error(err);

          return of();
        })
      )
      .subscribe();
    this.subscription.add(session);
  }

  onChangeAccount(): void {
    void this.router.navigate(['auth', 'select-account']).then(() => {
      this.store.dispatch(resetState({}));
    });
  }

  toggleSidenav(): void {
    this.sidenavService.toggle();
  }

  supportClick() {
    void this.router.navigate(['faq']);
  }

  feedbackClick() {
    this.openFeedbackModal();
  }

  openFeedbackModal() {
    this.dialogConfig.id = MODAL_ID;
    this.dialogConfig.height = MODAL_HEIGHT;
    this.dialogConfig.width = MODAL_WIDTH;
    this.dialogConfig.maxWidth = '600px';
    this.dialogConfig.maxHeight = '618px';
    this.feedbackModalDialog = this.matDialog.open(FeedbackModalComponent, this.dialogConfig);
  }
}

import { createAction, props } from '@ngrx/store';
import { ListDTO } from 'src/app/shared/loading-state/api.models';
import {
  UserGroup,
  UserGroupDTO,
  UserProfile,
  UserProfileForm,
} from '../../../auth/utility/auth.models';
import { Invite, InviteDTO, Permission, Provider } from '../../admin.models';
import {
  BillingCardDTO,
  BillingDetailsDTO,
} from '../../components/payment-form/payment-form.models';
import { SubscriptionInvoiceDTO } from '../../pages/payment-history/payment-history.models';

export const clearState = createAction('[Admin] Clear State');

export const getSupportedProviders = createAction('[Admin] Get Supported Providers');
export const setSupportedProviders = createAction(
  '[Admin] Set Supported Providers',
  props<{ providers: Provider[] | null }>()
);

export const getUsers = createAction('[Admin] Get All Users');
export const setUsers = createAction(
  '[Admin] Set All Users',
  props<{ users?: UserProfile[] | null; error?: string }>()
);

export const createUser = createAction(
  '[Admin] Create/Update User',
  props<{ user: UserProfileForm; invite_id?: string }>()
);
export const onUserCreated = createAction(
  '[Admin] User Created',
  props<{ user: UserProfile | null; error?: string }>()
);
export const deleteUser = createAction('[Admin] Delete User', props<{ user: UserProfile }>());

export const updateUserGroups = createAction(
  "[Admin] Update User's Groups",
  props<{ user: number; groups: number[]; editor_email?: string }>()
);
export const setUserGroups = createAction(
  "[Admin] Set User's Groups",
  props<{ user: number; groups: number[] }>()
);

export const getPermissionsList = createAction('[Admin] Get Permissions List');
export const setPermissionsList = createAction(
  '[Admin] Set Permissions List',
  props<{ permissions: Permission[] | null; error?: string }>()
);

export const getPermissionGroups = createAction('[Admin] Get Permission Groups');
export const setPermissionGroups = createAction(
  '[Admin] Set Permission Groups',
  props<{ permissionGroups: UserGroup[] | null; error?: string }>()
);

export const getMemberCounts = createAction('[Admin] Get Group Member Counts');
export const setMemberCounts = createAction(
  '[Admin] Set Group Member Counts',
  props<{ memberCounts: { name: string; member_count: number }[] | null; error?: string }>()
);

export const createPermissionGroup = createAction(
  '[Admin] Create Permission Group',
  props<UserGroupDTO>()
);

export const deletePermissionGroup = createAction(
  '[Admin] Delete Permission Group',
  props<{ id: string }>()
);

export const getInvites = createAction('[Admin] Get Invites');
export const setInvites = createAction(
  '[Admin] Set Invites',
  props<{ invites: Invite[] | null; error?: string }>()
);
export const validateInvite = createAction(
  '[Admin] Validate Invite',
  props<{ invite_id: string; email?: string }>()
);

export const createInvite = createAction('[Admin] Create Invite', props<{ invite: InviteDTO }>());
export const deleteInvite = createAction('[Admin] Delete Invite', props<{ invite_id: string }>());

export const getBillingDetails = createAction('[Admin] Get Billing Details');
export const setBillingDetails = createAction(
  '[Admin] Set Billing Details',
  props<{ billingDetails: BillingDetailsDTO | null; error?: string }>()
);

export const getBillingCard = createAction('[Admin] Get Billing Card');
export const setBillingCard = createAction(
  '[Admin] Set Billing Card',
  props<{ card: BillingCardDTO | null; error?: string }>()
);

export const getSubscriptionInvoices = createAction(
  '[Admin] Get Subscription Invoices',
  props<{
    pageIndex: number;
    pageSize: number;
  }>()
);
export const setSubscriptionInvoices = createAction(
  '[Admin] Set Subscription Invoices',
  props<{ invoices: ListDTO<SubscriptionInvoiceDTO> | null; error?: string }>()
);

export const getVehicleAccess = createAction(
  '[Admin] Get User Vehicle Access',
  props<{ email?: string }>()
);
export const setVehicleAccess = createAction(
  '[Admin] Set User Vehicle Access',
  props<{
    vehicle_ids: number[];
    user?: UserProfile;
    email?: string;
    error?: string;
  }>()
);
export const deleteVehicleAccess = createAction(
  '[VehicleAccess] Delete Vehicle Access',
  props<{ vehicle_id: number; user: UserProfile }>()
);

import { createAction, props } from '@ngrx/store';
import { TripSummary, VehicleState } from '../../../../shared/vehicle/models/vehicle.models';
import { VehicleAlertsResponse } from '../../../monitoring.models';

export const getVehicleTrips = createAction(
  '[Monitoring - Replay] Get Vehicle Trips',
  props<{ time_0: Date; time_1: Date; vehicle: number }>()
);
export const setVehicleTrips = createAction(
  '[Monitoring - Replay] Set Vehicle Trips',
  props<{ tripsStates: VehicleState[] | null }>()
);

export const getVehicleHistoricalAlerts = createAction(
  '[Monitoring - Replay] Get Vehicle Historical Trips',
  props<{ time_0: string; time_1: string; vehicle: [number] }>()
);
export const setVehicleHistoricalAlerts = createAction(
  '[Monitoring - Replay] Set Vehicle Historical Trips',
  props<{ alerts: VehicleAlertsResponse | null }>()
);

export const getVehicleTripSummaries = createAction(
  '[Monitoring - Replay] Get Vehicle Trip Summaries',
  props<{ time_0: Date; time_1: Date; vehicle: [number], pagination?: number }>(),
);
export const setVehicleTripSummaries = createAction(
  '[Monitoring - Replay] Set Vehicle Trip Summaries',
  props<{ summaries: TripSummary[] }>()
);
export const setVehicleTripSummariesError = createAction(
  '[Monitoring - Replay] Set Vehicle Trip Summaries Into Error State'
);

export const getCurrentTrip = createAction(
  '[Monitoring - Replay] Get Current Trip',
  props<{ tripId: number }>()
);
export const setCurrentTrip = createAction(
  '[Monitoring - Replay] Set Current Trip',
  props<{ states: VehicleState[] }>()
);
export const setCurrentTripError = createAction(
  '[Monitoring - Replay] Set Current Trip Into Error State'
);

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';
import { AppState } from 'src/app/store/app.reducers';
import { SubscriptionDataService } from './subscription-data.service';
import * as SubscriptionActions from './subscription.actions';

@Injectable()
export class SubscriptionEffects {
  getSubscriptionPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.getSubscriptionPlans),
      switchMap(() =>
        this.dataService.getSubscriptionPlans().pipe(
          map(subscriptionPlans => SubscriptionActions.setSubscriptionPlans({ subscriptionPlans })),
          catchError(error =>
            of(SubscriptionActions.setSubscriptionPlans({ subscriptionPlans: null, error }))
          )
        )
      )
    )
  );

  getSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionActions.getSubscription),
      switchMap(() =>
        this.dataService.getSubscription().pipe(
          map(subscription => SubscriptionActions.setSubscription({ subscription })),
          catchError(error =>
            of(SubscriptionActions.setSubscription({ subscription: null, error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private dataService: SubscriptionDataService,
    private store: Store<AppState>
  ) {}
}

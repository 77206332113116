import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-subscription-snackbar',
  template: `
    <div class="snackbar-content">
      <mat-icon>task_alt</mat-icon>
      <span><b>{{ data.message }}</b></span>
    </div>
  `,
  styles: [
    `
      .snackbar-content {
        display: flex;
        align-items: center;
        color: #00a667;
      }
      mat-icon {
        margin-right: 8px;
      }
    `,
  ],
})
export class SubscriptionSnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}

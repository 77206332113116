<ng-template
  #overlay
  cdkConnectedOverlay
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayPanelClass]="['generic-zeva-popover-panel']"
  [cdkConnectedOverlayBackdropClass]="backdropClass"
  [cdkConnectedOverlayOrigin]="anchor"
  [cdkConnectedOverlayOpen]="isOpen"
  (backdropClick)="onBackdropClick()"
>
  <ng-container [ngSwitch]="position">
    <div
      class="zeva-popover popover-pos__{{ position }}"
      [ngClass]="panelClass"
      [@fade]="isOpen ? 'active-center' : null"
    >
      <div>
        <ng-content></ng-content>
      </div>
    </div>
  </ng-container>
</ng-template>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { hasPermissions } from '../auth/permissions/permissions.guard';
import { hasAccountAccess } from '../auth/utility/account.guard';
import { ZevaPageSelector } from '../core/core.models';
import { ZEVA_PAGES, ZEVA_PAGE_VIEW_PERMISSION } from '../core/core.static';
import { MonitoringComponent } from './monitoring.component';

const monitoringRoutes: Routes = [
  {
    path: '',
    component: MonitoringComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ZEVA_PAGES[ZevaPageSelector.MONITORING_LIVE].slug,
      },
      {
        path: ZEVA_PAGES[ZevaPageSelector.MONITORING_LIVE].slug,
        loadChildren: () => import('./pages/live/live.module').then((m) => m.LiveModule),
        canActivate: [
          hasPermissions(ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.MONITORING_LIVE]),
          hasAccountAccess,
        ],
        data: { title: ZEVA_PAGES[ZevaPageSelector.MONITORING_LIVE].name },
      },
      {
        path: ZEVA_PAGES[ZevaPageSelector.MONITORING_REPLAY].slug,
        loadChildren: () => import('./pages/replay/replay.module').then((m) => m.ReplayModule),
        canActivate: [
          hasPermissions(ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.MONITORING_REPLAY]),
          hasAccountAccess,
        ],
        data: { title: ZEVA_PAGES[ZevaPageSelector.MONITORING_REPLAY].name },
      },
      {
        path: ZEVA_PAGES[ZevaPageSelector.MONITORING_CONTROL].slug,
        loadChildren: () => import('./pages/control/control.module').then((m) => m.ControlModule),
        canActivate: [
          hasPermissions(ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.MONITORING_CONTROL]),
          hasAccountAccess,
        ],
        data: { title: ZEVA_PAGES[ZevaPageSelector.MONITORING_CONTROL].name },
      },
      {
        path: ZEVA_PAGES[ZevaPageSelector.MONITORING_EN].slug,
        loadChildren: () => import('./pages/energy/energy.module').then((m) => m.EnergyModule),
        canActivate: [
          hasPermissions(ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.MONITORING_EN]),
          hasAccountAccess,
        ],
        data: { title: ZEVA_PAGES[ZevaPageSelector.MONITORING_EN].name },
      },
      {
        path: ZEVA_PAGES[ZevaPageSelector.MONITORING_GEOFENCES].slug,
        loadChildren: () =>
          import('./pages/geofences/geofences.module').then((m) => m.GeofencesModule),
        canActivate: [
          hasPermissions(ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.MONITORING_GEOFENCES]),
          hasAccountAccess,
        ],
        data: { title: ZEVA_PAGES[ZevaPageSelector.MONITORING_GEOFENCES].name },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(monitoringRoutes)],
  exports: [RouterModule],
})
export class MonitoringRoutingModule {}

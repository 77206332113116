import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Data } from '@angular/router';
import { Subscription, mergeMap, of, tap } from 'rxjs';
import { APP_VERSION } from '../../app.component';

@Component({
  selector: 'app-document-page',
  templateUrl: './document-page.component.html',
  styleUrls: ['./document-page.component.scss'],
})
export class DocumentPageComponent implements OnInit, OnDestroy {
  private _subscription = new Subscription();

  public readonly year = new Date().getFullYear();
  public readonly appVersion = APP_VERSION;

  public documentName: string | undefined;
  public documentHTML: string | undefined;
  public documentURL: SafeUrl | undefined;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    // Get the document
    const params$ = this.route.data.pipe(
      mergeMap((data: Data) => {
        this.documentName = data['title'];
        if (this.documentName !== undefined) {
          const url = `/assets/documents/${this.documentName}.html`;
          this.documentURL = this.sanitizer.bypassSecurityTrustUrl(url);

          // Make a GET request to the HTML file
          return this.http
            .get(url, { responseType: 'text' })
            .pipe(tap((content: string) => (this.documentHTML = content)));
        }
        return of();
      })
    );
    this._subscription.add(params$.subscribe());
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientWrapper } from '../../../http-client-wrapper';
import { WidgetDataResponse } from '../../../shared/widget/widget.models';
import {
  AnalyticsDataDateRange,
  BatteryCapacityData,
  BatteryEfficiencyData,
  BatteryLifeData,
  BatteryRatingResponseData,
  ChargeCycleData,
  ChargerLocation,
  ChargingAnalyticsData,
  GeofenceAlertsAnalyticsData,
  HeatmapDataPoint,
  RoadSpeedData,
  UsageAnalyticsData,
  VehicleInsightDimension,
  VehicleRankingData,
} from '../../analytics.models';
import {
  activityDataUrl,
  alertsDataUrl,
  averageDepletionAnalyticsUrl,
  batteryCapacityAnalyticsUrl,
  batteryEfficiencyAnalyticsUrl,
  batteryLifeAnalyticsUrl,
  batteryRatingAnalyticsUrl,
  chargeCycleAnalyticsUrl,
  chargingAnalyticsUrl,
  chargingDataUrl,
  chargingTypeDataUrl,
  dataDateRangeUrl,
  geofenceAlertsAnalyticsUrl,
  mileageDataUrl,
  roadSpeedDataUrl,
  tripDataUrl,
  usageAnalyticsUrl,
  usageChargingHeatmapUrl,
  vehicleRankingDataUrl,
} from '../../analytics.urls';

@Injectable({ providedIn: 'root' })
export class AnalyticsDataService {
  constructor(private httpClientWrapper: HttpClientWrapper, private httpClient: HttpClient) {}

  public getVehicleAnalyticsDataDateRange(): Observable<AnalyticsDataDateRange> {
    return this.httpClientWrapper.get(dataDateRangeUrl);
  }

  public getVehicleTravelData(
    time_0: Date,
    time_1: Date,
    compare_time_0: Date | undefined,
    compare_time_1: Date | undefined,
    vehicles: number[] | undefined
  ): Observable<WidgetDataResponse> {
    return this.httpClientWrapper.post(mileageDataUrl, {
      time_0,
      time_1,
      compare_time_0,
      compare_time_1,
      vehicles,
    });
  }

  public getVehicleActivityData(
    time_0: Date,
    time_1: Date,
    compare_time_0: Date | undefined,
    compare_time_1: Date | undefined,
    vehicles: number[] | undefined
  ): Observable<WidgetDataResponse> {
    return this.httpClientWrapper.post(activityDataUrl, {
      time_0,
      time_1,
      compare_time_0,
      compare_time_1,
      vehicles,
    });
  }

  public getVehicleChargingTypeData(
    time_0: Date,
    time_1: Date,
    compare_time_0: Date | undefined,
    compare_time_1: Date | undefined,
    vehicles: number[] | undefined
  ): Observable<WidgetDataResponse> {
    return this.httpClientWrapper.post(chargingTypeDataUrl, {
      time_0,
      time_1,
      compare_time_0,
      compare_time_1,
      vehicles,
    });
  }

  public getVehicleChargingData(
    time_0: Date,
    time_1: Date,
    compare_time_0: Date | undefined,
    compare_time_1: Date | undefined,
    vehicles: number[] | undefined
  ): Observable<WidgetDataResponse> {
    return this.httpClientWrapper.post(chargingDataUrl, {
      time_0,
      time_1,
      compare_time_0,
      compare_time_1,
      vehicles,
    });
  }

  public getVehicleTripData(
    time_0: Date,
    time_1: Date,
    compare_time_0?: Date,
    compare_time_1?: Date,
    vehicles?: number[]
  ): Observable<WidgetDataResponse> {
    return this.httpClientWrapper.post(tripDataUrl, {
      time_0,
      time_1,
      compare_time_0,
      compare_time_1,
      vehicles,
    });
  }

  public getVehicleAlertData(
    time_0: Date,
    time_1: Date,
    compare_time_0: Date | undefined,
    compare_time_1: Date | undefined,
    vehicles: number[] | undefined
  ): Observable<WidgetDataResponse> {
    return this.httpClientWrapper.post(alertsDataUrl, {
      time_0,
      time_1,
      compare_time_0,
      compare_time_1,
      vehicles,
    });
  }

  public getUsageChargingHeatmapData(
    time_0: Date | undefined,
    time_1: Date | undefined,
    vehicles: number[] | undefined
  ): Observable<HeatmapDataPoint[]> {
    return this.httpClientWrapper.post(usageChargingHeatmapUrl, {
      time_0,
      time_1,
      vehicles,
    });
  }

  public getUsageAnalyticsData(
    time_0: Date,
    time_1: Date,
    vehicles: number[] | undefined
  ): Observable<UsageAnalyticsData> {
    return this.httpClientWrapper.post(usageAnalyticsUrl, {
      time_0,
      time_1,
      vehicles,
    });
  }

  public getChargingAnalyticsData(
    time_0: Date,
    time_1: Date,
    vehicles: number[] | undefined
  ): Observable<ChargingAnalyticsData> {
    return this.httpClientWrapper.post(chargingAnalyticsUrl, {
      time_0,
      time_1,
      vehicles,
    });
  }

  public getGeofenceAlertsAnalyticsData(
    time_0: Date,
    time_1: Date,
    vehicles: number[] | undefined
  ): Observable<GeofenceAlertsAnalyticsData> {
    return this.httpClientWrapper.post(geofenceAlertsAnalyticsUrl, {
      time_0,
      time_1,
      vehicles,
    });
  }

  public getVehicleRanking(
    dimension: VehicleInsightDimension,
    time_0: Date,
    time_1: Date,
    compare_time_0: Date | undefined,
    compare_time_1: Date | undefined,
    vehicles: number[] | undefined
  ): Observable<VehicleRankingData> {
    return this.httpClientWrapper.post(vehicleRankingDataUrl, {
      dimension,
      time_0,
      time_1,
      compare_time_0,
      compare_time_1,
      vehicles,
    });
  }

  public getBatteryCapacity(
    time_0: Date,
    time_1: Date,
    vehicles: number[] | undefined
  ): Observable<BatteryCapacityData> {
    return this.httpClientWrapper.post(batteryCapacityAnalyticsUrl, {
      time_0,
      time_1,
      vehicles,
    });
  }

  public getBatteryEfficiency(
    time_0: Date,
    time_1: Date,
    vehicles: number[] | undefined
  ): Observable<BatteryEfficiencyData> {
    return this.httpClientWrapper.post(batteryEfficiencyAnalyticsUrl, {
      time_0,
      time_1,
      vehicles,
    });
  }

  public getBatteryLife(
    time_0: Date,
    time_1: Date,
    vehicles: number[] | undefined
  ): Observable<BatteryLifeData> {
    return this.httpClientWrapper.post(batteryLifeAnalyticsUrl, {
      time_0,
      time_1,
      vehicles,
    });
  }

  public getChargeCycles(
    time_0: Date,
    time_1: Date,
    vehicles: number[] | undefined
  ): Observable<ChargeCycleData> {
    return this.httpClientWrapper.post(chargeCycleAnalyticsUrl, {
      time_0,
      time_1,
      vehicles,
    });
  }

  public getAverageDepletion(
    time_0: Date,
    time_1: Date,
    vehicles: number[] | undefined
  ): Observable<WidgetDataResponse> {
    return this.httpClientWrapper.post(averageDepletionAnalyticsUrl, {
      time_0,
      time_1,
      vehicles,
    });
  }

  public getBatteryRating(
    time_0: Date,
    time_1: Date,
    compare_time_0: Date | undefined,
    compare_time_1: Date | undefined,
    vehicles: number[] | undefined
  ): Observable<BatteryRatingResponseData> {
    return this.httpClientWrapper.post(batteryRatingAnalyticsUrl, {
      time_0,
      time_1,
      compare_time_0,
      compare_time_1,
      vehicles,
    });
  }

  public getRoadSpeedData(
    time_0: Date,
    time_1: Date,
    lat_0: number,
    lat_1: number,
    lng_0: number,
    lng_1: number,
    zoom: number,
    vehicles: number[] | undefined
  ): Observable<RoadSpeedData> {
    zoom = Math.round(zoom);
    return this.httpClientWrapper.post(roadSpeedDataUrl, {
      time_0,
      time_1,
      lat_0,
      lat_1,
      lng_0,
      lng_1,
      vehicles,
      zoom,
    });
  }

  public getSuperchargers(): Observable<ChargerLocation> {
    return this.httpClientWrapper.get('data/superchargers/');
  }
}

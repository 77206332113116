import { createAction, props } from '@ngrx/store';
import { WidgetDataResponse } from '../../../shared/widget/widget.models';
import {
  AnalyticsDataDateRange,
  BatteryCapacityData,
  BatteryEfficiencyData,
  BatteryLifeData,
  BatteryRatingResponseData,
  ChargeCycleData,
  ChargerLocation,
  ChargingAnalyticsData,
  GeofenceAlertsData,
  HeatmapDataPoint,
  HeatmapDataRequestError,
  RoadSpeedData,
  UsageAnalyticsData,
  VehicleInsightDimension,
  VehicleRankingData
} from '../../analytics.models';

export const clearState = createAction('[Analytics] Clear State');

type GenericWidgetRequestBody = {
  time_0: Date;
  time_1: Date;
  compare_time_0?: Date;
  compare_time_1?: Date;
  vehicles: number[] | undefined;
};

/* Meta Data */
export const getVehicleAnalyticsDataDateRange = createAction(
  '[Analytics] Get Vehicle Analytics Data Date Range'
);

export const setVehicleAnalyticsDataDateRange = createAction(
  '[Analytics] Set Vehicle Analytics Data Date Range',
  props<{ dataDateRange: AnalyticsDataDateRange | null }>()
);

/* Travel Analytics */
export const getVehicleTravelData = createAction(
  '[Analytics] Get Vehicle Travel Data',
  props<GenericWidgetRequestBody>()
);
export const setVehicleTravelData = createAction(
  '[Analytics] Set Vehicle Travel Data',
  props<{ travelData: WidgetDataResponse }>()
);

export const setVehicleTravelDataError = createAction(
  '[Analytics] Set Vehicle Analytics Travel Data Into Error State'
);

/* Activity Analytics */
export const getVehicleActivityData = createAction(
  '[Analytics] Get Vehicle Activity Data',
  props<GenericWidgetRequestBody>()
);
export const setVehicleActivityData = createAction(
  '[Analytics] Set Vehicle Activity Data',
  props<{ activityData: WidgetDataResponse | null }>()
);

/* Alert Analytics */
export const getVehicleAlertData = createAction(
  '[Analytics] Get Vehicle Alert Data',
  props<GenericWidgetRequestBody>()
);
export const setVehicleAlertData = createAction(
  '[Analytics] Set Vehicle Alert Data',
  props<{ alertData: WidgetDataResponse | null }>()
);

/* Charging Analytics */
export const getVehicleChargingData = createAction(
  '[Analytics] Get Vehicle Charging Data',
  props<GenericWidgetRequestBody>()
);

export const setVehicleChargingData = createAction(
  '[Analytics] Set Vehicle Charging Data',
  props<{ chargingData: WidgetDataResponse | null }>()
);

export const getVehicleChargingTypeData = createAction(
  '[Analytics] Get Vehicle Charging Type Data',
  props<GenericWidgetRequestBody>()
);

export const setVehicleChargingTypeData = createAction(
  '[Analytics] Set Vehicle Charging Type Data',
  props<{ chargingData: WidgetDataResponse }>()
);

export const setVehicleChargingTypeDataError = createAction(
  '[Analytics] Set Vehicle Analytics Charging Type Data Into Error State'
);

/* Trip Analytics */
export const getVehicleTripData = createAction(
  '[Analytics] Get Vehicle Trip Data',
  props<GenericWidgetRequestBody>()
);

export const setVehicleTripData = createAction(
  '[Analytics] Set Vehicle Trip Data',
  props<{ tripData: WidgetDataResponse }>()
);

export const setVehicleTripDataError = createAction(
  '[Analytics] Set Vehicle Analytics Trip Data Into Error State'
);

/* Vehicle Ranking supporting widget endpoints above */
export const getVehicleRanking = createAction(
  '[Analytics] Get Vehicle Ranking',
  props<{ dimension: VehicleInsightDimension } & GenericWidgetRequestBody>()
);
export const setVehicleRanking = createAction(
  '[Analytics] Set Vehicle Ranking',
  props<{ vehicleRanking: VehicleRankingData | null }>()
);

export const getUsageChargingHeatmapData = createAction(
  '[Analytics] Get Usage/Charging Heatmap Data',
  props<{
    time_0: Date;
    time_1: Date;
    vehicles?: number[];
  }>()
);
export const setUsageChargingHeatmapData = createAction(
  '[Analytics] Set Usage/Charging Heatmap Data',
  props<{ usageChargingHeatmapData: HeatmapDataPoint[] | HeatmapDataRequestError }>()
);

export const getUsageAnalyticsData = createAction(
  '[Analytics] Get Usage Analytics Data',
  props<{ time_0: Date; time_1: Date; vehicles: number[] }>()
);
export const setUsageAnalyticsData = createAction(
  '[Analytics] Set Usage Analytics Data',
  props<{ usageAnalyticsData: UsageAnalyticsData | null }>()
);

export const getChargingAnalyticsData = createAction(
  '[Analytics] Get Charging Analytics Data',
  props<{ time_0: Date; time_1: Date; vehicles: number[] }>()
);
export const setChargingAnalyticsData = createAction(
  '[Analytics] Set Charging Analytics Data',
  props<{ chargingAnalyticsData: ChargingAnalyticsData | null }>()
);

export const getGeofenceAlertsAnalyticsData = createAction(
  '[Analytics] Get Geofence Alerts Analytics Data',
  props<{ time_0: Date; time_1: Date; vehicles: number[] }>()
);
export const setGeofenceAlertsAnalyticsData = createAction(
  '[Analytics] Set Geofence Alerts Analytics Data',
  props<{ geofenceAlertsAnalyticsData: GeofenceAlertsData[] | null }>()
);

export const getSuperchargers = createAction('[Analytics Data] Get Superchargers');
export const setSuperchargers = createAction(
  '[Analytics Data] Set Superchargers',
  props<{ superchargers: ChargerLocation | null; error?: string }>()
);

export const getBatteryEfficiency = createAction(
  '[Analytics] Get Battery Efficiency Data',
  props<{ time_0: Date; time_1: Date; vehicles?: number[] }>()
);
export const setBatteryEfficiency = createAction(
  '[Analytics] Set Battery Efficiency Data',
  props<{ efficiencyData: BatteryEfficiencyData }>()
);
export const setBatteryEfficiencyError = createAction(
  '[Analytics] Set Battery Efficiency Data Into Error State'
);

export const getBatteryCapacity = createAction(
  '[Analytics] Get Battery Capacity Data',
  props<{ time_0: Date; time_1: Date; vehicles?: number[] }>()
);
export const setBatteryCapacity = createAction(
  '[Analytics] Set Battery Capacity Data',
  props<{ capacityData: BatteryCapacityData }>()
);
export const setBatteryCapacityError = createAction(
  '[Analytics] Set Battery Capacity Data Into Error State'
);

export const getBatteryLife = createAction(
  '[Analytics] Get Battery Life Data',
  props<{ time_0: Date; time_1: Date; vehicles?: number[] }>()
);

export const setBatteryLife = createAction(
  '[Analytics] Set Battery Life Data',
  props<{ batteryLifeData: BatteryLifeData }>()
);

export const setBatteryLifeError = createAction(
  '[Analytics] Set Battery Capacity Into Error State'
);

export const getChargeCycles = createAction(
  '[Analytics] Get Charge Cycle Data',
  props<{ time_0: Date; time_1: Date; vehicles: number[] }>()
);
export const setChargeCycles = createAction(
  '[Analytics] Set Charge Cycle Data',
  props<{ chargeCycleData: ChargeCycleData }>()
);
export const setChargeCyclesError = createAction(
  '[Analytics] Set Charge Cycle Data Into Error State'
);

export const getDepletion = createAction(
  '[Analytics] Get Depletion Data',
  props<{ time_0: Date; time_1: Date; vehicles: number[] }>()
);
export const setDepletion = createAction(
  '[Analytics] Set Depletion Data',
  props<{ depletionData: WidgetDataResponse }>()
);
export const setDepletionError = createAction(
  '[Analytics] Set Depletion Data Into Error State'
);

export const getBatteryRating = createAction(
  '[Analytics] Get Battery Rating Data',
  props<{
    time_0: Date;
    time_1: Date;
    compare_time_0: Date | undefined;
    compare_time_1: Date | undefined;
    vehicles?: number[];
  }>()
);
export const setBatteryRating = createAction(
  '[Analytics] Set Battery Rating Data',
  props<{ batteryRatingData: BatteryRatingResponseData }>()
);
export const setBatteryRatingError = createAction(
  '[Analytics] Set Battery Rating Data Into Error State'
);

export const getRoadSpeedData = createAction(
  '[Analytics] Get Road Speed Data',
  props<{
    time_0: Date;
    time_1: Date;
    lat_0: number;
    lat_1: number;
    lng_0: number;
    lng_1: number;
    zoom: number;
    vehicles?: number[];
  }>()
);
export const setRoadSpeed = createAction(
  '[Analytics] Set Road Speed Data',
  props<{ roadSpeedData: RoadSpeedData }>()
);
export const setRoadSpeedError = createAction('[Analytics] Set Road Speed Data Into Error State');

import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Theme, ThemeService } from '../../core/services/theme.service';

@Component({
  standalone: true,
  selector: 'app-generic-error',
  imports: [CommonModule, MatButtonModule, RouterModule],
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss'],
})
export class GenericErrorComponent implements OnInit {
  errorCode = '400';
  errorMessage = 'Unexpected error';
  isValidErrorUrl = false;
  darkMode = false;

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    public themeService: ThemeService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const currentUrl = window.location.pathname;
    const errorCode = currentUrl.slice(-3);

    this.isValidErrorUrl = /error\/\d{3}$/.test(currentUrl);

    const theme$ = this.themeService.currentTheme;
    if (theme$ === Theme.DARK) this.darkMode = true;
    else this.darkMode = false;

    this.themeService.currentTheme$.subscribe((theme: Theme) => {
      if (theme === Theme.DARK) this.darkMode = true;
      else this.darkMode = false;
      this.cdr.markForCheck();
    });

    if (!this.isValidErrorUrl) {
      console.error('[Invalid Route]');
      this.errorCode = '404';
    } else if (['400', '403', '404', '500'].indexOf(errorCode) === -1) {
      if (environment.debug) console.error(`[Generic Error] Unhandled error code ${errorCode}`);
      this.errorCode = '400';
    } else {
      this.errorCode = errorCode;
    }

    switch (this.errorCode) {
      case '403':
        this.errorMessage = "You don't have permission to access this page";
        break;
      case '404':
        this.errorMessage = 'Page not found';
        break;
      case '500':
        this.errorMessage = 'Service is unavailable. Please, try again later';
        break;
      case '400':
      default:
        this.errorMessage = 'Unexpected error';
    }

    this.titleService.setTitle(`${this.errorCode} - ZEVA`);
  }
}

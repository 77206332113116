import { animate, style, transition } from '@angular/animations';

// ---------------- For left-aligned Menu

export const OVERLAY_FADE_IN_LEFT = transition('void => active-left', [
  // using status here for transition
  style({ opacity: 0, transform: 'translateX(-0.5rem)' }),
  animate(
    '200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
    style({ opacity: 1, transform: 'translateX(0rem)' })
  ),
]);

export const OVERLAY_FADE_OUT_LEFT = transition('active-left => void', [
  animate(
    '200ms cubic-bezier(0.4, 0.0, 0.1, 1)',
    style({ opacity: 0, transform: 'translateX(-0.5rem)' })
  ),
]);

// ---------------- For center-aligned Menu

export const OVERLAY_FADE_IN_CENTER = transition('void => active-center', [
  // using status here for transition
  style({ opacity: 0, transform: 'translateY(0.33rem)' }),
  animate(
    '150ms cubic-bezier(0.4, 0.0, 0.2, 1)',
    style({ opacity: 1, transform: 'translateY(0rem)' })
  ),
]);

export const OVERLAY_FADE_OUT_CENTER = transition('active-center => void', [
  animate(
    '100ms cubic-bezier(0.4, 0.0, 0.1, 1)',
    style({ opacity: 0, transform: 'translateY(0.33rem)' })
  ),
]);

// ---------------- For right-aligned Menu

export const OVERLAY_FADE_IN_RIGHT = transition('void => active-right', [
  // using status here for transition
  style({ opacity: 0, transform: 'translateX(0.5rem)' }),
  animate(
    '200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
    style({ opacity: 1, transform: 'translateX(0rem)' })
  ),
]);

export const OVERLAY_FADE_OUT_RIGHT = transition('active-right => void', [
  animate(
    '200ms cubic-bezier(0.4, 0.0, 0.1, 1)',
    style({ opacity: 0, transform: 'translateX(0.5rem)' })
  ),
]);

export const ALL_TRANSITION_ANIMATIONS = [
  OVERLAY_FADE_IN_LEFT,
  OVERLAY_FADE_OUT_LEFT,
  OVERLAY_FADE_IN_CENTER,
  OVERLAY_FADE_OUT_CENTER,
  OVERLAY_FADE_IN_RIGHT,
  OVERLAY_FADE_OUT_RIGHT,
];

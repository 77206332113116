import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FullHorizontalTileAdComponent } from './full-horizontal-tile-ad/full-horizontal-tile-ad.component';
import { SingleTileAdComponent } from './single-tile-ad/single-tile-ad.component';
import { VdualTileAdComponent } from './vdual-tile-ad/vdual-tile-ad.component';

@NgModule({
  declarations: [SingleTileAdComponent, VdualTileAdComponent, FullHorizontalTileAdComponent],
  exports: [SingleTileAdComponent, VdualTileAdComponent, FullHorizontalTileAdComponent],
  imports: [CommonModule],
})
export class AdsModule {}

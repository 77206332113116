import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientWrapper } from '../../../http-client-wrapper';
import {
  ChargingInvoice,
  DriverInviteDictionary,
  HomeChargingInvoice,
  PhantomDrainResponse,
  RangeEstimateResponse,
  VehicleAlertsResponse,
  VehicleChargeLocationResponse,
  VehicleDestinationResponse,
  VehicleOwnership,
} from '../../../monitoring/monitoring.models';
import { WidgetDataResponse } from '../../../shared/widget/widget.models';
import {
  chargingHistoryUrl,
  chargingInvoicesUrl,
  chargingScheduleUrl,
  homeChargingInvoicesUrl,
  phantomDrainUrl,
  rangeEstimateUrl,
  vehicleAlertsUrl,
  vehicleChargeLocationsUrl,
  vehicleChargingSettingsUrl,
  vehicleDestinationsUrl,
  vehicleDriverInviteUrl,
  vehicleLogsUrl,
  vehicleOwnershipUrl,
  weatherUrl
} from '../../monitoring.urls';
import {
  ChargingSchedule,
  ChargingSettings,
} from '../../pages/control/components/charging-management/charging-management.models';
import { CommandLogEntry } from '../../pages/control/components/vehicle-log/vehicle-log.models';
import { Weather } from '../../pages/live/live.models';

@Injectable({ providedIn: 'root' })
export class MonitoringDataService {
  constructor(private httpClientWrapper: HttpClientWrapper, private httpClient: HttpClient) {}

  getVehicleAlerts(
    time_0?: string,
    time_1?: string,
    vehicle?: number[]
  ): Observable<VehicleAlertsResponse> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {};
    if (time_0) params['time_0'] = time_0;
    if (time_1) params['time_1'] = time_1;
    if (vehicle) params['vehicle'] = vehicle;

    return this.httpClientWrapper.get(vehicleAlertsUrl, params);
  }

  /**
   * Gets the last count destinations the specified vehicle visited
   * @param vehicle vehicle to query
   * @param count number of destinations to return
   * @returns list of destinations
   */
  getVehicleDestinations(vehicle: number, count: number): Observable<VehicleDestinationResponse> {
    return this.httpClientWrapper.post(vehicleDestinationsUrl, {
      vehicle,
      count,
    });
  }

  getVehicleChargeLocations(
    vehicle: number,
    count: number
  ): Observable<VehicleChargeLocationResponse> {
    return this.httpClientWrapper.post(vehicleChargeLocationsUrl, {
      vehicle,
      count,
    });
  }

  getChargeHistory(
    vehicle: number,
    time_0: Date,
    time_1: Date
  ): Observable<WidgetDataResponse<'Charge'>> {
    return this.httpClientWrapper.post(chargingHistoryUrl, {
      vehicle,
      time_0,
      time_1,
    });
  }

  getVehicleLogs(vehicles: number[]): Observable<CommandLogEntry[]> {
    return this.httpClientWrapper.get(vehicleLogsUrl, { vehicles });
  }

  getChargingSchedules(vehicles: number[]): Observable<ChargingSchedule[]> {
    return this.httpClientWrapper.get(chargingScheduleUrl, { vehicles });
  }

  createChargingSchedule(
    schedule: ChargingSchedule,
    vehicles: number[]
  ): Observable<ChargingSchedule[]> {
    return this.httpClientWrapper.post(chargingScheduleUrl, { schedule, vehicles });
  }

  deleteChargingSchedule(schedule_id: number, vehicles: number[]): Observable<ChargingSchedule[]> {
    return this.httpClientWrapper.delete(`${chargingScheduleUrl}${schedule_id}`, { vehicles });
  }

  getChargingSettings(vehicle: number): Observable<ChargingSettings> {
    return this.httpClientWrapper.get(`${vehicleChargingSettingsUrl}${vehicle}/`);
  }

  createChargingSettings(
    settings: ChargingSettings,
    vehicle: number
  ): Observable<ChargingSettings> {
    const { id, ...data } = { ...settings, vehicle };
    if (id !== undefined)
      return this.httpClientWrapper.put(`${vehicleChargingSettingsUrl}${vehicle}/`, data);
    return this.httpClientWrapper.post(`${vehicleChargingSettingsUrl}`, data);
  }

  getVehicleOwnership(vehicles: number[]): Observable<VehicleOwnership> {
    return this.httpClientWrapper.post<VehicleOwnership>(vehicleOwnershipUrl, {
      vehicles,
    });
  }

  getChargingInvoices(
    vehicles: number[],
    time_0: Date,
    time_1: Date
  ): Observable<ChargingInvoice[]> {
    return this.httpClientWrapper.get(chargingInvoicesUrl, { vehicles, time_0, time_1 });
  }

  getHomeChargingInvoices(
    vehicles: number[],
    time_0: Date,
    time_1: Date,
    pagination?: number
  ): Observable<HomeChargingInvoice[]> {
    if (pagination) return this.httpClientWrapper.get(homeChargingInvoicesUrl, { vehicles, time_0, time_1, pagination });
    return this.httpClientWrapper.get(homeChargingInvoicesUrl, { vehicles, time_0, time_1 });
  }

  getRangeEstimate(vehicles: number[]): Observable<RangeEstimateResponse> {
    return this.httpClientWrapper.post(rangeEstimateUrl, { vehicles });
  }

  getPhantomDrain(
    vehicles: number[],
    time_0: Date,
    time_1: Date
  ): Observable<PhantomDrainResponse> {
    return this.httpClientWrapper.post(phantomDrainUrl, { vehicles, time_0, time_1 });
  }

  getDriverInvites(vehicles: number[]): Observable<DriverInviteDictionary> {
    const params = { vehicles };
    return this.httpClientWrapper.get<DriverInviteDictionary>(vehicleDriverInviteUrl, params);
  }

  createDriverInvites(vehicles: number[], revoke_dates: Record<number, Date | null>) {
    const params = {
      vehicles,
      revoke_dates,
    };
    return this.httpClientWrapper.post(vehicleDriverInviteUrl, params);
  }

  updateDriverInvites(updates: Record<string, unknown>[]) {
    const params = { updates };
    return this.httpClientWrapper.put(vehicleDriverInviteUrl + 'update/', params);
  }

  deleteDriverInvites(vehicle_invite_ids: number[]) {
    // Pass in internal id, NOT Tesla's invite id
    const params = { ids: vehicle_invite_ids };
    return this.httpClientWrapper.delete(vehicleDriverInviteUrl + 'delete/', params);
  }

  emailDriverInvite(invite_id: number, recipient_email: string) {
    // Pass in internal id, NOT Tesla's invite id
    const params = { invite_id: invite_id, email_address: recipient_email };
    return this.httpClientWrapper.post(vehicleDriverInviteUrl + 'email_invite/', params);
  }

  getWeatherData(vehicleId: number): Observable<Weather> {
    return this.httpClientWrapper.post(weatherUrl, { vehicle: vehicleId });
  }
}

import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  Observable,
  Subscription,
  filter,
  lastValueFrom,
  map,
  of,
  startWith,
  tap,
  zip
} from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { PermissionsService } from '../../auth/permissions/permissions.service';
import { SubscriptionService } from '../../shared/subscription/subscription.service';
import { Vehicle } from '../../shared/vehicle/models/vehicle.class';
import { getVehicleList } from '../../shared/vehicle/store/vehicle.actions';
import { selectVehicleList } from '../../shared/vehicle/store/vehicle.selectors';
import { NavigationElement } from './sidenav.models';
import { SidenavService } from './sidenav.service';
import { DEFAULT_NAVIGATION, EXTERNAL_LINKS, GET_STARTED_NAVIGATION } from './sidenav.static';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  navigationPages = DEFAULT_NAVIGATION;
  getStartedPages = GET_STARTED_NAVIGATION;
  externalLinks = EXTERNAL_LINKS;
  navigationPages$?: Subscription;

  hasVehicles = true;
  getStartedClicked = false;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly router: Router,
    private readonly store: Store,
    public readonly subscriptionService: SubscriptionService,
    public readonly sidenav: SidenavService,
    public readonly permissionService: PermissionsService,
    public readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // attempt at generating dynamic menu out of sidenav service
    // this.navigationPages$ = this.sidenav.navigationTemplate$
    //   .asObservable()
    //   .pipe(tap(navTemplate => (this.navigationPages = navTemplate ?? DEFAULT_NAVIGATION)))
    //   .subscribe();

    void lastValueFrom(
      this.store.select(selectVehicleList).pipe(
        tap((vehicles: Vehicle[] | undefined | null) => {
          if (vehicles === undefined) this.store.dispatch(getVehicleList());
        }),
        filter(
          (vehicles: Vehicle[] | null | undefined) => vehicles !== undefined && vehicles !== null
        ),
        tap((vehicles: Vehicle[] | null | undefined) => {
          if (!vehicles || (vehicles && vehicles.length < 1)) this.hasVehicles = false;
          else this.hasVehicles = true;
          this.changeDetector.markForCheck();
        })
      )
    );

    if (this.router.url.includes('setup-wizard')) this.getStartedClicked = true;
    else this.getStartedClicked = false;
    this.changeDetector.markForCheck();
  }

  ngOnDestroy(): void {
    // this.navigationPages$?.unsubscribe();
  }

  /**
   * Navigates to the page clicked
   * @param {NavigationElement} page
   */
  handleLinkClick(page: NavigationElement): void {
    if (page.disabled || (!page.link && !page.action)) return;

    page.active = true;

    if (page.action) page.action(this.dialog);
    else if (page.externalLink) window.open(page.link, '_blank');
    else if (page.link) void this.router.navigateByUrl(page.link);
    else throw new Error('No link or action provided for navigation element');

    this.changeDetector.markForCheck();
  }

  handleGetStartedClick() {
    void this.router.navigate(['admin', 'setup-wizard']);
  }

  showParentPage(page: NavigationElement): Observable<boolean> {
    if (page.subPages !== undefined && page.subPages.length > 0) {
      if (page.subPages.some((curPage) => curPage.name === 'Profile')) return of(true);

      const observables = page.subPages.map((subPage) =>
        this.permissionService.hasPermissions(subPage.permissionsRequired ?? [])
      );
      return zip(observables).pipe(
        map((permissionsArray: boolean[]) =>
          permissionsArray.some((hasPermission) => hasPermission)
        )
      );
    }
    return of(true);
  }
}

/**
 * Sidenav domestic Pipe that checks if a NavigationElement is currently active
 * by comparing the `link` member of the element with the current Router URL
 */
@Pipe({
  name: 'isActive',
})
export class IsPageActivePipe implements PipeTransform {
  constructor(private readonly router: Router) {}

  transform(page: NavigationElement): Observable<boolean> {
    if (!page) return of(false);

    return this.router.events.pipe(
      // listen to router events emitted at the navigation end only
      filter((routerEvent) => routerEvent instanceof NavigationEnd),
      // start with the current router state,
      // since first NavigationEnd event emits before this gets called on the very first time
      startWith(this.router),
      map(() => {
        return (
          // check if the page is active
          this.router.url.split('?')[0] === page.link ||
          // or if the page has children and one of those children is active
          (page.subPages !== undefined &&
            page.subPages.length > 0 &&
            page.subPages.some((subPage) => this.router.url.split('?')[0] === subPage.link))
        );
      })
    );
  }
}

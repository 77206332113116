import { createSelector } from '@ngrx/store';
import { selectFeatureAuth } from '../../store/app.selectors';
import { AuthState } from './auth.reducer';

export const selectLoadingStatus = createSelector(
  selectFeatureAuth,
  (state: AuthState) => state.loadingStatus
);

export const selectSession = createSelector(
  selectFeatureAuth,
  (state: AuthState) => state.isAuthenticated
);

export const selectUserProfile = createSelector(
  selectFeatureAuth,
  (state: AuthState) => state.userProfile
);

export const selectCSRFToken = createSelector(
  selectFeatureAuth,
  (state: AuthState) => state.csrfToken
);

export const selectCompanies = createSelector(
  selectFeatureAuth,
  (state: AuthState) => state.companies
);

export const selectSelectedCompany = createSelector(
  selectFeatureAuth,
  (state: AuthState) => state.selectedCompany
);

export const selectUserPreferences = createSelector(
  selectFeatureAuth,
  (state: AuthState) => state.userPreferences
);

export const selectIpData = createSelector(selectFeatureAuth, (state: AuthState) => state.ipData);

/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subscription, filter, firstValueFrom, map, switchMap, tap } from 'rxjs';
import { ProviderCredential } from 'src/app/admin/admin.models';
import { SSOAction, SSOProvider } from 'src/app/auth/pages/sso/sso.models';
import { getSSOProviderUri } from 'src/app/auth/pages/sso/sso.static';
import { selectSelectedCompany } from 'src/app/auth/store/auth.selectors';
import { Company, CompanyDataType } from 'src/app/auth/utility/auth.models';
import { HttpClientWrapper } from 'src/app/http-client-wrapper';
import {
  ActionPromptConfig,
  ActionPromptDialog,
} from 'src/app/shared/action-prompt/action-prompt.component';
import { Loadable } from 'src/app/shared/loading-state/loadable';
import { SubscriptionService } from 'src/app/shared/subscription/subscription.service';
import { Vehicle } from 'src/app/shared/vehicle/models/vehicle.class';
import { getProviderCredentials } from 'src/app/shared/vehicle/store/vehicle.actions';
import {
  selectProviderCredentials,
  selectVehicleList,
} from 'src/app/shared/vehicle/store/vehicle.selectors';
import { AppState } from 'src/app/store/app.reducers';
import { BillingCardDTO } from '../../admin/components/payment-form/payment-form.models';
import { getBillingCard } from '../../admin/store/general/admin.actions';
import { selectBillingCard } from '../../admin/store/general/admin.selectors';
import { SubscriptionState } from '../../shared/subscription/subscription.models';

@Injectable({ providedIn: 'root' })
export class DialogService {
  /**
   * Main subscription
   */
  private readonly subscription = new Subscription();

  /**
   * The selected company
   */
  private selectedCompany: Company | undefined;

  constructor(
    private dialog: MatDialog,
    private readonly subscriptionService: SubscriptionService,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly http: HttpClientWrapper
  ) {}

  /**
   * Initializes the dialog service
   * @author Juan Corral
   */
  public init(): void {
    const navigate$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),

      // Trigger persistent detection dialogs
      tap(() => void this._detectAdBlock()),

      // Trigger account-based detection dialogs
      switchMap(() => this.store.select(selectSelectedCompany)),
      filter((company?: Company) => this.selectedCompany !== company && company !== undefined),
      tap((company?: Company) => (this.selectedCompany = company)),

      // Trigger detection dialogs in all pages
      tap(() => void this._detectTrialEnd()),
      tap(() => void this._detectExpiredSubscription()),

      // Trigger detection dialogs in non-auth pages
      filter(() => !this.router.url.includes('/auth')),
      tap(() => void this._detectPastDueSubscription()),
      tap(() => void this._detectExpiringPayment()),
      tap(() => void this._detectExpiredToken())
    );
    this.subscription.add(navigate$.subscribe());
  }

  /**
   * Detects if the user has an ad-blocker enabled and prompts the user to disable it
   * @author Anna Chester
   */
  private async _detectAdBlock(): Promise<void> {
    const displayAds = await firstValueFrom(this.subscriptionService.displayAds$);
    if (!displayAds) return;

    const iframe = document.createElement('iframe');
    iframe.className = 'ad-zone ad-space ad-unit textads banner-ads banner_ads';
    iframe.height = '1px';
    iframe.src = 'https://ads.google.com/';
    iframe.id = 'some-ad';
    document.body.appendChild(iframe);

    setTimeout(() => {
      // Checks if the iframe got blocked due to an ad-blocker
      const someAd = document.getElementById('some-ad');
      if (someAd!.offsetHeight === 0) {
        const pastDueDialog = this.dialog.open<ActionPromptDialog, ActionPromptConfig>(
          ActionPromptDialog,
          {
            width: '25rem',
            data: {
              icon: 'error_outline',
              heading: 'Adblock Detected',
              message:
                // eslint-disable-next-line max-len
                'You will not be able to access ZEVA. Turn off any ad blockers for full use of ZEVA.',
              removeCancelAction: true,
              confirmActionCaption: 'Reload App',
            },
          }
        );
        void firstValueFrom(pastDueDialog.afterClosed().pipe(tap(() => window.location.reload())));
      }
      iframe.remove();
    }, 1000);
  }

  /**
   * Detects if the subscription is past due and prompts the user to update their payment details
   * @author Samuel Bai
   */
  private async _detectPastDueSubscription(): Promise<void> {
    const subscription = await firstValueFrom(this.subscriptionService.subscription$);
    const state = subscription?.state;

    if (state !== SubscriptionState.PAST_DUE) return;

    const account = await firstValueFrom(this.store.select(selectSelectedCompany));
    const isCommercial =
      account?.data_type === CompanyDataType.FM || account?.data_type === CompanyDataType.SI;
    const date = moment(subscription!.next_billing_date)
      .add(subscription!.grace_period_days, 'days')
      .format('MMM DD, YYYY');
    const pastDueDialog = this.dialog.open<ActionPromptDialog, ActionPromptConfig>(
      ActionPromptDialog,
      {
        width: '25rem',
        minHeight: '30.5rem',
        data: {
          icon: 'error_outline',
          heading: isCommercial ? 'Urgent: Payment Issue Detected' : 'Payment Issue Detected',
          messages: isCommercial
            ? [
                // eslint-disable-next-line max-len
                "We've encountered an issue with your account's payment method, leading to a potential " +
                  `expiration of your ZEVA for Fleets subscription on ${date}.  Without an update, your ` +
                  'access to ZEVA will be suspended.',
                'Please update your payment information to ensure uninterrupted service.',
              ]
            : [
                // eslint-disable-next-line max-len
                `We encountered a problem with your payment, and your subscription is set to expire on ${date}.`,
                'Please update your payment details to maintain access to ZEVA.',
              ],
          confirmActionCaption: isCommercial
            ? 'Update Payment Details'
            : 'Update My Payment Details',
          removeCancelAction: true,
        },
      }
    );
    void firstValueFrom(
      pastDueDialog.afterClosed().pipe(
        tap((result: boolean) => {
          if (result) void this.router.navigate(['admin', 'payment-details']);
        })
      )
    );
  }

  /**
   * Detects if the payment card will soon expire, and prompts user to update payment details
   * @author Anna Chester
   */
  private async _detectExpiringPayment() {
    const card$ = this.store.select(selectBillingCard).pipe(
      tap((card: Loadable<BillingCardDTO>) => {
        if (card.value === undefined && !card.isLoading && !card.error)
          this.store.dispatch(getBillingCard());
      }),
      filter(
        (card: Loadable<BillingCardDTO>) =>
          !card.isLoading && !card.error && card.value !== undefined
      ),
      map((card: Loadable<BillingCardDTO>) => {
        if (card.value && card.value.last_digits !== null) {
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth() + 1;
          return (
            card.value.expiration_year === currentYear &&
            card.value.expiration_month === currentMonth
          );
        }
        return false;
      })
    );

    const expiring = await firstValueFrom(card$);
    if (!expiring) return;

    const account = await firstValueFrom(this.store.select(selectSelectedCompany));
    const isCommercial =
      account?.data_type === CompanyDataType.FM || account?.data_type === CompanyDataType.SI;
    const expiryDialog = this.dialog.open<ActionPromptDialog, ActionPromptConfig>(
      ActionPromptDialog,
      {
        width: '25rem',
        minHeight: '28.5rem',
        data: {
          icon: 'error_outline',
          heading: isCommercial
            ? 'Reminder: Payment Update Required'
            : 'Update Your Payment Details',
          messages: isCommercial
            ? [
                'The payment card associated with your ZEVA for Fleets subscription is about to expire. ',
                "To ensure your subscription continues without interruption, please update your account's payment details.",
              ]
            : [
                'The payment card associated with your ZEVA subscription is about to expire. ',
                'To ensure your subscription continues without interruption, please update your payment details.',
              ],
          removeCancelAction: true,
          confirmActionCaption: isCommercial
            ? 'Update Payment Details'
            : 'Update My Payment Details',
        },
      }
    );

    void firstValueFrom(
      expiryDialog.afterClosed().pipe(
        tap((result: boolean) => {
          if (result) void this.router.navigate(['admin', 'payment-details']);
        })
      )
    );
  }

  /**
   * Opens the trial ended dialog
   * @author Juan Corral
   */
  private async _detectTrialEnd(): Promise<void> {
    const subscription = await firstValueFrom(this.subscriptionService.subscription$);
    const state = subscription?.state;

    if (state !== SubscriptionState.TRIAL_END) return;

    const account = await firstValueFrom(this.store.select(selectSelectedCompany));
    const isCommercial =
      account?.data_type === CompanyDataType.FM || account?.data_type === CompanyDataType.SI;

    const vehicles = await firstValueFrom(
      this.store.select(selectVehicleList).pipe(filter((v: Vehicle[] | null | undefined) => !!v))
    );

    const trialEndDialog = this.dialog.open<ActionPromptDialog, ActionPromptConfig>(
      ActionPromptDialog,
      {
        width: '25rem',
        data: {
          icon: 'error_outline',
          heading: isCommercial ? 'Your Trial Has Ended' : 'Your Promo Has Ended',
          messages: isCommercial
            ? [
                "We hope you've enjoyed exploring all the features ZEVA offers for managing and optimizing your fleet.",
                `Following the conclusion of your trial, your account access has been temporarily suspended. To continue leveraging ZEVA's suite of tools,
              please contact your ZEVA representative to get your subscription started.`,
              ]
            : [
                "We hope you've enjoyed exploring all the features ZEVA offers for managing and optimizing your electric vehicle(s).",
                'Your promo has ended. Upgrade today to continue enjoying all the features ZEVA Premium has to offer!',
              ],
          removeCancelAction: true,
          // cancelActionCaption: isCommercial ? '' : 'Continue Free',
          confirmActionCaption: isCommercial ? 'Continue' : 'Upgrade Now',
        },
      }
    );

    void firstValueFrom(
      trialEndDialog.afterClosed().pipe(
        tap((result: boolean) => {
          if ((result || !isCommercial) && !subscription?.plan_instance.plan.mobile)
            void this.router.navigate(['auth', 'subscribe']);
          // } else if (!isCommercial) {
          //   void firstValueFrom(
          //     this.store.select(selectSubscriptionPlans).pipe(
          //       tap((plans: Loadable<ListDTO<SubscriptionPlanDTO>>) => {
          //         if (!plans.isLoading && plans.value === undefined)
          //           this.store.dispatch(getSubscriptionPlans());
          //       }),
          //       first((plans: Loadable<ListDTO<SubscriptionPlanDTO>>) => plans.value !== undefined),
          //       tap((plans: Loadable<ListDTO<SubscriptionPlanDTO>>) => {
          //         const freePlan = plans.value?.results.find(
          //           (plan: SubscriptionPlanDTO) => plan.free
          //         );
          //         const data = { plan: freePlan!.id, vehicle_spots: 5 };
          //         void firstValueFrom<boolean>(
          //           this.http.put<{ success: boolean }>(subscriptionsUrl + 'update/', data).pipe(
          //             catchError(() => of({ success: false })),
          //             map((response: { success: boolean }) => response.success)
          //           )
          //         ).then(async (success: boolean) => {
          //           if (success) {
          //             if (vehicles && vehicles?.length > 0) {
          //               await firstValueFrom(
          //                 this.http
          //                   .post<{ success: boolean }>(vehicleListUrl + 'set_premium/', {
          //                     premium: false,
          //                     vehicles: vehicles.map((v) => v.id),
          //                   })
          //                   .pipe(
          //                     catchError(() => of({ success: false })),
          //                     map((response: { success: boolean }) => response.success)
          //                   )
          //               ).then((vehicleSuccess: boolean) => {
          //                 if (vehicleSuccess) this.store.dispatch(getVehicleList());
          //                 if (success) this.store.dispatch(getSubscription());
          //               });
          //             }
          //           }
          //         });
          //       })
          //     )
          //   );
          // }
        })
      )
    );
  }

  /**
   * Opens the expired subscription dialog
   * @author Juan Corral
   */
  private async _detectExpiredSubscription(): Promise<void> {
    const subscription = await firstValueFrom(this.subscriptionService.subscription$);
    const state = subscription?.state;

    if (state !== SubscriptionState.EXPIRED) return;

    const account = await firstValueFrom(this.store.select(selectSelectedCompany));
    const isCommercial =
      account?.data_type === CompanyDataType.FM || account?.data_type === CompanyDataType.SI;

    let messages = [
      'Your most recent payment attempt was unsuccessful, resulting in a temporary suspension of your ZEVA for Fleets account.',
      "To regain full access, please update your account's payment details.",
    ];

    if (!isCommercial) {
      const vehicles = await firstValueFrom(
        this.store.select(selectVehicleList).pipe(filter((v: Vehicle[] | null | undefined) => !!v))
      );
      const archivedVehicles = vehicles!.filter((v: Vehicle) => v.archived);
      const numArchived = archivedVehicles.length;
      const nonArchivedVehicles = vehicles!.filter((v: Vehicle) => !v.archived);
      const numNonArchived = nonArchivedVehicles.length;

      messages = [
        'We were unable to process your recent payment and all of your vehicles have been archived.',
      ];
      if (numArchived === 0) {
        if (subscription?.plan_instance.plan.mobile) {
          messages.push(
            'To regain access to ZEVA features, please visit the mobile app store you originally subscribed through to resolve your payment issue.'
          );
        } else {
          messages.push(
            'To regain access to ZEVA features, please update your payment details and subscribe again.'
          );
        }
      } else {
        if (subscription?.plan_instance.plan.mobile) {
          messages.push(
            'To regain access to ZEVA features, including historical data from your archived vehicle(s), please visit the mobile app store you originally subscribed through to resolve your payment issue.'
          );
        } else {
          messages.push(
            'To regain access to ZEVA features, including historical data from your archived vehicle(s), please update your payment details and subscribe again.'
          );
        }
      }
    }

    const expiredDialog = this.dialog.open<ActionPromptDialog, ActionPromptConfig>(
      ActionPromptDialog,
      {
        width: '25rem',
        data: {
          icon: 'error_outline',
          heading: isCommercial ? 'Account Access Suspended' : 'Payment Failed',
          messages: messages,
          removeCancelAction: isCommercial,
          cancelActionCaption: 'Update Later',
          confirmActionCaption: 'Update Subscription',
          disableClose: true,
        },
      }
    );

    void firstValueFrom(
      expiredDialog.afterClosed().pipe(
        tap((result: boolean) => {
          if (result && !subscription?.plan_instance.plan.mobile)
            void this.router.navigate(['auth', 'subscribe']);
          else void this.router.navigate(['admin', 'subscription']);
          // } else if (!isCommercial) {
          //   void firstValueFrom(
          //     this.store.select(selectSubscriptionPlans).pipe(
          //       tap((plans: Loadable<ListDTO<SubscriptionPlanDTO>>) => {
          //         if (!plans.isLoading && plans.value === undefined)
          //           this.store.dispatch(getSubscriptionPlans());
          //       }),
          //       first((plans: Loadable<ListDTO<SubscriptionPlanDTO>>) => plans.value !== undefined),
          //       tap((plans: Loadable<ListDTO<SubscriptionPlanDTO>>) => {
          //         const freePlan = plans.value?.results.find(
          //           (plan: SubscriptionPlanDTO) => plan.free
          //         );
          //         const data = { plan: freePlan!.id, vehicle_spots: 5 };
          //         void firstValueFrom<boolean>(
          //           this.http.put<{ success: boolean }>(subscriptionsUrl + 'update/', data).pipe(
          //             catchError(() => of({ success: false })),
          //             map((response: { success: boolean }) => response.success)
          //           )
          //         ).then((success: boolean) => {
          //           if (success) this.store.dispatch(getSubscription());
          //         });
          //       })
          //     )
          //   );
          // }
        })
      )
    );
  }

  /**
   * Detects if the provider token has expired and prompts the user to sign in again
   * @author Juan Corral
   */
  private async _detectExpiredToken(): Promise<void> {
    // Retrieve the provider credentials
    const credentials$ = this.store.select(selectProviderCredentials).pipe(
      tap((credentials: Loadable<ProviderCredential[]>) => {
        if (!credentials.value && !credentials.isLoading)
          this.store.dispatch(getProviderCredentials());
      }),
      filter(
        (credentials: Loadable<ProviderCredential[]>) =>
          credentials.value !== null && credentials.value !== undefined && !credentials.isLoading
      ),
      map((credentials: Loadable<ProviderCredential[]>) => credentials.value!)
    );
    const credentials = await firstValueFrom(credentials$);
    if (!credentials) return;

    // Retrieve the tokens that need to be refreshed
    const toRefresh = credentials.filter(
      (token: ProviderCredential) => !token.is_active && !token.revoked
    );
    if (toRefresh.length === 0) return;

    // Open the dialog to refresh the tokens
    const refreshDialog = this.dialog.open<ActionPromptDialog, ActionPromptConfig>(
      ActionPromptDialog,
      {
        width: '25rem',
        data: {
          icon: 'error_outline',
          heading: 'Your Vehicle Connection Needs Refreshing',
          // eslint-disable-next-line max-len
          message:
            `Your vehicle ${toRefresh[0].provider_name} connection is due for a quick refresh. ` +
            'Please click the button below and sign in to your ' +
            (toRefresh[0].provider_user_email ? toRefresh[0].provider_user_email + ' ' : '') +
            'account again and the connection will be automatically restored.',
          confirmActionCaption: 'Log In',
          removeCancelAction: true,
          disableClose: true,
        },
      }
    );
    void firstValueFrom(
      refreshDialog.afterClosed().pipe(
        tap((result: boolean) => {
          if (result) {
            const provider = toRefresh[0].provider_name.toLowerCase() as SSOProvider;
            const url = getSSOProviderUri(
              provider,
              SSOAction.RECONNECT,
              window.location.href,
              this.selectedCompany,
              toRefresh[0].provider_user_email
            );
            if (url !== undefined) window.location.href = url;
          }
        })
      )
    );
  }
}

/* eslint-disable prettier/prettier */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

import { forEach } from 'lodash';
import * as moment from 'moment';
import { ZEVA_TIMESTAMP_OUTPUT } from './core/core.static';
import { catchErrorResponse, handleErrorResponse } from './shared/helpers/utility';

export type RawParams = {
  [param: string]: string | string[] | number | number[] | Date | Date[] | boolean;
};

@Injectable({ providedIn: 'root' })
export class HttpClientWrapper {
  constructor(private httpClient: HttpClient) {}

  get<T>(url: string, params?: RawParams): Observable<T> {
    if (params === undefined) {
      return this.httpClient.get<T>(`${ environment.apiUrl }${ url }`, {
        withCredentials: true,
      });
    }

    return this.httpClient.get<T>(`${ environment.apiUrl }${ url }`, {
      params: this.prepareParams(params),
      withCredentials: true,
    }).pipe(catchErrorResponse, handleErrorResponse());
  }

  post<T>(
    url: string,
    body: unknown | null,
    params?: RawParams,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text'
  ): Observable<T> {
    const options: { [key: string]: unknown } = { withCredentials: true };
    if (params !== undefined) options['params'] = this.prepareParams(params);
    if (responseType !== undefined) options['responseType'] = responseType;
    return this.httpClient
      .post<T>(`${ environment.apiUrl }${ url }`, body, options)
      .pipe(catchErrorResponse, handleErrorResponse());
  }

  patch<T>(url: string, body: unknown, params?: RawParams): Observable<T> {
    if (params === undefined) {
      return this.httpClient.patch<T>(
        `${ environment.apiUrl }${ url }`, body, { withCredentials: true }
      );
    }

    return this.httpClient.patch<T>(`${ environment.apiUrl }${ url }`, body, {
      params: this.prepareParams(params),
      withCredentials: true,
    });
  }

  put<T>(url: string, body: unknown, params?: RawParams): Observable<T> {
    if (params === undefined) {
      return this.httpClient.put<T>(
        `${ environment.apiUrl }${ url }`, body, { withCredentials: true }
      );
    }

    return this.httpClient.patch<T>(`${ environment.apiUrl }${ url }`, body, {
      params: this.prepareParams(params),
      withCredentials: true,
    });
  }

  delete<T>(url: string, body: RawParams, params?: RawParams): Observable<T> {
    const options: { [key: string]: unknown } = { withCredentials: true };
    if (params !== undefined) options['params'] = this.prepareParams(params);
    if (body !== undefined) options['body'] = body;
    return this.httpClient.delete<T>(`${ environment.apiUrl }${ url }`, options);
  }

  private prepareParams(params: RawParams): { [param: string]: string | string[] } {
    const newParams: { [key: string]: unknown } = {};
    forEach(params, (value, key) => {
      if (value instanceof Date)
        // format Date into a string of a format we can control
        newParams[key] = moment(value).format(ZEVA_TIMESTAMP_OUTPUT);
      else
        newParams[key] = String(value);
    });

    return <{ [key: string]: string | string[] }>newParams;
  }

  getFile<T>(url: string, params?: RawParams): Observable<Blob> {
    if (params === undefined) {
      return this.httpClient.get<Blob>(`${ environment.apiUrl }${ url }`, {
        responseType: 'blob' as 'json',
        withCredentials: true,
      });
    }
    return this.httpClient.get<Blob>(`${ environment.apiUrl }${ url }`, {
      params: this.prepareParams(params),
      responseType: 'blob' as 'json',
      withCredentials: true,
    });
  }
}
